import React, { useEffect } from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { FormControl } from '@material-ui/core';

//styles
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface ITimeFilterProps {
    keyId: number; //key
    id: string; //e.g. start-hour
    name: string; //e.g. Hour
    clearValues: boolean; // flag to clear filters
    dropdownValues: any; //values to fill the dropdown
    onSelected: Function; //function to pass selected value to parent.
}

const makeSelectStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: 2,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export function SelectDropDown(props: ITimeFilterProps) {
    const selectClasses = makeSelectStyles();

    const [value, setValue] = React.useState('0');

    const handleChange = (event: any) => {
        let selectedvalue = event.target.value;
        setValue(selectedvalue);
        props.onSelected(selectedvalue);
    };

    //clear the vlaue
    useEffect(() => {
        if (props.clearValues) {
            setValue('');
        }
    }, [props.clearValues]);

    return (
        <FormControl fullWidth variant="filled" className={selectClasses.formControl} size="small">
            <InputLabel htmlFor={props.id}>{props.name}</InputLabel>
            <Select
                key={props.keyId}
                native
                value={value}
                IconComponent={KeyboardArrowDownIcon}
                onChange={handleChange}
                inputProps={{
                    name: props.name,
                    id: props.id,
                }}
            >
                {props.dropdownValues}
            </Select>
        </FormControl>
    );
}
