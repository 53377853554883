import React, {useState} from 'react';
import { Box } from "@material-ui/core/";
import { IMapBaseLayer } from '../../models/IMapBaseLayer';
import useComponentVisible from '../../hooks/useComponentVisible';

import styles from "./MapBaseLayerSelector.module.css";

interface IMapBaseLayerSelectorProps {
    baseMapLayers: IMapBaseLayer[];
    currentBaseLayer: string;
    onBaseMapLayerSelected: Function;
}

export function MapBaseLayerSelector(props:IMapBaseLayerSelectorProps){
    const [showSelector, setShowSelector] = useState(false);
    const { ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    let selectedLayer = props.baseMapLayers.find(l=>l.baseLayerTitle === props.currentBaseLayer );
    if (selectedLayer == null)
        return null;

    let thumbnails: JSX.Element[] = [];

    let i=0;
    props.baseMapLayers.forEach(l => {
        let layerClass = styles.baseLayer + ' ';
        if ( i === 0) layerClass += styles.topBaseLayer;
        else if ( i === props.baseMapLayers.length -1) layerClass += styles.bottomBaseLayer;
        ++i;

        if ( l.baseLayerTitle === props.currentBaseLayer)
            layerClass += ' ' + styles.selectedBaseLayerInList;

        thumbnails.push(
            <div key={l.baseLayerId} className={layerClass} style={{ width: 56, height: 54}}>
                <img src={l.thumbnailUrl} 
                     title={l.baseLayerTitle}   
                     onClick={ () => { 
                         setShowSelector(false); 
                         props.onBaseMapLayerSelected(l.baseLayerTitle);
                     }} />
            </div>
        )
    });

    let thumbnailStyle = {
        display: showSelector && isComponentVisible ? 'block' : 'none'
    }

    if (showSelector && !isComponentVisible) {
        setShowSelector(false);
    }

    return <div ref={ref} onClick={() => setIsComponentVisible(true)}>
             <Box display="flex" flexDirection="row" style={{height:'100%'}}>
                <Box>
                  <div className={styles.baseLayers} style={thumbnailStyle}> 
                    {thumbnails}
                  </div>
                </Box>
                <Box>
                  <div className={styles.selectedBaseLayer} > 
                    <img src={selectedLayer.thumbnailUrl} onClick={ () => setShowSelector(!showSelector) } title={selectedLayer.baseLayerTitle} ></img>
                  </div>
                </Box>
            </Box>
          </div>
}
