import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { IImageStatus } from '../../models/IImageStatus';
import {fetchWithAuthorisationHeader, postWithAuthorisationHeader } from "../../services/AuthenticationService";
import styles from './ImageStatusMenu.module.css'
import { IStatusCheck } from '../../models/IStatusCheck';
import { deepClone } from '../Common/Common';
import { FormControlLabel, FormControl } from '@material-ui/core';
import { FormGroup } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const makeMenuStyles = makeStyles((theme) => ({
  list: {
    padding:'0px',
  }
}));


const statusChangeRequiresConfirmation = (status:string) => {
    let statList = ["REJECT"];
    return statList.indexOf(status.toUpperCase()) >= 0;
}

interface IImageStatusMenuProps {
    changeCountConfirmation:number;
    imageStatuses: IImageStatus[];
    anchorImageStatusMenuEl: any;
    selectedImageIds:number[];
    onMenuClosed: Function;
    onSetImageStatus: Function;
    onCancel: Function;
}


export function ImageStatusMenu(props: IImageStatusMenuProps) {
    const menuClasses = makeMenuStyles();

    let emptyJsxArr:JSX.Element[] = [];
    let emptyBoolArr:boolean[] = [];
    const [clickedStatus, setClickedStatus] = useState('');
    const [confirmationMessagesJSX, setConfirmationMessagesJSX] = useState(emptyJsxArr);
    const [confirmationChecks, setConfirmationChecks] = useState(emptyBoolArr);

    const handleCancel = (event:any) => {
      if (event.keyCode === 27){
	        props.onCancel();}
    };

    const handleCheck = (index:number) => {
        let temp = deepClone(confirmationChecks);
        temp[index] = !temp[index];
        setConfirmationChecks(temp);
    }
  
    var imageStatusMenuPositionOffset = {
        left:-60,
        top:-30
    }

    function setStatus(status: string) {
        postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "search/imagestatuscheck", props.selectedImageIds).then(response => {
            if (response.status === 200) {
                let statuses = response.data as IStatusCheck[];
                setClickedStatus(status);

                let messages:JSX.Element[] = [];
                statuses.forEach(s => {
                    if (statusChangeRequiresConfirmation(s.name)) {
                        messages.push(<div>You are changing the status of one or more images from <b style={{color:'#FF9999'}}>{s.name}</b></div>);
                    }
                });
                if (props.selectedImageIds.length >= props.changeCountConfirmation) {
                    messages.push(<div>You are updating the status of {props.selectedImageIds.length} images</div>);
                }
                else if (messages.length === 0) {
                    props.onSetImageStatus(status);
                }
                setConfirmationMessagesJSX(messages);
            }
        }).catch(response => {
            console.error(`Failed to fetch image statuses. returned: ${response.status}`);
        });
    }

    let imageStatusMenuItems: JSX.Element[] = [];
    props.imageStatuses.forEach( (imageStatus:IImageStatus) => {
        imageStatusMenuItems.push(
                <MenuItem
                    className={styles.imageStatusMenuItem}
                    onClick={(e: any) => setStatus(imageStatus.status)}
                >
                    <span className={styles.imageStatusDot} style={{backgroundColor: '#' + imageStatus.colour}}></span><span style={{fontSize:16}}>{imageStatus.status}</span>
                </MenuItem>                                
            );
          });

    return (
        <div style={{ display: props.anchorImageStatusMenuEl != null ? 'block' : 'none' }}>
    <Menu
        classes={menuClasses}
        anchorEl={props.anchorImageStatusMenuEl}
        style={{ left: imageStatusMenuPositionOffset.left, top: imageStatusMenuPositionOffset.top }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        elevation={1}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(props.anchorImageStatusMenuEl)}
        onClose={(e: any) => { props.onMenuClosed(); handleCancel(e) }}
    >
        <div className={styles.menuContainer} style={{ height: imageStatusMenuItems.length > 0 ? 472 : 64 + (props.imageStatuses.length * 36) }} >
            <div className={styles.menuContents} >
                <div className={styles.imageStatusMenuHeader}>Change image status</div>
                {imageStatusMenuItems}
            </div>
            <div className={styles.confirmationMessage} style={{ display: confirmationMessagesJSX.length > 0 ? 'block' : 'none' }} >
                <Box display="flex" flexDirection="column" >
                    <div className={styles.confirmationMessageHeader}>Confirm the following as you will not be able to undo the action:</div>
                    <form>
                        <FormGroup>
                            <FormControl>
                                <Box>
                                    {confirmationMessagesJSX.map((msg, index) => (
                                        <FormControlLabel key={index} style={{ marginBottom: 10 }} control={<Checkbox checkedIcon={<CheckBoxIcon style={{color:'#F7B500'}} />} required onChange={() => handleCheck(index)} />} label={msg} />
                                    ))}
                                </Box>
                            </FormControl>
                            <FormControl>
                                <Box display="flex" justifyContent="flex-end">
                                    <IconButton
                                        
                                        onClick={(e: any) => {
                                            props.onMenuClosed();
                                        }}
                                        style={{ height: 17, width: 17}}>
                                        <ClearIcon style={{ fontSize: 20,  color:'#FF9999'  }} />
                                    </IconButton>
                                    <IconButton 
                                        type='submit'
                                        disabled={confirmationChecks.filter(b => b).length < confirmationMessagesJSX.length}
                                        onClick={(e: any) => {
                                            props.onSetImageStatus(clickedStatus);
                                        }}
                                        style={{  height: 17, width: 17}}>
                                        <DoneIcon 
                                            style={{ fontSize: 20, color:confirmationChecks.filter(b => b).length < confirmationMessagesJSX.length ? "#5C5D5E" :"#AED581"} } />
                                    </IconButton>
                                </Box>
                            </FormControl>
                        </FormGroup>
                    </form>
                </Box>
            </div>
        </div>
    </Menu>
</div>
    );
}
