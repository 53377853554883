import React, {useState, useEffect, createRef,useRef} from 'react';
import Checkbox , { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import styles from './StatusFilter.module.css';

const makeControlStyles = makeStyles((theme) => ({
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7B500'
    }
}));


interface IStatusFilterProps {
  statuses: string[],
  onFilterChanged: Function,
  clearFilters: number
}

interface IStatus {
  name: string;
  isSelected: boolean;
}

export function StatusFilter(props:IStatusFilterProps){

  const controlClasses = makeControlStyles();

  const emptyStatuses: IStatus[] = [];

  const [statuses, setStatuses] = useState(emptyStatuses);
  const [lastClearFilters, setLastClearFilters] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  // Handle when the filters have been cleard
  // A number is passed as a flag so we can tell when it has changed
   useEffect(() => {

     if (props.clearFilters != lastClearFilters) {
       statuses.forEach(s=> s.isSelected = false);
       setStatuses(statuses.slice());
       setSelectAll(false);
       setLastClearFilters(props.clearFilters);
     }

    },[props.clearFilters, lastClearFilters, statuses]);

   useEffect(() => {
     let statuses: IStatus[] = [];
     props.statuses.forEach(status => statuses.push({name:status,isSelected:false}));    
       
     statuses.sort( ( a:IStatus, b:IStatus ) => {
          if ( a.name < b.name ){
            return -1;
          }
          if ( a.name > b.name ){
            return 1;
          }
          return 0;
     });

     setStatuses(statuses);

   }, [props.statuses]);


  function onSelectAll(selectAll: boolean) {
    if(!selectAll)
    {
      statuses.forEach(s => s.isSelected = true);
    } else {
      statuses.forEach(s => s.isSelected = false);
    }
    setStatuses(statuses.slice());
    let selectedStatuses = statuses.filter(s => s.isSelected).map(s => s.name);
    props.onFilterChanged(selectedStatuses);
    setSelectAll(!selectAll);
  }

    function onCheckboxChanged(status: IStatus) {
    status.isSelected = !status.isSelected;
    setStatuses(statuses.slice());
    let selectedStatuses = statuses.filter(s=>s.isSelected).map(s=>s.name);
    props.onFilterChanged(selectedStatuses);
    syncSelectAll();
  };

  function syncSelectAll(){
    let selectedStatuses = statuses.filter(s => s.isSelected);
    if(!selectAll && selectedStatuses.length == statuses.length)
    {
      setSelectAll(true);
    } else if(selectAll && selectedStatuses.length < statuses.length)
    {
      setSelectAll(false);
    }
  };

  let statusCheckboxes: JSX.Element[] = [];
  statuses.forEach(status => {

    statusCheckboxes.push(
       <FormControlLabel
            key={status.name}
            classes={{label: status.isSelected ? controlClasses.labelSelected : controlClasses.label}}
            control={<Checkbox checked={status.isSelected} checkedIcon={<CheckBoxIcon style={{color:'#F7B500'}} />} onChange={()=> onCheckboxChanged(status) } name={status.name} size={'small'} />}
            label={status.name}
          />
        );
  });

  let selectAllCheckbox = (
    <FormControlLabel
        key={"Select All"}
        classes={{ label: selectAll ? controlClasses.labelSelected : controlClasses.label }}
        control={<Checkbox style={{ color: '#F7B500', opacity: '0.5 !important' }} checked={selectAll} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500', opacity: '0.5 !important' }} />} onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
        label={"Select All"}
      />
  );

  return (     
    <div className={styles.container} >
      <div className={styles.content}>
        <FormGroup>
          {selectAllCheckbox}
          {statusCheckboxes}
        </FormGroup>
       </div>
    </div>
  );
}