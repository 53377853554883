import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

interface IMapSelectionSnackBarProps {
    imagesCount: number;
    isSelectModeEnabled: boolean;
    onCancel: Function;
    onClear: Function;
}
 
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    anchorOriginTopCenter: {
        top: 70
    }
}));

export function MapSelectionSnackBar(props: IMapSelectionSnackBarProps) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [message, setMessage] = React.useState("No Images Selected");

    const handleCancel = () => {
        setOpen(false);
        props.onCancel();
    };

    useEffect(() => {
        setOpen(props.isSelectModeEnabled);
        if (props.imagesCount > 0) {
            setMessage(props.imagesCount + " Image/s Selected");
        }
        else {
            setMessage("No Images Selected");
        }
       
    }, [props.imagesCount, props.isSelectModeEnabled]);

    // escaping selection mode by pressing escape key.
    useEffect(() => {
        const handleEsc = (event: { keyCode: number; }) => {

            if (props.isSelectModeEnabled && event.keyCode === 27)
                handleCancel();        
        
        };
        window.addEventListener('keydown', handleEsc);
    
        return function cleanup() {
          window.removeEventListener('keydown', handleEsc);
        };
      });
   
    return (
        <div >
            <Snackbar 
                classes={{ root: classes.root, anchorOriginTopCenter: classes.anchorOriginTopCenter }}
                open={open}
                message={message}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                action={
                    <React.Fragment>
                        <Button variant="outlined" disabled={props.imagesCount === 0} onClick={() =>  props.onClear()}>Clear All</Button>
                        <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
                    </React.Fragment>
                }
            />
        </div>
    );
}
