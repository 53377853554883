import React, {useState, useEffect, createRef,useRef} from 'react';
import Checkbox , { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import styles from './CaptureTypeFilter.module.css';

const makeControlStyles = makeStyles((theme) => ({
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7B500'
    }
}));


interface ICaptureTypeFilterProps {
  onFilterChanged: Function;
  clearFilters: number;
}

interface ICaptureType {
  name: string;
  isSelected: boolean;
}

export function CaptureTypeFilter(props:ICaptureTypeFilterProps){

  const controlClasses = makeControlStyles();

  const allCaptureTypes: ICaptureType[] = [  {name: 'Aerial', isSelected: false }];

  const [captureTypes, setCaptureTypes] = useState(allCaptureTypes);
  const [lastClearFilters, setLastClearFilters] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  // Handle when the filters have been cleard
  // A number is passed as a flag so we can tell when it has changed
   useEffect(() => {

     if (props.clearFilters != lastClearFilters) {
      captureTypes.forEach(s=> s.isSelected = false);
      setCaptureTypes(captureTypes.slice());
      setSelectAll(false);
      setLastClearFilters(props.clearFilters);
     }

    },[props.clearFilters, lastClearFilters, captureTypes]);

    function onSelectAll(selectAll: boolean) {
      if(!selectAll)
      {
        captureTypes.forEach(c => c.isSelected = true);
      } else {
        captureTypes.forEach(c => c.isSelected = false);
      }
      setCaptureTypes(captureTypes.slice());
      let selectedCaptureTypes = captureTypes.filter(c => c.isSelected).map(c => c.name);
      props.onFilterChanged(selectedCaptureTypes);
      setSelectAll(!selectAll);
    }

  function onCheckboxChanged(captureType: ICaptureType ) {
    captureType.isSelected = !captureType.isSelected;
    setCaptureTypes(captureTypes.slice());
    let selectedCaptureType = captureTypes.filter(s=>s.isSelected).map(s=>s.name);
    props.onFilterChanged(selectedCaptureType);
    syncSelectAll();
  };

  function syncSelectAll(){
    let selectedCaptureType = captureTypes.filter(c => c.isSelected);
    if(!selectAll && selectedCaptureType.length == captureTypes.length)
    {
      setSelectAll(true);
    } else if(selectAll && selectedCaptureType.length < captureTypes.length)
    {
      setSelectAll(false);
    }
  };

  let captureTypeCheckboxes: JSX.Element[] = [];
  captureTypes.forEach(captureType => {

    captureTypeCheckboxes.push(
       <FormControlLabel
            key={captureType.name}
            classes={{label: captureType.isSelected ? controlClasses.labelSelected : controlClasses.label}}
            control={<Checkbox checked={captureType.isSelected} checkedIcon={<CheckBoxIcon style={{color:'#F7B500'}} />} onChange={()=> onCheckboxChanged(captureType) } name={captureType.name} size={'small'} />}
            label={captureType.name}
          />
        );
  });

  let selectAllCheckbox = (
    <FormControlLabel
        key={"Select All"}
        classes={{ label: selectAll ? controlClasses.labelSelected : controlClasses.label }}
        control={<Checkbox style={{ color: '#F7B500', opacity: '0.5 !important' }} checked={selectAll} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500', opacity: '0.5 !important' }} />} onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
        label={"Select All"}
      />
  );

  return (     
    <div className={styles.container} >
      <div className={styles.content}>
        <FormGroup>
          {selectAllCheckbox}
          {captureTypeCheckboxes}
        </FormGroup>
       </div>
    </div>
  );
}