import React, { useState, useEffect, useCallback } from 'react';
import { fetchWithAuthorisationHeader } from "../../../../services/AuthenticationService";
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import styles from './AssigneeFilter.module.css';
import TextField from '@material-ui/core/TextField';
import { IUser } from '../../../../models/IUser';
import { Tooltip} from '@material-ui/core/';

const makeControlStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14
  },
  labelSelected: {
    fontSize: 14,
    color: '#F7B500'
  },
  labelSelectedUnassigned: {
    fontSize: 14,
    color: '#808080'
  }
}));


interface IAssigneeFilterProps {
  organisationUsers:IUser[];
  campaignId: number;
  isDisplayed:boolean;
  onFilterChanged: Function;
  clearFilters: number;
}

interface IFilterUser {
  name: string;
  emailAddress: string;
  isSelected: boolean;
  isUnassigned: boolean;
}

export function AssigneeFilter(props: IAssigneeFilterProps) {

  const controlClasses = makeControlStyles();
  const initialUsers: IFilterUser[] = [];
  const [users, setUsers] = useState(initialUsers);
  const [filteredUsers, setFilteredUsers] = useState(initialUsers);
  const [lastClearFilters, setLastClearFilters] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  // Handle when the filters have been cleard
  // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {

        if (props.clearFilters != lastClearFilters) {
            users.forEach(s => s.isSelected = false);
            setUsers(users.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);
        }

    }, [props.clearFilters, lastClearFilters, users]);

    useEffect(() => {
        
        if ( props.isDisplayed )
            getAssignedUsersForCurrentCampaign();

    }, [props.isDisplayed]);

    useEffect(() => {
        getAssignedUsersForCurrentCampaign();

    }, [props.organisationUsers,props.campaignId]);


    const getAssignedUsersForCurrentCampaign = useCallback(async () => {
        let url = process.env.REACT_APP_VAA_API_URL + "session/usersThatAreAssignedToImages/" + props.campaignId;

        fetchWithAuthorisationHeader(url).then(res => {

            let assigneeEmailAddresses = res.data as string[];

            let assignedUsers = props.organisationUsers.filter(u => assigneeEmailAddresses.includes(u.emailAddress));
            let assignedUsersEmailAddresses = assignedUsers.map(u=>u.emailAddress);

            let selectedUsers = users.filter(u => u.isSelected);
            let selectedUsersEmailAddresses = selectedUsers.map(u=>u.emailAddress);

            let unassignedSelectedUsers = selectedUsers.filter(u => !assignedUsersEmailAddresses.includes(u.emailAddress));

            let theUsers: IFilterUser[] = []; 

            assignedUsers.forEach(u => {
                theUsers.push({
                        name: u.firstName + ' ' + u.surname,
                        emailAddress: u.emailAddress,
                        isSelected: selectedUsersEmailAddresses.includes(u.emailAddress),
                        isUnassigned: false
                    })
                });

            unassignedSelectedUsers.forEach(u => {
                theUsers.push({
                        name: u.name,
                        emailAddress: u.emailAddress,
                        isSelected: true,
                        isUnassigned: true
                    })
                });

            theUsers.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));

            setUsers(theUsers.slice());
            setFilteredUsers(theUsers.slice());
            syncSelectAll();
        })
        .catch(err => {
            console.log('Error: Failed to get ' + url + ' with status code ' + err.status);
        });
    },[props.organisationUsers,props.campaignId,users]);

    function onSelectAll(selectAll: boolean) {
        
        filteredUsers.forEach(u => u.isSelected = !selectAll);

        setUsers(users.slice());
        let selectedUsers = users.filter(u => u.isSelected).map(u => u.emailAddress);
        props.onFilterChanged(selectedUsers);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(user: IFilterUser) {
        user.isSelected = !user.isSelected;
        setUsers(users.slice());
        let selectedUsers = users.filter(u => u.isSelected).map(u => u.emailAddress);
        props.onFilterChanged(selectedUsers);
        syncSelectAll();
    };
  
    function syncSelectAll(){
        let selectedUsers = users.filter(u => u.isSelected);
        if(!selectAll && selectedUsers.length == users.length && users.length > 0)
        {
            setSelectAll(true);
        } else if(selectAll && selectedUsers.length < users.length)
        {
            setSelectAll(false);
        }
    }
  
    function onSearchChange(value:string) {
        if(value=="")
        {
            syncSelectAll();
            setFilteredUsers(users);
        } else 
        {
            setFilteredUsers(users.filter(u => u.name.toLowerCase().includes(value.toLowerCase())));
        }
    };
  
    let userCheckboxes: JSX.Element[] = [];
    filteredUsers.forEach(user => {
      userCheckboxes.push(
          <Tooltip title={user.emailAddress + (user.isUnassigned ? ' (not assigned to any images) ' : '' ) }>
            <FormControlLabel
              key={user.emailAddress}
              classes={{ label: user.isSelected ? ( user.isUnassigned ? controlClasses.labelSelectedUnassigned : controlClasses.labelSelected) : controlClasses.label }}
              control={<Checkbox checked={user.isSelected} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500' }} />} onChange={() => onCheckboxChanged(user)} name={user.emailAddress} size={'small'} />}
              label={user.name}
            />
          </Tooltip>
      );
    });

    let selectAllCheckbox = (
      <FormControlLabel
          key={"Select All"}
          classes={{ label: selectAll ? controlClasses.labelSelected : controlClasses.label }}
          control={<Checkbox style={{ color: '#F7B500', opacity: '0.5 !important' }} checked={selectAll} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500', opacity: '0.5 !important' }} />} onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
          label={"Select All"}
        />
    );
  
    return (
      <div className={styles.container}>
        <div className={styles.content}>
            { users.length === 0 && 
              <div style={{width:200,height:40,padding:20}}>No users are currently assigned to images in this campaign</div>
            }
            { users.length > 0 &&
              <FormGroup>
                <TextField  style={{ color: '#F7B500' }} id="user-filter" label="Search" onChange={(event) => onSearchChange(event.target.value)} />
                {selectAllCheckbox}
                <div className={styles.users}>
                  {userCheckboxes}
                </div>
              </FormGroup>
            }
        </div>
      </div>
    );
  }