import {IPropertyValue} from '../../models/IPropertyValue';

// Compare two lists and return a list of the properties that have changed
export function getPropertiesThatHaveChanged(currentList: IPropertyValue[], updatedList: IPropertyValue[]): IPropertyValue[] {

  let differentProperties: IPropertyValue[] = [];

  for (let property of currentList) {

    let updatedProperty = updatedList.find(p=>p.name === property.name);
    if ( updatedProperty == null)
      continue;

    if ( JSON.stringify(property.value) != JSON.stringify(updatedProperty.value))
      differentProperties.push(updatedProperty)
  }

  return differentProperties;
}

// Update the properties in the currentList with the matching ones from the updatedList
 export function updatePropertiesFromUpdates(currentList: IPropertyValue[], updatedList: IPropertyValue[]) {

  for (let property of currentList) {
    let updatedProperty = updatedList.find(p=>p.name === property.name);
    if ( updatedProperty != null)
      property.value = updatedProperty.value;
  }
}

// Return properties from an object as IPropertyValue[]
 export function getPropertyValuesFromProps(props:any): IPropertyValue[] {
  let properties: IPropertyValue[] = [];
    
  for (let propName of Object.keys(props)) {
    let val = props[propName];
    if ( typeof(val) != 'function')
      properties.push({name:propName,value: val});
  }

  return properties;
}
  