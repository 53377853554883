import * as React from 'react';
import { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import { RouteComponentProps } from "react-router";
import { LayerList } from '../../LayerList/LayerList';
import { isUserAuthenticated, fetchWithAuthorisationHeader, getAuthorizationData, postWithAuthorisationHeader, getTokenClaims } from "../../../services/AuthenticationService";
import { setCommonPinpointEventAttributes, sendEventToPinpoint } from "../../../services/PinpointService";
import { UserIcon } from "../../UserIcon/UserIcon";

//Components
import Campaign from '../../Campaign/Campaign';
import { Map } from '../../Map/Map';
import { MapBaseLayerSelector } from '../../MapBaseLayerSelector/MapBaseLayerSelector';
import { ToolBar } from '../../ToolBar/ToolBar';
import { MapSelectionSnackBar } from '../../SnackBar/MapSelectionSnackBar/MapSelectionSnackBar';
import { LinkConfirmationSnackBar } from '../../SnackBar/LinkConfirmationSnackBar/LinkConfirmationSnackBar';
import { GeneralConfirmationSnackBar } from '../../SnackBar/GeneralConfirmationSnackBar/GeneralConfirmationSnackBar';
import { GeneralSnackbar } from '../../SnackBar/GeneralSnackbar/GeneralSnackbar';
import { MapPicker } from '../../MapPicker/MapPicker';
import { ImageSearch } from '../../ImageSearch/ImageSearch/ImageSearch';
import { SegmentDetails } from '../../SegmentDetails/SegmentDetails';
import { ShapefileDataDetails } from '../../ShapefileDataDetails/ShapefileDataDetails';
import { ImageSearchResults } from '../../ImageSearch/ImageSearchResults/ImageSearchResults';
import { DZIViewer } from '../../DZI/DZIViewer/DZIViewer';
import { PopupWindowManagerAndPostMessageStateSync } from '../../StateSync/PopupWindowManagerAndPostMessageStateSync/PopupWindowManagerAndPostMessageStateSync';
import { AssetSearch } from '../../AssetSearch/AssetSearch/AssetSearch';
import { AssetSearchResults } from '../../AssetSearch/AssetSearchResults/AssetSearchResults';
import { ImageCarousel } from '../../ImageCarousel/ImageCarousel';
import { ImageStatusMenu } from '../../ImageStatusMenu/ImageStatusMenu';
import { ImageAssignMenu } from '../../ImageAssignMenu/ImageAssignMenu';
import { UrlViewer } from '../../UrlViewer/UrlViewer';

// common components
import { NoResultsFoundMessage } from '../../Common/Common';

//Models
import { ICoordinate } from '../../../models/ICoordinate';
import { IOpenLayersPositionData } from '../../../models/IOpenLayersPositionData';
import { IMapClick } from '../../../models/IMapClick';
import { IImageSearchCreateResponse } from '../../../models/IImageSearchCreateResponse';
import { IImage } from '../../../models/IImage';
import { ISegment } from '../../../models/ISegment';
import { IAsset } from '../../../models/IAsset';
import { IShapefileData } from '../../../models/IShapefileData';
import { IDZIViewerState } from '../../../models/IDZIViewerState';
import { IRect } from '../../../models/IRect';
import { LinearProgress, Snackbar } from '@material-ui/core';
import { IMapLayer, GeometryType } from '../../../models/IMapLayer';
import { ILinkingResult } from '../../../models/ILinkingResult';
import { IMapPickerConfig } from '../../../models/IMapPickerConfig';
import { IGeneralSnackbarConfig } from '../../../models/IGeneralSnackbarConfig';
import { IGeneralConfirmationSnackbarConfig } from '../../../models/IGeneralConfirmationSnackbarConfig';
import { ISegmentDetailsConfig } from '../../../models/ISegmentDetailsConfig';
import { IShapefileDataDetailsConfig } from '../../../models/IShapefileDataDetailsConfig';
import { IDefect } from '../../../models/IDefect';
import { IOrganisationShapefile } from '../../../models/IOrganisationShapefile';
import { IUserPreference } from '../../../models/IUserPreference';
import { UserPreference } from '../../../enums/UserPreference';
import { IImageStatus } from '../../../models/IImageStatus';
import { IAction } from '../../../models/IAction';
import { IUser } from '../../../models/IUser';

//images
import customPoleImage from '../../../images/custom_pole.png';
import headingImage from '../../../images/heading.png';
import sessionImagesImage from '../../../images/images.png';
import assetImageLink from '../../../images/asset_image_link.png';


import { IAssetSearchResponse } from '../../../models/IAssetSearchResponse';
import { deepClone } from '../../Common/Common';
import { IUrlParams } from '../../../models/IUrlParams';
import { IImageDefectCount } from '../../../models/IImageDefectCount';

const querystring = require('querystring');
const mapLayerIconPath: string = "../../../images/MapLayerIcons/";
let styles = require('./VAAContainer.module.css');
let globalStyles = require('../../../globalStyles.module.css');

const USER_UNLINK_WARNING_COUNT: number = 2;
const USER_LINK_WARNING_COUNT: number = 15;
const USER_UNASSIGN_WARNING_COUNT: number = 15;

const POLYGON_ICON_PREFIX: string = 'Frame_13';


////////// Tabs functionality

enum TabIndex {
    ImageSearch = 0,
    Assets,
    Layers
}

function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            style={{ height: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box height={'100%'} width={'100%'}>
                {children}
            </Box>
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

interface IVAAContainerProps extends RouteComponentProps {
};

interface IVAAContainerState {
    lastMapPosition: ICoordinate | null;
    lastOpenLayersPositionData: IOpenLayersPositionData | null;
    lastMapClick: IMapClick | null;
    mapOptions: any;
    baseMapLayers: any[];
    mapDataLayers: IMapLayer[][];
    currentBaseLayer: string;
    tabValue: number;
    anchorEl: any;
    anchorElSetting: any;
    anchorProductEl: any;
    anchorImageStatusMenuEl: any;
    anchorImageAssignMenuEl: any;
    campaignId: number;
    organisationId: number;
    imageSearch: IImageSearchCreateResponse | null;
    imageSearchZoomExtent: boolean;
    selectedDziImage: IImage | null;
    selectedImageRow: IImage | null;
    selectedAssetRow: IAsset | null;
    selectedSegment: ISegment | null;
    selectedImageIds: number[];
    dziViewerPopupState: IDZIViewerState | null;
    dziViewerExpanded: boolean;
    dziViewerEnabled: boolean;
    dziViewerWidth: number;
    dziViewerHeight: number;
    dziViewerX: number;
    dziViewerY: number;
    urlViewerExpanded: boolean;
    urlViewerEnabled: boolean;
    urlViewerWidth: number;
    urlViewerHeight: number;
    urlViewerX: number;
    urlViewerY: number;
    getDziViewerStateFlag: number;
    isDziViewerPoppedOut: boolean;
    dziViewerWindowRect: IRect | null,
    urlViewerWindowRect: IRect | null,
    isLoadingSearchResults: boolean;
    isSelectModeEnabled: boolean;
    isLinkModeEnabled: boolean;
    isLinkConfirmationRequired: boolean;
    linkingResult: ILinkingResult | null;
    refreshMapLayersFlag: number;
    mapPickerConfig: IMapPickerConfig | null;
    segmentDetailsConfig: ISegmentDetailsConfig | null;
    shapefileDataDetailsConfig: IShapefileDataDetailsConfig | null;
    generalSnackbarConfig: IGeneralSnackbarConfig | null;
    updatedImageIds: number[] | null;
    userPermissions: string[];
    assetSearchResults: IAssetSearchResponse | null;
    isLoadingAssetSearchResults: boolean;
    defects: IDefect[];
    refreshSearchResultsFlag: number;
    canAdmin: boolean;
    canAims: boolean;
    canCmply: boolean;
    orgShapefileLayers: IMapLayer[];
    userPreferences: IUserPreference[];
    imageStatuses: IImageStatus[];
    defectStatuses: string[],
    userImageStatuses: IImageStatus[];
    imageStatusChangeCountConfirmation: number;
    actionPerformed: IAction | null;
    searchAsset: string;
    lastAccessedCampaign: number;
    organisationUsers:IUser[];
    showGenericConfirmationSnackbar: boolean;
    generalConfirmationSnackbarConfig: IGeneralConfirmationSnackbarConfig | null;
    imageSearchFiltersExpanded: boolean;
    loggedInUser: IUser | null;
    isDefectModeEnabled: boolean;
    searchImageUrlParam : IUrlParams | null;
    urlCampaignExists : boolean;
    urlCampaignId : number;
    isUrlImageSearchPerformed: boolean;
    urlLatLong : ICoordinate| null;
    imageDefectCount:IImageDefectCount[];
    urlViewerUrl: string|null;
}


export class VAAContainer extends React.Component<IVAAContainerProps, IVAAContainerState> {
    aimsBaseUrl: string = (process.env.REACT_APP_AIMS3D_URL as string);
    aimsCmplyUrl: string = (process.env.REACT_APP_CMPLY_URL as string);
    intialised: boolean = false;
    dziViewerNodeRef: any = null;
    urlViewerNodeRef: any = null;
    mainContainerNodeRef: any = null;
    dziViewerMinWidth: number = 640;
    dziViewerMinHeight: number = 300;
    urlViewerMinWidth: number = 640;
    urlViewerMinHeight: number = 300;
    userEmail: string = '';

    constructor(props: IVAAContainerProps) {
        super(props);
        //todo: move to API appSettings or DB when user configuration required

        this.dziViewerNodeRef = React.createRef();
        this.urlViewerNodeRef = React.createRef();
        this.mainContainerNodeRef = React.createRef();
        this.initaliseState();
    }

    initaliseState() {
        // Setup the container state
        this.state = {
            tabValue: 0,
            lastMapPosition: null,
            lastOpenLayersPositionData: null,
            lastMapClick: null,
            anchorEl: null,
            anchorElSetting: null,
            anchorProductEl: null,
            anchorImageStatusMenuEl: null,
            anchorImageAssignMenuEl: null,
            isLoadingSearchResults: false,
            isSelectModeEnabled: false,

            //Map config
            currentBaseLayer: localStorage.getItem('SelectedBaseMap')?.toString() ?? 'Dark',
            baseMapLayers: [
                { baseLayerId: 1, baseLayerTitle: 'Dark', active: false, baseLayerName: 'dark-v10', source: 'mapbox', thumbnailUrl: process.env.PUBLIC_URL + '/images/BaseMapLayers/Dark.png' },
                { baseLayerId: 2, baseLayerTitle: 'Light', active: false, baseLayerName: 'light-v10', source: 'mapbox', thumbnailUrl: process.env.PUBLIC_URL + '/images/BaseMapLayers/Light.png' },
                { baseLayerId: 3, baseLayerTitle: 'Outdoor', active: false, baseLayerName: 'outdoors-v10', source: 'mapbox', thumbnailUrl: process.env.PUBLIC_URL + '/images/BaseMapLayers/Outdoor.png' },
                { baseLayerId: 4, baseLayerTitle: 'Satellite', active: false, baseLayerName: 'satellite-streets-v10', source: 'mapbox', thumbnailUrl: process.env.PUBLIC_URL + '/images/BaseMapLayers/Satellite.png' },
                { baseLayerId: 5, baseLayerTitle: 'Road', active: false, baseLayerName: 'streets-v10', source: 'mapbox', thumbnailUrl: process.env.PUBLIC_URL + '/images/BaseMapLayers/Road.png' }
            ],
            mapDataLayers: [
                [
                    {
                        group: "ImageSearchResult",
                        label: "Image search results",
                        thumbnail: sessionImagesImage,
                        hideFromMenu: false,
                        min: 16,
                        max: 23,
                        tiled: false,
                        zOrder: 900,
                        isActive: true,
                        isAvailable: true,
                        stateIsStatic: false,
                        onVisibilityToggle: () => { },
                        geometryType: GeometryType.Point
                    },
                    {
                        group: "ImageSearchResultClustering",
                        label: "Image search result clustering",
                        thumbnail: customPoleImage,
                        hideFromMenu: true,
                        min: 2,
                        max: 16,
                        tiled: false,
                        zOrder: 900,
                        isActive: true,
                        isAvailable: false,
                        stateIsStatic: false,
                        onVisibilityToggle: () => { },
                        geometryType: GeometryType.Point

                    }
                ],
                [{
                    group: "ImageBearingLinked",
                    label: "Linked Image Heading",
                    thumbnail: headingImage,
                    hideFromMenu: false,
                    min: 16,
                    max: 23,
                    tiled: false,
                    zOrder: 1,
                    isActive: false,
                    isAvailable: true,
                    stateIsStatic: false,
                    onVisibilityToggle() { },
                    geometryType: GeometryType.Line
                }],
                [{
                    group: "ImageBearingUnlinked",
                    label: "Unlinked Image Heading",
                    thumbnail: headingImage,
                    hideFromMenu: false,
                    min: 16,
                    max: 23,
                    tiled: false,
                    zOrder: 0,
                    isActive: true,
                    isAvailable: true,
                    stateIsStatic: false,
                    onVisibilityToggle() { },
                    geometryType: GeometryType.Line
                }],
                [{
                    group: "AssetImageLink",
                    label: "Asset Image Link",
                    thumbnail: assetImageLink,
                    hideFromMenu: false,
                    min: 16,
                    max: 23,
                    tiled: false,
                    zOrder: 2,
                    isActive: true,
                    isAvailable: true,
                    stateIsStatic: false,
                    onVisibilityToggle() { },
                    geometryType: GeometryType.Line
                }],
                [{
                    group: "Segment",
                    label: "Pole assets",
                    thumbnail: customPoleImage,
                    hideFromMenu: false,
                    min: 16,
                    max: 23,
                    tiled: false,
                    zOrder: 3,
                    isActive: true,
                    isAvailable: true,
                    stateIsStatic: true,
                    onVisibilityToggle() { },
                    geometryType: GeometryType.Point
                }
                ]
            ],

            mapOptions: {
                wmsApiUrl: process.env.REACT_APP_VAA_API_URL + 'map/wmsBase64',
                mapBoxBaseUrl: 'https://api.mapbox.com/styles/v1/mapbox/{baselayerName}/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ3RtYXBib3giLCJhIjoiY2l3bG9wazQxMDA0bDJ5cXBleHBjbzJrbSJ9._2U0Hn3TbgoLXMsSGP_QWw',
                openTopoMapBaseUrl: 'https://a.tile.opentopomap.org/{z}/{x}/{y}.png',
                dataProjection: 'EPSG:4326',
                mapProjection: 'EPSG:900913',
            },
            campaignId: 0,
            organisationId: 0,
            imageSearch: null,
            imageSearchZoomExtent: false,
            selectedDziImage: null,
            selectedImageRow: null,
            selectedImageIds: [],
            selectedAssetRow: null,
            selectedSegment: null,
            dziViewerPopupState: null,
            dziViewerExpanded: false,
            dziViewerEnabled: false,
            dziViewerWidth: 736,
            dziViewerHeight: 540,
            dziViewerX: 0,
            dziViewerY: 0,
            urlViewerExpanded: false,
            urlViewerEnabled: false,
            urlViewerWidth: 736,
            urlViewerHeight: 540,
            urlViewerX: 0,
            urlViewerY: 0,
            getDziViewerStateFlag: 0,
            isDziViewerPoppedOut: false,
            dziViewerWindowRect: null,
            urlViewerWindowRect: null,
            mapPickerConfig: null,
            isLinkModeEnabled: false,
            isLinkConfirmationRequired: false,
            linkingResult: null,
            generalSnackbarConfig: null,
            segmentDetailsConfig: null,
            shapefileDataDetailsConfig: null,
            updatedImageIds: null,
            refreshMapLayersFlag: 0,
            userPermissions: [],
            assetSearchResults: null,
            isLoadingAssetSearchResults: false,
            defects: [],
            refreshSearchResultsFlag: 0,
            canAdmin: false,
            canAims: false,
            canCmply: false,
            orgShapefileLayers: [],
            userPreferences: [],
            imageStatusChangeCountConfirmation: 15,
            imageStatuses: [],
            defectStatuses: ["Completed","Created","Draft"],
            userImageStatuses: [],
            actionPerformed: null,
            searchAsset: '',
            lastAccessedCampaign: 0,
            organisationUsers:[],
            showGenericConfirmationSnackbar:false,
            generalConfirmationSnackbarConfig: null,
            imageSearchFiltersExpanded: false,
            loggedInUser: null,
            isDefectModeEnabled: false,
            searchImageUrlParam: null,
            urlCampaignExists: false,
            urlCampaignId : 0,
            isUrlImageSearchPerformed: false,
            urlLatLong: null,
            imageDefectCount:[],
            urlViewerUrl: null
        }
    }

    toggleUserPreference(preferenceName: string) {
        let value = this.getUserPreferenceAsBool(preferenceName);

        this.updateUserPreference(preferenceName, (!value).toString());
    }

    getUserPreferenceAsBool(preferenceName: string): boolean {
        let preference = this.state.userPreferences.find(i => i.preferenceName === preferenceName);
        return preference != null ? (preference.preferenceValue == "true" ? true : false) : false;
    }

    updateUserPreference(preferenceName: string, value: string) {

        let data = {
            name: preferenceName,
            value: value
        };

        postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "user/updateUserPreference", data).then(res => {
            if (res.data && res.status === 200) {
                this.setState({ userPreferences: res.data });
            }
        })
            .catch(err => {
                console.log('Error: Failed to update user preference with status code ' + err.status);
            });
    }


    setSelectedDziImage(image: IImage | null) {

        this.setState({ selectedDziImage: image });

        if (image === null)
            this.setState({ dziViewerEnabled: false });

    }

    handleSegmentAttributesPopupState(e: any) {
        if (this.state.segmentDetailsConfig) {
            this.setState({ segmentDetailsConfig: null });
        }

    }

    handleShapefileDataAttributesPopupState(e: any) {
        if (this.state.shapefileDataDetailsConfig) {
            this.setState({ shapefileDataDetailsConfig: null });
        }
    }

    setSelectedAssetRow(asset: IAsset | null) {
        this.setState({ selectedAssetRow: asset });
    }

    setSelectedImageRow(image: IImage | null) {
        this.setState({ selectedImageRow: image });
    }

    addOrRemoveFromSelectedImages(imageId: number, isRemoveEnabled: boolean) {

        var stateArray = [...this.state.selectedImageIds];
        var hasImage = stateArray.includes(imageId);

        if (hasImage) {
            if (isRemoveEnabled) {
                var index = stateArray.indexOf(imageId);
                stateArray.splice(index, 1);
            }
        }
        else {
            //Add image id to array if not present(Select)
            stateArray.push(imageId);
        }
        this.setState({ selectedImageIds: stateArray });
    }

    addOrRemoveListFromSelectedImages(imageIds: number[], isRemoveEnabled: boolean) {

        var stateArray = [...this.state.selectedImageIds];

        imageIds.forEach(imageId => {
            var hasItem = stateArray.includes(imageId);

            if (hasItem) {
                if (isRemoveEnabled) {
                    var index = stateArray.indexOf(imageId);
                    stateArray.splice(index, 1);
                }
            }
            else {
                //Add image to array if not present(Select)
                stateArray.push(imageId);
            }
        });

        this.setState({ selectedImageIds: stateArray });
    }

    componentDidMount() {
        
        let this_ = this;

        const values = querystring.parse(this.props.location.search);
        //if an asset id has been passed in, store in localstorage
        let paramatisedAssetId = values["?assetid"] || values["assetid"];
        if (paramatisedAssetId)
            localStorage.setItem('aims3d.vaa.assetid', paramatisedAssetId);

        let paramCampaign = values["?campaign"] || values["campaign"];
        if (paramCampaign)
            localStorage.setItem('aims3d.vaa.campaign', paramCampaign);

        let paramSession = values["?session"] || values["session"];
        if (paramSession)
            localStorage.setItem('aims3d.vaa.session', paramSession);

        let paramImage = values["?image"] || values["image"];
        if (paramImage)
            localStorage.setItem('aims3d.vaa.image', paramImage);

        let paramDefect = values["?defect"] || values["defect"];
        if (paramDefect)
            localStorage.setItem('aims3d.vaa.defect', paramDefect);

        let paramLatitude = values["?lat"] || values["lat"];
        if (paramLatitude)
            localStorage.setItem('aims3d.vaa.lat', paramLatitude);

        let paramLongitude = values["?lon"] || values["lon"];
        if (paramLongitude)
            localStorage.setItem('aims3d.vaa.lon', paramLongitude);

        // If a token has been passed in the querystring store it in local storage
        if (values["?token"] != null && values["refresh_token"] != null) {
            var accessToken = window.atob(values["?token"]);
            var refreshToken = window.atob(values["refresh_token"]);
            localStorage.setItem('aims3d.authorizationData', JSON.stringify({ token: accessToken, refreshToken: refreshToken }));
            this.props.history.replace('');
            setCommonPinpointEventAttributes();
            let pinpointMessage = {
                eventName: 'login', attributes: { action: 'login', identifier: null, source: 'viewer', detail: '' }
            };
            sendEventToPinpoint(pinpointMessage);
        }
        
        let authData = getAuthorizationData();
        if (authData) {
            this.getAuthData().then(res => {

                if (values["?token"] == null) setCommonPinpointEventAttributes();

                //Allows for authenticationService to access the onTokenRefresh function to update state after token refresh
                let wnd = window as any;
                wnd.tokenChangedCallBack = this;
                this.getOrgShapefileLayers();
                this.getUserPreferences();
                this.getImageStatuses();
                this.getUserImageStatuses();
                this.getOrganisationUsers();

                // if asset id was stored, use now and remove the cookie
                paramatisedAssetId = localStorage.getItem('aims3d.vaa.assetid');
                paramCampaign = localStorage.getItem('aims3d.vaa.campaign');
                paramSession = localStorage.getItem('aims3d.vaa.session');
                paramImage = localStorage.getItem('aims3d.vaa.image');
                paramDefect = localStorage.getItem('aims3d.vaa.defect');
                paramLatitude = localStorage.getItem('aims3d.vaa.lat');
                paramLongitude = localStorage.getItem('aims3d.vaa.lon');

                if (this.checkUserAuth()) {
                    let resetHistory = false;

                    const isUrlParam = (paramName: any, localStorageName : string ) =>{
                        if(paramName){
                            localStorage.removeItem(localStorageName);
                            resetHistory = true;
                            return paramName;
                        }   
                    }


                    let assetId = isUrlParam(paramatisedAssetId, 'aims3d.vaa.assetid');
                    if(assetId){
                        this.setState({ searchAsset: String(assetId), tabValue: TabIndex.Assets });
                    }
                
                    const imageParamBuilder: Partial<IUrlParams> = {};
                    imageParamBuilder.campaign = isUrlParam(paramCampaign, 'aims3d.vaa.campaign');
                    imageParamBuilder.session = isUrlParam(paramSession, 'aims3d.vaa.session');
                    imageParamBuilder.image = isUrlParam(paramImage, 'aims3d.vaa.image');
                    imageParamBuilder.defect = isUrlParam(paramDefect, 'aims3d.vaa.defect');
                    
                    if(imageParamBuilder.campaign || imageParamBuilder.session || imageParamBuilder.image || imageParamBuilder.defect){
                        this.setState({ searchImageUrlParam : imageParamBuilder as IUrlParams });
                    }

                    const latLongParamBuilder: Partial<ICoordinate> = {};
                    latLongParamBuilder.latitude = isUrlParam(paramLatitude, 'aims3d.vaa.lat');
                    latLongParamBuilder.longitude = isUrlParam(paramLongitude, 'aims3d.vaa.lon');
                    
                    if(latLongParamBuilder.latitude && latLongParamBuilder.longitude){
                        this.setState({ urlLatLong : latLongParamBuilder as ICoordinate });
                    }
                    else {
                        
                        let lastMapPos = localStorage.getItem('lastMapPosition');

                        // For some reason we need to use a timeout when deployed or it does not work consistently on refresh
                        setTimeout(() => {
                            if ( lastMapPos )
                            {
                                this_.setState({ urlLatLong : JSON.parse(lastMapPos) });
                            }
                        }, 200);
                    }
                    
                    if(resetHistory){
                        this.props.history.replace('');
                    }
                }

                this.intialised = true;
            })
            .catch((error) => {
               window.location.href = this.aimsBaseUrl + "/#/home?product=AIMSVAA";
            });

        }
        else {
            // No token.
           window.location.href = this.aimsBaseUrl + "/#/home?product=AIMSVAA";
        }
    }

    isSelectModeEnabledChanged(selectModeEnabled: boolean) {
        this.setState({ isSelectModeEnabled: selectModeEnabled });
        if (selectModeEnabled === false) {
            this.setState({
                selectedImageIds: [],
                linkingResult: { result: 'cancelled' },
                isLinkModeEnabled: false,
                isLinkConfirmationRequired: false
            });
        } else {
            let message = "Click on an individual image to select, or hold right-click to draw a selection box";
            this.setState({
                generalSnackbarConfig: { messageType: 'info', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 3000 },
                isLinkModeEnabled: this.state.userPermissions.includes('CanLinkUnlink')
            });
        }
    }

    getOrgShapefileLayers() {
        fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "shapefile/getShapefileLayers/").then(res => {
            if (res.status !== 200) return;
            let shapefileLayers: IOrganisationShapefile[] = res.data as IOrganisationShapefile[];
            if (!shapefileLayers) return;
            //todo z order sorting
            //map layers to seperate array for diff to standard layers
            let z = 50;
            let layers: IMapLayer[] = shapefileLayers.map(x => {
                let y: IMapLayer = {
                    group: "Shapefiles",
                    thumbnail: `${mapLayerIconPath}${(x.dataType === 'Line' ? x.lineType : x.dataType === 'Polygon' ? POLYGON_ICON_PREFIX : x.icon)}${x.displayColor ? "_" + x.displayColor : ""}.png`,
                    label: x.description,
                    hideFromMenu: false,
                    min: 16,
                    max: 23,
                    tiled: false,
                    zOrder: 50,
                    isActive: x.isVisibleByDefault,
                    isAvailable: true,
                    stateIsStatic: true,
                    onVisibilityToggle: () => { },
                    fileId: x.id,
                    geometryType:
                        x.dataType === 'Line' ? GeometryType.Line
                            : x.dataType === 'Polygon' ? GeometryType.Polygon
                                : GeometryType.Point
                }

                return y;
            });
            this.setState({ orgShapefileLayers: layers });

        })
    }

    checkUserAuth() {
        if (this.intialised && !isUserAuthenticated()) {
            localStorage.removeItem('aims3d.authorizationData');
            window.location.href = this.aimsBaseUrl + "/#/home?product=AIMSVAA";
            return false;
        }
        return true;
    }

    async getAuthData() {

        // Get permissions and roles from Server. If fetch returns 401, it will try to refresh (interceptor). If refresh fails, it will redirect to Aims3d
        var authData = await fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "authorisation").then(res => {
            if (res && res.status !== 200) {
                throw "Failed to get authorisation data";
            }

            // Get the authorisation data from local storage and update it with the permission and roles from Server
            // For code to get here, the fetch was successful as it would have redirected if not.
            var existingAuthData = getAuthorizationData();
            existingAuthData.organisationId = res.data.organisationId;
            existingAuthData.organisationName = res.data.organisationName;
            this.userEmail = res.data.userEmail;
            existingAuthData.roles = res.data.roles;
            existingAuthData.canAdmin = res.data.canAdmin;
            localStorage.setItem('aims3d.authorizationData', JSON.stringify(existingAuthData));

            // Get the claims from the token stored in local storage
            var claims = getTokenClaims();
            var products = claims["products"];
            this.setState({ organisationId: existingAuthData.organisationId, userPermissions: res.data.userPermissions, canAdmin: res.data.canAdmin, canAims: products.includes("AIMS 3D"), canCmply : products.includes("AIMS CMPLY") });
        })
    }

    getUserPreferences() {
        fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "user/getUserPreferences").then(res => {
            if (res && res.status !== 200) return;

            var preferenceData : IUserPreference[] = res.data;

            var lastAccessedCampaignPreference = preferenceData.find(i => i.preferenceName == UserPreference.LAST_ACCESSED_CAMPAIGN);
            if (lastAccessedCampaignPreference) {
                this.setState({ lastAccessedCampaign: parseInt(lastAccessedCampaignPreference.preferenceValue) });
            }

            var lastAccessedMap = preferenceData.find(i => i.preferenceName == UserPreference.LAST_SELECTED_BASE_MAP);
            if (lastAccessedMap) {
                let selectedMap = lastAccessedMap.preferenceValue ?? 'Dark';
                if(this.state.baseMapLayers.find(bml => bml.baseLayerTitle === selectedMap)) {
                    if(this.state.currentBaseLayer !== selectedMap) {
                        localStorage.setItem('SelectedBaseMap', selectedMap);
                        this.setState({ currentBaseLayer: selectedMap });
                    }
                }
                else if(this.state.currentBaseLayer !== 'Dark') this.onBaseMapLayerSelectedHandler('Dark'); //revert to dark if user selected map no longer exists
            }
            else if(this.state.currentBaseLayer !== 'Dark') this.onBaseMapLayerSelectedHandler('Dark'); //revert to dark if user has not selected any map
            
            this.setState({ userPreferences: preferenceData });
        })
    }

    getImageStatuses() {
        fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "session/imageStatuses").then(res => {
            if (res && res.status !== 200) return;
            this.setState({ imageStatuses: res.data });
        })
    }

    getUserImageStatuses() {
        fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "session/userImageStatuses").then(res => {
            if (res && res.status !== 200) return;
            this.setState({ userImageStatuses: res.data });
        })
    }

    getOrganisationUsers() {
        // Get all the users (active and inactive) for the users org from Aims
        let aims_api_url = process.env.NODE_ENV === "development" ? "https://localhost:44300/" : process.env.REACT_APP_AIMS3D_URL;

        fetchWithAuthorisationHeader(aims_api_url + "api/user/organisation").then(res => {
            if (res && res.status !== 200) return;
            this.setState({ organisationUsers: res.data as IUser[] });
            this.assignLoggedInUser();
        })
    }

    assignLoggedInUser() {
        var user = this.state.organisationUsers.find(user => user.emailAddress == this.userEmail);
        if (user)
        {
            this.setState({ loggedInUser: user });
        }
    }

    async signOut() {
        window.location.href = "/signOut";
    }

    async switchProduct(productName: string) {
        var authData = await getAuthorizationData();
        switch (productName) {
            case "AIMS": {
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    await fetchWithAuthorisationHeader(this.aimsBaseUrl.replace('44301', '44300') + "api/account/userProductSwitch/AIMS3D");
                } else {
                    await fetchWithAuthorisationHeader(this.aimsBaseUrl + "api/account/userProductSwitch/AIMS3D");
                }

                let mapPosition = '';
                if ( this.state.lastMapPosition != null ){
                    mapPosition = '&lat=' + this.state.lastMapPosition.latitude + '&lon=' + this.state.lastMapPosition.longitude; 
                }

                window.location.href = this.aimsBaseUrl + "?rt=" + authData.refreshToken + mapPosition;
                break;
            }
            case "Admin": {
                window.location.href = this.aimsBaseUrl + "admin/" + "?rt=" + authData.refreshToken;
                break;
            }

            case "Cmply": {
                if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                    await fetchWithAuthorisationHeader(this.aimsBaseUrl.replace('44301', '44300') + "api/account/userProductSwitch/AIMSCMPLY");
                } else {
                    await fetchWithAuthorisationHeader(this.aimsBaseUrl + "api/account/userProductSwitch/AIMSCMPLY");
                }
                window.location.href = this.aimsCmplyUrl + "?rt=" + authData.refreshToken;
                break;
            }
            default: break;
        }
    }

    updateMapDataLayerActiveState = (group: string, label: string, checked: boolean) => {
        //note this doesnt check for duplicate labels.
        let deepClone = JSON.parse(JSON.stringify(this.state.mapDataLayers)) as IMapLayer[][];
        deepClone.forEach(layerGroup => {
            for (var i = 0; i < layerGroup.length; i++) {
                if (layerGroup[i].group === "Shapefiles") {
                    //if shapefile group, need to check label as they are dynamically
                    //generated under the one mapserver group
                    for (var j = 0; j < layerGroup.length; j++) {
                        if (layerGroup[j].label === label) {
                            layerGroup[j].isActive = checked;
                            break;
                        }
                    }
                    break;
                }
                else if (layerGroup[i].group === group) {
                    //if any member of a layer set has the matching group name, set all members
                    for (var j = 0; j < layerGroup.length; j++) {
                        layerGroup[j].isActive = checked;
                    }
                    break;
                }
            }
        })

        //update the state array
        this.setState({ mapDataLayers: deepClone });

        if (group === 'Segment' && !checked && this.state.isLinkModeEnabled) {
            this.setState({ isLinkModeEnabled: false, linkingResult: { result: 'cancelled' } });
        }
    }

    getShapefileMapLayers(): IMapLayer[] {

        let shapeFileLayers: IMapLayer[] = [];

        this.state.mapDataLayers.forEach(layerGroup => {
            for (var i = 0; i < layerGroup.length; i++) {
                if (layerGroup[i].group === "Shapefiles") {
                    shapeFileLayers.push(layerGroup[i]);
                }
            }
        });

        return shapeFileLayers;
    }

    isSegmentLayerVisible() {

        let isVisible = false;

        let group = 'Segment';

        this.state.mapDataLayers.forEach(layerGroup => {
            for (var i = 0; i < layerGroup.length; i++) {
                if (layerGroup[i].group == group) {
                    isVisible = true;
                    for (var j = 0; j < layerGroup.length; j++) {
                        if (!layerGroup[j].isActive)
                            isVisible = false;
                    }
                    break;
                }
            }
        })

        return isVisible;
    }

    updateMapDataLayerAvailability(prevState: IVAAContainerState) {
        if (this.state.lastOpenLayersPositionData && prevState.lastOpenLayersPositionData
            && this.state.lastOpenLayersPositionData.zoom != prevState.lastOpenLayersPositionData.zoom) {
            //update map layer control from Mapzoom
            let deepClone = JSON.parse(JSON.stringify(this.state.mapDataLayers)) as IMapLayer[][];
            deepClone.forEach(layerGroup => {
                //layer is available if zoom is within layer arrays

                //first get min and max zoom extents for group
                let min = 99; let max = 0;
                layerGroup.forEach(l => {
                    min = l.min < min ? l.min : min;
                    max = l.max > max ? l.max : max;
                });
                layerGroup.forEach(l => {
                    if (this.state.lastOpenLayersPositionData
                        && this.state.lastOpenLayersPositionData.zoom >= min
                        && this.state.lastOpenLayersPositionData.zoom <= max) {
                        layerGroup.forEach(l => {
                            l.isAvailable = true;
                        })
                    } else {
                        layerGroup.forEach(l => {
                            l.isAvailable = false;
                        })
                    }
                });
            }
            )
            this.setState({ mapDataLayers: deepClone });
        }
    }

    updateOrgShapefileLayerSource(prevState: IVAAContainerState) {
        //update maplayers as new layers are added
        let lenDelta: number = this.state.orgShapefileLayers.length - prevState.orgShapefileLayers.length;
        if (lenDelta > 0) {
            let clone: IMapLayer[][] = deepClone<IMapLayer[][]>(this.state.mapDataLayers);
            //get the offset starting ith position
            for (let i = this.state.orgShapefileLayers.length - lenDelta; i < this.state.orgShapefileLayers.length; i++) {
                clone.push([this.state.orgShapefileLayers[i]]);
            }
            this.setState({ mapDataLayers: clone });
        }
    }

    componentDidUpdate(prevProps: IVAAContainerProps, prevState: IVAAContainerState) {
        this.updateMapDataLayerAvailability(prevState);
        this.updateOrgShapefileLayerSource(prevState);
        if (this.state.selectedDziImage != null && this.state.updatedImageIds != null && this.state.updatedImageIds.length > 0 && this.state.updatedImageIds != prevState.updatedImageIds) {

            let selectedDziId = this.state.selectedDziImage.id;
            //check if the dzi image has been updated
            if (this.state.updatedImageIds.find(id => id === selectedDziId) != null) {
                // fetch and update only if action is not linking / unlinking the image as those two are already calling this method on success
               if(this.state.actionPerformed != null && ['linkedImages', 'unlinkedImages'].indexOf(this.state.actionPerformed.type) == 0)
                this.refreshSelectedDziImage(null, null);
            }
        }
    }

    onSegmentSelectedFromMap(segment: ISegment, screenX: number, screenY: number) {
        let ids: number[] = [Number(segment.id)];
        let fetchUrl = process.env.REACT_APP_VAA_API_URL + "segment/fetchBySegmentIds";
        let linkedImagesUrl = process.env.REACT_APP_VAA_API_URL + "segment/fetchLinkedImagesBySegment/" + segment.id;



        postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "segment/fetchBySegmentIds", ids).then(res => {
            if (res.data && res.status === 200) {
                let foundSegment: ISegment = res.data[0] as ISegment;

                fetchWithAuthorisationHeader(linkedImagesUrl).then(res => {
                    if (res.data && res.status === 200) {
                        foundSegment.linkedImages = res.data as IImage[];
                        this.setState({ segmentDetailsConfig: { segment: foundSegment, screenX: screenX, screenY: screenY } });
                    }
                })
                    .catch(err => {
                        console.log('Error: Failed to get ' + fetchUrl + ' with status code ' + err.status);
                    })
            }
        })
            .catch(err => {
                console.log('Error: Failed to get ' + fetchUrl + ' with status code ' + err.status);
            });
    }

    onShapeFileDataSelectedFromMap(shapefileData: IShapefileData, screenX: number, screenY: number) {
        let ids: number[] = [Number(shapefileData.id)];
        let fetchUrl = process.env.REACT_APP_VAA_API_URL + "shapefile/fetchShapefilesByShapefileDataIds";

        postWithAuthorisationHeader(fetchUrl, ids).then(res => {
            if (res.data && res.status === 200) {
                let foundShapefileData: IShapefileData = res.data[0] as IShapefileData;
                this.setState({ shapefileDataDetailsConfig: { shapefileData: foundShapefileData, screenX: screenX, screenY: screenY } });
            }
        })
            .catch(err => {
                console.log('Error: Failed to get ' + fetchUrl + ' with status code ' + err.status);
            });
    }

    onImageSelectedFromMap(image: IImage) {
        this.setSelectedDziImage(image);

        if (!this.state.isDziViewerPoppedOut)
            this.setState({ dziViewerEnabled: true });
    }

    async linkSelectedImagesToPole() {
        let data = {
            segmentId: this.state.selectedSegment?.id,
            imageIds: this.state.selectedImageIds
        };

        postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "segment/linkImages", data).then(res => {

            let message = this.state.selectedImageIds.length + ' image' + (this.state.selectedImageIds.length > 1 ? 's ' : ' ') + 'linked to asset ' + this.state.selectedSegment?.externalReferenceId + ' successfully';

            let updatedImageIds = [...this.state.selectedImageIds];

            this.setState({
                generalSnackbarConfig: { messageType: 'success', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 3000 },
                selectedImageIds: [],
                linkingResult: { result: 'linked' },
                isLinkModeEnabled: false,
                isSelectModeEnabled: false,
                refreshMapLayersFlag: this.state.refreshMapLayersFlag + 1,
                updatedImageIds: updatedImageIds
            });
            this.setState({ selectedSegment: null });
            let seg = this.state.segmentDetailsConfig;
            if (seg) {
                this.onSegmentSelectedFromMap(seg.segment, seg.screenX, seg.screenY);
            }

            this.refreshSelectedDziImage({ type: 'linkedImages', data: { result: 'linked' } }, updatedImageIds);
        })
            .catch(err => {

                if (err.response) {
                    console.log("Failed to link images. Code = " + err.response.status + ' Error = ' + err.response.data);
                } else {
                    console.log("Failed to link images.  No response object");
                }

                let message = 'Failed to link to pole.  Please try again or contact Support if the problem persists';

                this.setState({
                    generalSnackbarConfig: { messageType: 'error', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 6000 },
                    selectedImageIds: [],
                    linkingResult: { result: 'failed' },
                    isLinkModeEnabled: false,
                    isSelectModeEnabled: false
                });

            })

    }

    async unlinkSelectedImagesFromPoles() {

        postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "segment/unlinkImages", this.state.selectedImageIds).then(res => {

            let message = this.state.selectedImageIds.length + ' image' + (this.state.selectedImageIds.length > 1 ? 's ' : ' ') + 'unlinked successfully';

            let updatedImageIds = [... this.state.selectedImageIds];

            this.setState({
                generalSnackbarConfig: { messageType: 'success', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 3000 },
                selectedImageIds: [],
                linkingResult: { result: 'unlinked' },
                isLinkModeEnabled: false,
                isSelectModeEnabled: false,
                refreshMapLayersFlag: this.state.refreshMapLayersFlag + 1,
                updatedImageIds: updatedImageIds
            });
            let seg = this.state.segmentDetailsConfig;
            if (seg) {
                this.onSegmentSelectedFromMap(seg.segment, seg.screenX, seg.screenY);
            }

            this.refreshSelectedDziImage({ type: 'unlinkedImages', data: { result: 'unlinked' } }, updatedImageIds);

        })
            .catch(err => {

                if (err.response) {
                    console.log("Failed to unlink images. Code = " + err.response.status + ' Error = ' + err.response.data);
                } else {
                    console.log("Failed to unlink images.  No response object");
                }

                let message = 'Failed to unlink images.  Please try again or contact Support if the problem persists';

                this.setState({
                    generalSnackbarConfig: { messageType: 'error', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 6000 },
                    selectedImageIds: [],
                    linkingResult: { result: 'failed' },
                    isLinkModeEnabled: false,
                    isSelectModeEnabled: false
                });

            })


    }


    async refreshSelectedDziImage(actionPerformed: IAction | null, updatedImageIds: number[] | null) {

        if (this.state.selectedDziImage == null)
            return;

        postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "search/fetchBySessionImageIds", [this.state.selectedDziImage.id]).then(res => {

            let images = res.data as IImage[];
            if (images[0].id != null)
                this.setState({ selectedDziImage: images[0] });

            if (actionPerformed != null && ['linkedImages', 'unlinkedImages'].indexOf(actionPerformed.type)>=0)
                this.setState({
                    actionPerformed: actionPerformed,
                    updatedImageIds: updatedImageIds
                });
            else
                this.setState({
                    actionPerformed: { type: 'refreshSelectedDziImage', data: {  } }
                });
               
        })
            .catch(err => {
                console.log("Failed to get images");
            })
    }

    setImageStatus(status: string) {

        let data = {
            imageIds: this.state.selectedImageIds,
            status: status
        };

        postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "session/updateSessionImageStatus", data).then(res => {

            let message = this.state.selectedImageIds.length + ' image' + (this.state.selectedImageIds.length > 1 ? 's ' : ' ') + 'changed to ' + status + ' status successfully';

            let updatedImageIds = [...this.state.selectedImageIds];

            this.setState({
                generalSnackbarConfig: { messageType: 'success', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 3000 },
                refreshMapLayersFlag: this.state.refreshMapLayersFlag + 1,
                updatedImageIds: updatedImageIds,
                actionPerformed: { type: 'statusChange', data: { status: status } },
                isLinkModeEnabled: false
            });

        })
            .catch(err => {

                console.log("Failed to set image statuses. Code = " + err.response.status + ' Error = ' + err.response.data);

                let message = 'Failed to change image status.  Please try again or contact Support if the problem persists';

                this.setState({
                    generalSnackbarConfig: { messageType: 'error', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 6000 },
                });
            })
    }

    assignImages(user: IUser) {

        let data = {
            imageIds: this.state.selectedImageIds,
            assigneeEmailAddress: user.emailAddress,
            assigneeFirstName: user.firstName,
            assigneeSurname: user.surname,
        };

        postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "session/assignImage", data).then(res => {

            let message = this.state.selectedImageIds.length + ' image' + (this.state.selectedImageIds.length > 1 ? 's ' : ' ') + 'assigned to ' + user.firstName + ' ' + user.surname + ' successfully';

            let updatedImageIds = [...this.state.selectedImageIds];

            this.setState({
                generalSnackbarConfig: { messageType: 'success', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 3000 },
                actionPerformed: { type: 'assign', data: { userEmailAddress: user.emailAddress } },
                updatedImageIds: updatedImageIds,
                isLinkModeEnabled: false
            });
        })
        .catch(err => {

            console.log("Failed to set assign images. Code = " + err.response.status + ' Error = ' + err.response.data);

            let message = 'Failed to assign images.  Please try again or contact Support if the problem persists';

            this.setState({
                generalSnackbarConfig: { messageType: 'error', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 6000 },
            });
        })
    }

    async unassignSelectedImages() {

        postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "session/unassignImage", this.state.selectedImageIds).then(res => {

            let message = this.state.selectedImageIds.length + ' image' + (this.state.selectedImageIds.length > 1 ? 's ' : ' ') + 'unassigned successfully';

            let updatedImageIds = [... this.state.selectedImageIds];

            this.setState({
                generalSnackbarConfig: { messageType: 'success', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 3000 },
                refreshMapLayersFlag: this.state.refreshMapLayersFlag + 1,
                updatedImageIds: updatedImageIds,
                actionPerformed: { type: 'unassign', data: null },
                isLinkModeEnabled: false
            });
        })
        .catch(err => {

            if (err.response) {
                console.log("Failed to unassign images. Code = " + err.response.status + ' Error = ' + err.response.data);
            } else {
                console.log("Failed to unassign images.  No response object");
            }

            let message = 'Failed to unassign images.  Please try again or contact Support if the problem persists';

            this.setState({
                generalSnackbarConfig: { messageType: 'error', message: message, verticalAnchorOrigin: 'bottom', autoHideDuration: 6000 },
            });
        })
    }

    showRefreshSearchResultsAlert() {
        if (this.state.generalSnackbarConfig != null) {

            const actionLink = (
                <React.Fragment>
                    <Link style={{ fontSize: 14, marginRight: 15 }} onClick={() => this.setState({ refreshSearchResultsFlag: this.state.refreshSearchResultsFlag + 1, generalSnackbarConfig: null })}>
                        REFRESH
                    </Link>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => this.setState({ generalSnackbarConfig: null })}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            );

            let config: IGeneralSnackbarConfig = { messageType: 'info', message: 'Search list needs to be refreshed to show the correct results', verticalAnchorOrigin: 'bottom', autoHideDuration: null, action: actionLink };
            let time: number = this.state.generalSnackbarConfig != null && this.state.generalSnackbarConfig.autoHideDuration != null ? this.state.generalSnackbarConfig.autoHideDuration : 1000;
            let this_ = this;
            setTimeout(() => {
                this_.setState({ generalSnackbarConfig: config });
            }, time);
        }
    }

    onBaseMapLayerSelectedHandler = (baseMapLayer: string) => {
        this.setState({ currentBaseLayer: baseMapLayer }); 
        this.updateUserPreference(UserPreference.LAST_SELECTED_BASE_MAP, baseMapLayer);
        localStorage.setItem('SelectedBaseMap', baseMapLayer);
    }

    render() {
        this.checkUserAuth();

        const tabStyle = {
            minWidth: '33.5%',
            backgroundColor: '#3E3F41',
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: 1.25
        };

        let selectedDziImageDisplayStyle = this.state.selectedDziImage != null ? 'block' : 'none';

        let headerHeight = 47;
        let windowHeaderHeight = 48;
        let clientHeight = this.mainContainerNodeRef.current != null ? this.mainContainerNodeRef.current.clientHeight : 0;
        let clientWidth = this.mainContainerNodeRef.current != null ? this.mainContainerNodeRef.current.clientWidth : 0;

        let showDziViewer = this.state.dziViewerEnabled && !this.state.isDziViewerPoppedOut;

        let dziViewerContainerStyleBase = {
            width: this.state.dziViewerWidth,
            height: this.state.dziViewerHeight,
            display: showDziViewer ? 'block' : 'none',
        };

        let dziViewerExpandedStyle = {
            left: 0,
            top: headerHeight,
            height: clientHeight,
            width: '100%'
        };

        let dziViewerContainerStyle = this.state.dziViewerExpanded ? { ...dziViewerContainerStyleBase, ...dziViewerExpandedStyle } : dziViewerContainerStyleBase;

        let dziViewerHeight = dziViewerContainerStyle.height - windowHeaderHeight;

        let dziViewerDraggablePosition = this.state.dziViewerExpanded ? { x: 0, y: 0 } : { x: this.state.dziViewerX, y: this.state.dziViewerY };


        let urlViewerDisplayStyle = this.state.urlViewerUrl != null ? 'block' : 'none';
        let showUrlViewer = this.state.urlViewerEnabled;

        let urlViewerContainerStyleBase = {
            width: this.state.urlViewerWidth,
            height: this.state.urlViewerHeight,
            display: showUrlViewer ? 'block' : 'none',
        };

        let urlViewerExpandedStyle = {
            left: 0,
            top: headerHeight,
            height: clientHeight,
            width: '100%'
        };

        let urlViewerContainerStyle = this.state.urlViewerExpanded ? { ...urlViewerContainerStyleBase, ...urlViewerExpandedStyle } : urlViewerContainerStyleBase;

        let urlViewerHeight = urlViewerContainerStyle.height - windowHeaderHeight;

        let urlViewerDraggablePosition = this.state.urlViewerExpanded ? { x: 0, y: 0 } : { x: this.state.urlViewerX, y: this.state.urlViewerY };


        let loadingContent = (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                <LinearProgress style={{ width: '260px', color: 'red', marginBottom: '8px' }} />
                <span className={styles.imagesMessageText}>This could take a few seconds</span>
            </Box>);

        //reusing in multiple places
        let noResults = (
            <Box className={styles.imagesMessageText} display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '90%' }}>
                <img src={process.env.PUBLIC_URL + '/images/noresults.png'} title='No Campaign Selected' style={{ paddingBottom: '10%', width: '38%' }} />
                <span>Please select a campaign</span>
            </Box>);

        let imagesTab: JSX.Element;
        let assetsTab: JSX.Element;
        let layersTab: JSX.Element;

        if (this.state.campaignId == 0) {
            imagesTab = noResults;
        } else {
            imagesTab = (<Box display="flex" flexDirection="column" height={'100%'}>
                <Box className={styles.imageSearchContainer}>
                    <ImageSearch
                        actionPerformed={this.state.actionPerformed}
                        linkingResult={this.state.linkingResult}
                        imageStatuses={this.state.imageStatuses}
                        defectStatuses={this.state.defectStatuses}
                        organisationUsers={this.state.organisationUsers}
                        refreshSearchResultsFlag={this.state.refreshSearchResultsFlag}
                        urlCampaignExists={this.state.urlCampaignExists}
                        urlSession={this.state.searchImageUrlParam?.session}
                        urlImage={this.state.searchImageUrlParam?.image}
                        isUrlImageSearchPerformed = {this.state.isUrlImageSearchPerformed}
                        campaignId={this.state.campaignId}
                        onUpdateIsLoadingCheck={(isLoading: boolean) => this.setState({ isLoadingSearchResults: isLoading })} onImageSearchCreated={(newImageSearch: IImageSearchCreateResponse, zoomToExtent: boolean) => { this.setState({ imageSearch: newImageSearch, imageSearchZoomExtent: zoomToExtent, selectedImageIds: [] }); }}
                        onSearchResultsNeedRefreshing={() => {
                            this.showRefreshSearchResultsAlert();
                        }}
                        onFiltersExpandedChanged={(isExpanded:boolean) => {
                            this.setState({imageSearchFiltersExpanded:isExpanded});
                        }}
                        onUrlSearchPerformed={()=>{
                            this.setState({isUrlImageSearchPerformed:true});
                        }}

                    />
                </Box>
                <Box flexGrow={1} className={styles.imageSearchResultsContainer}>
                    {this.state.isLoadingSearchResults ? <Box style={{ height: '100%' }}>{loadingContent}</Box> :
                        <ImageSearchResults
                            imageSearchFiltersExpanded={this.state.imageSearchFiltersExpanded}
                            dziSelectionSelectsRow={this.getUserPreferenceAsBool(UserPreference.OPENING_DZI_FROM_RESULTS_LIST_SELECTS_IMAGE)}
                            isVisible={this.state.tabValue === TabIndex.ImageSearch}
                            linkingResult={this.state.linkingResult}
                            imageStatuses={this.state.imageStatuses}
                            imageSearch={this.state.imageSearch}
                            imageDefectCount={this.state.imageDefectCount}
                            canViewDefects = { this.state.userPermissions.includes('CanReadDefect')}
                            onDziImageSelected={(image: IImage) => {
                                this.setSelectedDziImage(image);
                                if (!this.state.isDziViewerPoppedOut)
                                    this.setState({ dziViewerEnabled: true });
                                let pinpointMessage = {
                                    eventName: 'image', attributes: { action: 'image-click', identifier: image.id.toString(), source: 'search-list', detail: this.state.campaignId }
                                };
                                sendEventToPinpoint(pinpointMessage);
                            }}

                            onImageRowSelected={(image: IImage) => {
                                this.setSelectedImageRow(image);
                            }}
                            selectedDziImageId={this.state.selectedDziImage ? this.state.selectedDziImage.id : 0}
                            isImageSelectionEnabled={this.state.isSelectModeEnabled}
                            onImageCheckboxChecked={(image: IImage) => {
                                this.addOrRemoveFromSelectedImages(image.id, true);
                            }}
                            selectedImageIds={this.state.selectedImageIds}
                            updatedImageIds={this.state.updatedImageIds}
                            toggleIsImageSelectionEnabled={() => {
                                this.setState({ isSelectModeEnabled: !this.state.isSelectModeEnabled, selectedImageIds: [], isLinkConfirmationRequired: false, isLinkModeEnabled: false, linkingResult: { result: 'cancelled' },showGenericConfirmationSnackbar:false });
                                this.isSelectModeEnabledChanged(!this.state.isSelectModeEnabled);
                            }}
                            onImagesSelected={(imageIds: number[]) => this.setState({ selectedImageIds: imageIds })}
                            onSearchResultsNeedRefreshing={() => {
                                this.showRefreshSearchResultsAlert();
                            }}
                        />}
                </Box>
            </Box>);
        }
        //assets
        if (this.state.organisationId == 0) {
            assetsTab = noResults;
        } else {
            assetsTab = (
                <Box display="flex" flexDirection="column" height={'100%'} style={{ maxHeight: '100%' }}>
                    <Box className={styles.imageSearchContainer} style={{ marginBottom: !this.state.assetSearchResults ? '-74.7px' : '0px' }}>
                        <AssetSearch
                            shapefileLayers={this.getShapefileMapLayers()}
                            onAssetSearchUpdated={(e: any) => this.setState({ assetSearchResults: e })}
                            onUpdateIsLoadingCheck={(isLoading: boolean) => this.setState({ isLoadingAssetSearchResults: isLoading })}
                            onAssetSearchCreated={(e: any) => this.setState({ assetSearchResults: e })}
                            onSearchStarted={(e: string) => this.setState({ assetSearchResults: null, isLoadingAssetSearchResults: true })}
                            triggerSearch={this.state.searchAsset}
                        />
                    </Box>

                    { !this.state.assetSearchResults && !this.state.isLoadingAssetSearchResults
                        ? (<NoResultsFoundMessage imageUrl={process.env.PUBLIC_URL + "/images/noresults.png"} message="" />)
                        : !this.state.assetSearchResults && this.state.isLoadingAssetSearchResults
                            ? (<Box style={{ height: "100%" }}>{loadingContent}</Box>)
                            : this.state.assetSearchResults && this.state.assetSearchResults.segments.length <= 0 && this.state.assetSearchResults.shapefiles.length <= 0
                                ? (<Box style={{ height: "100%" }}>
                                    { <NoResultsFoundMessage imageUrl={process.env.PUBLIC_URL + "/images/noresults.png"} message="No assets found" />}
                                </Box>
                                )
                                : (<Box flexGrow={1} height='100%' style={{ maxHeight: '100%', backgroundColor: '#292A2B' }} className={styles.imageSearchResultsContainer}>
                                    <AssetSearchResults
                                        shapefileLayers={this.getShapefileMapLayers()}
                                        assetSearchResults={this.state.assetSearchResults}
                                        onAssetRowMouseClick={(asset: IAsset) => { this.setSelectedAssetRow(asset) }}
                                    />
                                </Box>
                                )
                    }
                </Box>
            );
        }

        //layers
        layersTab = (
            <Box display="flex" flexDirection="column" height={'100%'}>
                <Box className={styles.mapLayersContainer}>
                    <Box flexGrow={1} className={styles.imageSearchResultsContainer}>
                        {this.state.mapDataLayers.length > 0 &&
                            <LayerList mapLayers={this.state.mapDataLayers}
                                onToggleVisibility={(group: string, label: string, checked: boolean) => { this.updateMapDataLayerActiveState(group, label, checked) }}
                            />}
                    </Box>
                </Box>
            </Box>
        );

        return (
            <div className={styles.vaaContainer} onContextMenu={(e: any) => e.preventDefault()}>
                {/*
                The PopupWindowManagerAndPostMessageStateSync component creates popup windows and handles property syncing to and from their containers.
                Properties that need syncing need to be added to it as input properties.  Property names should be the same as they are in the container state.
                For each popup only configured properties are synched. This is defined in ComponentPropertySyncConfig.
              */}
                <PopupWindowManagerAndPostMessageStateSync
                    windowId={'vaacontainer'}
                    isDziViewerPoppedOut={this.state.isDziViewerPoppedOut}
                    dziViewerWindowRect={this.state.dziViewerWindowRect}
                    dziViewerPopupState={this.state.dziViewerPopupState}
                    selectedDziImage={this.state.selectedDziImage}
                    imageStatuses={this.state.imageStatuses}
                    organisationUsers={this.state.organisationUsers}
                    defects={this.state.defects}
                    userPermissions={this.state.userPermissions}
                    isDefectModeEnabled={this.state.isDefectModeEnabled}
                    updatedImageIds={this.state.updatedImageIds}
                    imageDefectCount={this.state.imageDefectCount}

                    // Property updates from popup window containers come out here
                    onStateChanged={(stateObj: any) => this.setState(stateObj)}
                />

                <div className={styles.primaryNavbar} >
                    <Box style={{ paddingBottom: 3 }} onClick={(e: any) => { this.setState({ anchorProductEl: null }); this.setState({ anchorProductEl: e.target }); }}>
                        <img src={process.env.PUBLIC_URL + "/images/application_select_icon.png"} className={styles.virtualAssessmentIcon} />
                        <span className={globalStyles.heading4 + ' ' + styles.virtualAssessment}>Virtual Assessment</span>
                    </Box>
                    <Menu
                        id="product-menu"
                        className={styles.productMenu}
                        anchorEl={this.state.anchorProductEl}
                        elevation={1}
                        getContentAnchorEl={null}
                        keepMounted
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(this.state.anchorProductEl)}
                        onClose={(e: any) => this.setState({ anchorProductEl: null })}
                    >
                        <MenuItem className={styles.productMenuItem} onClick={(e) => { this.switchProduct("AIMS"); this.setState({ anchorProductEl: null }); }} disabled={!this.state.canAims}>
                            <img style={{ paddingRight: '8px' }} src={process.env.PUBLIC_URL + "/images/Aims3d.png"} />AIMS 3D
                        </MenuItem>
                        <MenuItem className={styles.productMenuItem} onClick={(e) => { this.switchProduct("Admin"); this.setState({ anchorProductEl: null }); }} disabled={!this.state.canAdmin}>
                            <img style={{ paddingRight: '8px' }} src={process.env.PUBLIC_URL + "/images/Dashboard.png"} />Admin Dashboard
                        </MenuItem>
                        <MenuItem className={styles.productMenuItem} onClick={(e) => { this.switchProduct("Cmply"); this.setState({ anchorProductEl: null }); }} disabled={!this.state.canCmply}>
                            <img style={{ paddingRight: '8px' }} src={process.env.PUBLIC_URL + "/images/AimsCmply.png"} />AIMS CMPLY
                        </MenuItem>
                    </Menu>

                    <span>
                        <Campaign
                            organisationId={this.state.organisationId}
                            lastAccessedCampaignId={this.state.lastAccessedCampaign}
                            urlCampaignName={this.state.searchImageUrlParam?.campaign}
                            isUrlImageSearchPerformed={this.state.isUrlImageSearchPerformed}
                            onCampaignIdChange={(e: number, isUrlCampaign : boolean) => { //e: id of campaign, isUrlCampaign: true if campaign is selected from url
                                let deepClone = JSON.parse(JSON.stringify(this.state.mapDataLayers)) as IMapLayer[][];
                                for (let i = 0; i < this.state.mapDataLayers.length; i++) {
                                    for (let j = 0; j < this.state.mapDataLayers[i].length; j++) {
                                        deepClone[i][j].isActive = this.state.mapDataLayers[i][j].isActive;
                                    }
                                }
                                this.setState({ mapDataLayers: deepClone });

                                if(isUrlCampaign){
                                    this.setState({ urlCampaignExists : true });
                                    this.setState({ urlCampaignId :  e});
                                }

                                if(!isUrlCampaign && this.state.urlCampaignId > 0 && this.state.urlCampaignId !== e){
                                    //reset the state once search is performed and user has changed the campaign,=.
                                    this.setState({ searchImageUrlParam :  null});
                                }

                                this.setState({ campaignId: e, lastAccessedCampaign:e });
                                // Reset to defaults after campaign change
                                this.setSelectedDziImage(null);
                                this.setState({
                                    isDziViewerPoppedOut: false,
                                    imageSearch: null,
                                    selectedImageRow: null,
                                    mapDataLayers: deepClone,
                                    selectedImageIds: [],
                                    selectedAssetRow: null
                                });
                                let pinpointMessage = {
                                    eventName: 'campaign', attributes: { action: 'campaign-change', identifier: e.toString(), source: 'viewer', detail: this.state.campaignId }
                                };
                                sendEventToPinpoint(pinpointMessage);
                            }}
                        />

                    </span>

                    <div className={styles.menuIcons}>
                        <IconButton aria-controls="setting-menu" aria-haspopup="true" onClick={(e: any) => this.setState({ anchorElSetting: e.target })} >
                            <SettingsOutlinedIcon />
                        </IconButton>

                        {this.state.loggedInUser != null &&
                            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={(e: any) => this.setState({ anchorEl: e.target })}>
                                <UserIcon marginLeft={0} marginRight={0} height={28} width={28} fontSize={12} emailAddress={this.state.loggedInUser.emailAddress} firstName={this.state.loggedInUser.firstName} surname={this.state.loggedInUser.surname} tooltip={this.state.loggedInUser.firstName + ' ' + this.state.loggedInUser.surname + ' ' + this.state.loggedInUser.emailAddress} />
                            </IconButton>
                        }

                        <Menu
                            id="simple-menu"
                            className={styles.userMenu}
                            anchorEl={this.state.anchorEl}
                            elevation={1}
                            getContentAnchorEl={null}
                            keepMounted
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(this.state.anchorEl)}
                            onClose={(e: any) => this.setState({ anchorEl: null })}
                        >
                            <MenuItem className={styles.userMenuItem} onClick={(e) => { this.signOut(); this.setState({ anchorEl: null }); }}> <ExitToAppOutlinedIcon className={styles.signOutIcon} fontSize="small" />Logout</MenuItem>
                        </Menu>
                        <Menu
                            id="setting-menu"
                            className={styles.userMenu}
                            anchorEl={this.state.anchorElSetting}
                            elevation={1}
                            getContentAnchorEl={null}
                            keepMounted
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(this.state.anchorElSetting)}
                            onClose={(e: any) => this.setState({ anchorElSetting: null })}
                        >
                            <MenuItem className={styles.userMenuItem}>VAA version: {process.env.REACT_APP_VERSION as string}</MenuItem>

                            <MenuItem className={styles.userMenuItem}>
                                <FormControlLabel
                                    label="Image selection navigation"
                                    control={<Checkbox
                                        checked={this.getUserPreferenceAsBool(UserPreference.OPENING_DZI_FROM_RESULTS_LIST_SELECTS_IMAGE)}
                                        onChange={() => this.toggleUserPreference(UserPreference.OPENING_DZI_FROM_RESULTS_LIST_SELECTS_IMAGE)}
                                        checkedIcon={<CheckBoxIcon style={{ color: '#F7B500' }} />}
                                        size={'small'} />}
                                />
                            </MenuItem>

                        </Menu>
                    </div>

                </div>

                <div className={styles.mainContainer} ref={this.mainContainerNodeRef}>
                    {this.state.anchorImageStatusMenuEl != null &&
                        <ImageStatusMenu
                            selectedImageIds={this.state.selectedImageIds}
                            changeCountConfirmation={this.state.imageStatusChangeCountConfirmation}
                            anchorImageStatusMenuEl={this.state.anchorImageStatusMenuEl}
                            imageStatuses={this.state.userImageStatuses}
                            onMenuClosed={(e: any) => this.setState({ anchorImageStatusMenuEl: null })}
                            onSetImageStatus={(status: string) => {
                                this.setState({ anchorImageStatusMenuEl: null });
                                this.setImageStatus(status);
                            }}
                            onCancel={() => this.setState({
                                isSelectModeEnabled: false,
                                selectedImageIds: [],
                                linkingResult: { result: 'cancelled' },
                                isLinkModeEnabled: false,
                                selectedSegment: null,
                                isLinkConfirmationRequired: false
                            })}
                        />
                    }
                    {this.state.anchorImageAssignMenuEl != null &&
                        <ImageAssignMenu
                            organisationActiveUsers={this.state.organisationUsers.filter(user=>user.isActive)}
                            selectedImageIds={this.state.selectedImageIds}
                            anchorImageAssignMenuEl={this.state.anchorImageAssignMenuEl}
                            onMenuClosed={(e: any) =>
                                this.setState({ anchorImageAssignMenuEl: null
                            })}
                            onAssignUser={(user: IUser) => {
                                this.setState({ anchorImageAssignMenuEl: null });
                                this.assignImages(user);
                            }}
                            onCancel={() =>
                              this.setState({
                                isSelectModeEnabled: false,
                                selectedImageIds: [],
                                linkingResult: { result: 'cancelled' },
                                isLinkModeEnabled: false,
                                selectedSegment: null,
                                isLinkConfirmationRequired: false
                            })}
                        />
                    }
                    <div className={styles.mapBaseLayerSelectorContainer}>
                        <MapBaseLayerSelector
                            baseMapLayers={this.state.baseMapLayers}
                            currentBaseLayer={this.state.currentBaseLayer}
                            onBaseMapLayerSelected={this.onBaseMapLayerSelectedHandler}
                        />
                    </div>

                    <div className={styles.mapPickerContainer}>
                        <MapPicker
                            config={this.state.mapPickerConfig}
                            onClose={() => this.setState({ mapPickerConfig: null })}
                            onImageSelected={(image: IImage) => {
                                this.setState({ mapPickerConfig: null });
                                this.onImageSelectedFromMap(image);
                                let pinpointMessage = {
                                    eventName: 'image', attributes: { action: 'image-click', identifier: image.id.toString(), source: 'map-picker', detail: this.state.campaignId }
                                };
                                sendEventToPinpoint(pinpointMessage);
                            }}
                            onSegmentSelected={(segment: ISegment, screenX: number, screenY: number) => {
                                this.setState({ segmentDetailsConfig: null });
                                this.setState({ mapPickerConfig: null });
                                this.onSegmentSelectedFromMap(segment, screenX, screenY);
                            }}
                            onShapefileDataSelected={(shapefileData: IShapefileData, screenX: number, screenY: number) => {
                                this.setState({ shapefileDataDetailsConfig: null });
                                this.setState({ mapPickerConfig: null });
                                this.onShapeFileDataSelectedFromMap(shapefileData, screenX, screenY);
                            }}
                        />
                    </div>

                    <div className={styles.toolBarContainer}>
                        <ToolBar
                            isSelectModeEnabled={this.state.isSelectModeEnabled}
                            selectedImagesCount={this.state.selectedImageIds.length}
                            onIsSelectModeEnabledChanged={(selectModeEnabledFromMap: boolean) => {
                                this.setState({ isLinkConfirmationRequired: false, showGenericConfirmationSnackbar:false });
                                this.isSelectModeEnabledChanged(selectModeEnabledFromMap);

                            }}
                            isLinkModeEnabled={this.state.isLinkModeEnabled}
                            enableLinkModeButton={this.isSegmentLayerVisible()}
                            onIsLinkModeEnabledChanged={(linkModeEnabled: boolean) => {
                                this.setState({ isLinkModeEnabled: linkModeEnabled, isLinkConfirmationRequired: false, showGenericConfirmationSnackbar:false })
                                if (!linkModeEnabled) {
                                    this.setState({
                                        linkingResult: { result: 'cancelled' },
                                        isLinkModeEnabled: false
                                    });
                                }
                            }
                            }
                            onUnlink={() => {
                                if (this.state.selectedImageIds.length >= USER_UNLINK_WARNING_COUNT) {
                                    this.setState({ isLinkConfirmationRequired: true, isLinkModeEnabled: false, showGenericConfirmationSnackbar:false });
                                }
                                else {
                                    this.unlinkSelectedImagesFromPoles();
                                }
                            }}
                            showImageStatusButton={ this.state.userPermissions.includes('CanEditImageStatus')}
                            showLinkUnlinkButton={ this.state.userPermissions.includes('CanLinkUnlink')}
                            onShowImageStatusMenu={(e: any) => {
                                this.setState({ anchorImageStatusMenuEl: e.currentTarget, isLinkModeEnabled: false, isLinkConfirmationRequired: false });
                            }}
                            showImageAssigneeButtons={ this.state.userPermissions.includes('CanAssignUnassignSessionImage')}
                            onShowImageAssignMenu={(e: any) => {
                                this.setState({ anchorImageAssignMenuEl: e.currentTarget, isLinkModeEnabled: false, isLinkConfirmationRequired: false, showGenericConfirmationSnackbar:false });
                            }}
                            onUnassign={(e:any) => {

                                if (this.state.selectedImageIds.length >= USER_UNASSIGN_WARNING_COUNT) {
                                    let config : IGeneralConfirmationSnackbarConfig = {
                                        action: 'unassign',
                                        message: "Confirm unassign of " + this.state.selectedImageIds.length + " image" + (this.state.selectedImageIds.length > 1 ? "s" : ""),
                                        top: 388 + e.currentTarget.offsetTop,
                                        right:76
                                    };

                                    this.setState({ generalConfirmationSnackbarConfig: config, showGenericConfirmationSnackbar:true, isLinkConfirmationRequired: false, isLinkModeEnabled: false });
                                }
                                else {
                                    this.unassignSelectedImages();
                                }
                            }}
                        />
                    </div>

                    <div className={styles.toolBarContainer}>
                        <MapSelectionSnackBar
                            isSelectModeEnabled={this.state.isSelectModeEnabled}
                            imagesCount={this.state.selectedImageIds.length}
                            onCancel={() => this.setState({
                                isSelectModeEnabled: false,
                                selectedImageIds: [],
                                linkingResult: { result: 'cancelled' },
                                isLinkModeEnabled: false,
                                selectedSegment: null,
                                isLinkConfirmationRequired: false
                            })}
                            onClear={() => this.setState({
                                selectedImageIds: [],
                                linkingResult: { result: 'cancelled' },
                                isLinkModeEnabled: false,
                                selectedSegment: null,
                                isLinkConfirmationRequired: false
                            })}
                        />
                    </div>
                    {(this.state.isLinkModeEnabled || this.state.isLinkConfirmationRequired) &&
                        <div className={styles.toolBarContainer}>
                            <LinkConfirmationSnackBar
                                isLinkConfirmationRequired={this.state.isLinkConfirmationRequired}
                                isLinkModeEnabled={this.state.isLinkModeEnabled}
                                imagesCount={this.state.selectedImageIds.length}
                                onCancel={() => this.setState({ isLinkConfirmationRequired: false })}
                                onConfirm={() => {
                                    this.setState({ isLinkConfirmationRequired: false });
                                    if (this.state.isLinkModeEnabled) this.linkSelectedImagesToPole();
                                    else this.unlinkSelectedImagesFromPoles();
                                }}
                            />
                        </div>
                    }
                    {this.state.showGenericConfirmationSnackbar && (this.state.generalConfirmationSnackbarConfig != null) &&
                        <div className={styles.toolBarContainer}>
                            <GeneralConfirmationSnackBar
                                config={this.state.generalConfirmationSnackbarConfig }
                                onCancel={() => this.setState({ showGenericConfirmationSnackbar: false, generalConfirmationSnackbarConfig: null })}
                                onConfirm={() => {

                                    if ( this.state.generalConfirmationSnackbarConfig != null ){
                                        if ( this.state.generalConfirmationSnackbarConfig.action == 'unassign' )
                                            this.unassignSelectedImages();
                                    }

                                    this.setState({ showGenericConfirmationSnackbar: false, generalConfirmationSnackbarConfig: null });
                                }}
                            />
                        </div>
                    }
                    <div className={styles.generalSnackbarContainer}>
                        <GeneralSnackbar
                            config={this.state.generalSnackbarConfig}
                        />
                    </div>

                    <div className={styles.generalSnackbarContainer}>
                        {this.state.segmentDetailsConfig &&
                            <SegmentDetails
                                selectedDziImageId={this.state.selectedDziImage ? this.state.selectedDziImage.id : 0}
                                config={this.state.segmentDetailsConfig}
                                onClose={() => this.setState({ segmentDetailsConfig: null })}
                                onThumbnailClick={(img: IImage) => {
                                    this.setSelectedDziImage(img);
                                    if (!this.state.isDziViewerPoppedOut)
                                        this.setState({ dziViewerEnabled: true });
                                    let pinpointMessage = {
                                        eventName: 'image', attributes: { action: 'image-click', identifier: img.id.toString(), source: 'pole-popup', detail: this.state.campaignId }
                                    };
                                    sendEventToPinpoint(pinpointMessage);
                                }}
                                onSegmentUrlClick={(url: string) => {
                                     this.setState({ urlViewerUrl: url });
                                     this.setState({ urlViewerEnabled: true });
                                }}
                            />}
                    </div>

                    <div className={styles.generalSnackbarContainer}>
                        {this.state.shapefileDataDetailsConfig &&
                            <ShapefileDataDetails
                                config={this.state.shapefileDataDetailsConfig}
                                onClose={() => this.setState({ shapefileDataDetailsConfig: null })}
                            />}
                    </div>

                    <div className={styles.imageCarouselContainer}>
                        <ImageCarousel
                            selectedImageIds={this.state.selectedImageIds}
                            onImageRemoved={(imageId: number) => this.addOrRemoveFromSelectedImages(imageId, true)}
                            selectedDziImageId={this.state.selectedDziImage ? this.state.selectedDziImage.id : 0}
                            onClearSelectedImages={() => this.setState({ selectedImageIds: [] })}
                            imageDefectCount={this.state.imageDefectCount}
                            onDziImageSelected={(image: IImage) => {
                                this.setSelectedDziImage(image);
                                if (!this.state.isDziViewerPoppedOut)
                                    this.setState({ dziViewerEnabled: true });
                                let pinpointMessage = {
                                    eventName: 'image', attributes: { action: 'image-click', identifier: image.id.toString(), source: 'image-carousel', detail: this.state.campaignId }
                                };
                                sendEventToPinpoint(pinpointMessage);
                            }}


                        />
                    </div>
                    <Draggable bounds="parent" handle="strong" nodeRef={this.dziViewerNodeRef} position={dziViewerDraggablePosition}

                        onDrag={(event: any, data: any) => {
                            if (data.lastX != this.state.dziViewerX || data.lastY != this.state.dziViewerY) {
                                this.setState({ dziViewerX: data.lastX, dziViewerY: data.lastY });
                            }
                        }}

                    >
                        <Resizable

                            onResize={(event: any, data: any) => {
                                if (data.size.width != this.state.dziViewerWidth || data.size.height != this.state.dziViewerHeight) {

                                    let posX = this.state.dziViewerX;
                                    let posY = this.state.dziViewerY;

                                    if (data.handle.indexOf("n") !== -1) {
                                        let heightDiff = data.size.height - this.state.dziViewerHeight;
                                        posY = this.state.dziViewerY - heightDiff;
                                    }

                                    if (data.handle.indexOf("e") !== -1) {
                                        let widthDiff = data.size.width - this.state.dziViewerWidth;
                                        posX = this.state.dziViewerX + widthDiff;
                                    }

                                    this.setState({ dziViewerWidth: data.size.width, dziViewerHeight: data.size.height, dziViewerY: posY, dziViewerX: posX });
                                }
                            }}
                            className={styles.dziViewerContainer}
                            width={dziViewerContainerStyleBase.width}
                            height={dziViewerContainerStyleBase.height}
                            minConstraints={[this.dziViewerMinWidth, this.dziViewerMinHeight]}
                            resizeHandles={['sw', 'se', 'nw', 'ne', 'w', 'e', 'n', 's']}
                        >
                            <div ref={this.dziViewerNodeRef} style={dziViewerContainerStyle} >
                                <strong className={styles.moveCursor}>
                                    <div className={styles.draggableWindowHeader} style={{ display: selectedDziImageDisplayStyle }}>
                                        <div className={styles.draggableWindowHeaderContent} style={{ fontWeight: 400, position: "absolute", paddingLeft: 15, color: 'white' }}>{this.state.selectedDziImage?.fileName}</div>
                                        <div className={styles.draggableWindowHeaderContent}>
                                            <img src={process.env.PUBLIC_URL + '/images/expand.png'} title='Expand' className={styles.linkPointer} style={{ marginRight: 5 }} onClick={() => this.setState({ dziViewerExpanded: !this.state.dziViewerExpanded })} />
                                            <img src={process.env.PUBLIC_URL + '/images/popout.png'} title='Open in new window' className={styles.linkPointer} style={{ marginRight: 5 }} onClick={() => this.setState({ getDziViewerStateFlag: this.state.getDziViewerStateFlag + 1 })} />
                                            <img src={process.env.PUBLIC_URL + '/images/close.png'} title='Close' className={styles.linkPointer}
                                                onClick={() => {
                                                    this.setState({ dziViewerEnabled: false });
                                                    this.setState({ dziViewerExpanded: false });
                                                    this.setState({ selectedDziImage: null });
                                                }} />
                                        </div>
                                    </div>
                                </strong>
                                <div style={{ height: dziViewerHeight }}>
                                    {showDziViewer &&
                                        <DZIViewer
                                            organisationUsers={this.state.organisationUsers}
                                            image={this.state.selectedDziImage}
                                            imageStatuses={this.state.imageStatuses}
                                            viewerState={null}
                                            userPermissions={this.state.userPermissions}
                                            defects={this.state.defects}
                                            isExpanded={this.state.dziViewerExpanded}
                                            canViewDefects = { this.state.userPermissions.includes('CanReadDefect')}
                                            imageDefectCount={this.state.imageDefectCount}
                                            // This is updated via the 'Open in new window' icon
                                            getViewerStateFlag={this.state.getDziViewerStateFlag}
                                            onGetViewerState={(dZIViewerState: IDZIViewerState) => {
                                                this.setState({ dziViewerPopupState: dZIViewerState });
                                                let dziViewerRectForPopout: IRect | null = null;
                                                if (this.dziViewerNodeRef.current != null && this.dziViewerNodeRef.current.children[1] != null) {
                                                    let rect = this.dziViewerNodeRef.current.children[1].getBoundingClientRect();
                                                    if (rect != null) {
                                                        dziViewerRectForPopout = { left: rect.left + 10, top: rect.top + 60, width: rect.width, height: rect.height };
                                                    }
                                                }

                                                //Signal that a popup window be created
                                                this.setState({
                                                    dziViewerEnabled: false,
                                                    isDziViewerPoppedOut: true,
                                                    dziViewerExpanded: false,
                                                    dziViewerWindowRect: dziViewerRectForPopout
                                                });
                                            }}
                                            onDefectsChanged={(defects: IDefect[]) => this.setState({ defects: defects })}
                                            isDefectModeEnabled={this.state.isDefectModeEnabled}
                                            onDefectModeChanged={(isEnabled: boolean) => this.setState({ isDefectModeEnabled: isEnabled })}
                                            onSelectedDZIImageChanged={(img: IImage | null) => this.setState({ selectedDziImage: img })}
                                            actionPerformed={this.state.actionPerformed}
                                            updatedImageIds={['statusChange', 'linkedImages', 'unlinkedImages'].indexOf(this.state.actionPerformed?.type??'')>=0 ? this.state.updatedImageIds : null}
                                            onDefectChanged={(imageId: number, defectCount: number) => {
                                                if(this.state.userPermissions.includes('CanReadDefect')){
                                                    //this function will update count of the defects for each image. It is used to display red bar on the image thumbnail.
                                                    let imageDefects: IImageDefectCount[] = [...this.state.imageDefectCount];
                                                    
                                                    let existingDefect:IImageDefectCount = {...imageDefects.filter(x => x.imageId === imageId)[0]};
                                                    if(existingDefect.imageId){
                                                        existingDefect.defectCount = defectCount;
                                                        imageDefects[imageDefects.findIndex(x => x.imageId === imageId)] = existingDefect;
                                                    }
                                                    else{
                                                        imageDefects.push({imageId : imageId, defectCount : defectCount})
                                                    }
                                                    
                                                    this.setState({imageDefectCount: imageDefects});
                                                }
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                        </Resizable>
                    </Draggable>


                    <Draggable bounds="parent" handle="strong" nodeRef={this.urlViewerNodeRef} position={urlViewerDraggablePosition}

                        onDrag={(event: any, data: any) => {
                            if (data.lastX != this.state.urlViewerX || data.lastY != this.state.urlViewerY) {
                                this.setState({ urlViewerX: data.lastX, urlViewerY: data.lastY });
                            }
                        }}

                    >
                        <Resizable
                            onResize={(event: any, data: any) => {
                                if (data.size.width != this.state.urlViewerWidth || data.size.height != this.state.urlViewerHeight) {

                                    let posX = this.state.urlViewerX;
                                    let posY = this.state.urlViewerY;

                                    if (data.handle.indexOf("n") !== -1) {
                                        let heightDiff = data.size.height - this.state.urlViewerHeight;
                                        posY = this.state.urlViewerY - heightDiff;
                                    }

                                    if (data.handle.indexOf("e") !== -1) {
                                        let widthDiff = data.size.width - this.state.urlViewerWidth;
                                        posX = this.state.urlViewerX + widthDiff;
                                    }

                                    this.setState({ urlViewerWidth: data.size.width, urlViewerHeight: data.size.height, urlViewerY: posY, urlViewerX: posX });
                                }
                            }}
                            className={styles.urlViewerContainer}
                            width={urlViewerContainerStyleBase.width}
                            height={urlViewerContainerStyleBase.height}
                            minConstraints={[this.urlViewerMinWidth, this.urlViewerMinHeight]}
                            resizeHandles={['sw', 'se', 'nw', 'ne', 'w', 'e', 'n', 's']}
                        >
                            <div ref={this.urlViewerNodeRef} style={urlViewerContainerStyle} >
                                <strong className={styles.moveCursor}>
                                    <div className={styles.draggableWindowHeader} style={{ display: urlViewerDisplayStyle }}>
                                        <div className={styles.draggableWindowHeaderContent} style={{ fontWeight: 400, position: "absolute", paddingLeft: 15, color: 'white' }}>Url Viewer</div>
                                        <div className={styles.draggableWindowHeaderContent}>
                                            <img src={process.env.PUBLIC_URL + '/images/expand.png'} title='Expand' className={styles.linkPointer} style={{ marginRight: 5 }} onClick={() => this.setState({ urlViewerExpanded: !this.state.urlViewerExpanded })} />
                                            <img src={process.env.PUBLIC_URL + '/images/close.png'} title='Close' className={styles.linkPointer}
                                                onClick={() => {
                                                    this.setState({ urlViewerEnabled: false });
                                                    this.setState({ urlViewerExpanded: false });
                                                    this.setState({ urlViewerUrl: null });
                                                }} />
                                        </div>
                                    </div>
                                </strong>
                                <div style={{ height: urlViewerHeight }}>
                                    <UrlViewer url={this.state.urlViewerUrl} />
                                </div>
                            </div>
                        </Resizable>
                    </Draggable>

                    <Map
                        mapOptions={this.state.mapOptions}
                        baseMapLayers={this.state.baseMapLayers}
                        currentBaseLayer={this.state.currentBaseLayer}
                        imageSearch={this.state.imageSearch}
                        imageSearchZoomExtent={this.state.imageSearchZoomExtent}
                        assetSearch={this.state.assetSearchResults}
                        selectedImageRow={this.state.selectedImageRow}
                        selectedAssetRow={this.state.selectedAssetRow}
                        isSelectModeEnabled={this.state.isSelectModeEnabled}
                        selectedDziImage={this.state.selectedDziImage}
                        selectedImageIds={this.state.selectedImageIds}
                        isLinkModeEnabled={this.state.isLinkModeEnabled}
                        linkingResult={this.state.linkingResult}
                        refreshMapLayersFlag={this.state.refreshMapLayersFlag}
                        urlLatLong={this.state.urlLatLong}

                        //this is shared with LayerList
                        mapDataLayers={this.state.mapDataLayers}
                        /*Output Events */
                        /*onMapSingleClick is an event output by the Map component.  We handle it here by getting the event it emits (type IMapClick) and using setState to put it into the VAAContainer state */
                        onMapMoveStart={(e: any) => { this.handleSegmentAttributesPopupState(e) }}

                        onMapSingleClick={(e: IMapClick) => {
                            this.setState({ lastMapClick: e });
                            this.handleSegmentAttributesPopupState(e);
                        }}

                        onDragBoxEnd={(imageIds: number[]) => {
                            if (imageIds.length >= 1) {
                                this.setState({ isSelectModeEnabled: true, isLinkModeEnabled: this.state.userPermissions.includes('CanLinkUnlink') });
                                this.addOrRemoveListFromSelectedImages(imageIds, false);
                            }
                        }
                        }
                        onMapMoveEnd={(e: ICoordinate, zoom: number) => {
                            if ( this.intialised)
                            {
                                localStorage.setItem('lastMapPosition', JSON.stringify(e));
                            }
                            this.setState({ lastMapPosition: e, lastOpenLayersPositionData: { centrePoint: e, zoom: zoom } } ) }
                         }
                        onMapSingleClickObjects={(images: IImage[], segments: ISegment[], shapefileDataList: IShapefileData[], screenX: number, screenY: number) => {
                            if (this.state.isSelectModeEnabled && images.length >= 1) {
                                this.addOrRemoveFromSelectedImages(images[0].id, true);
                            }

                            else {
                                if (images.length === 1 && segments.length === 0 && shapefileDataList.length === 0) {
                                    this.onImageSelectedFromMap(images[0]); //open dzi
                                    let pinpointMessage = {
                                        eventName: 'image', attributes: { action: 'image-click', identifier: images[0].id.toString(), source: 'map', detail: this.state.campaignId }
                                    };
                                    sendEventToPinpoint(pinpointMessage);

                                }
                                if (images.length === 0 && segments.length === 1 && shapefileDataList.length === 0) {
                                    this.onSegmentSelectedFromMap(segments[0], screenX, screenY); // open pole details
                                }
                                if (images.length === 0 && segments.length === 0 && shapefileDataList.length === 1) {
                                    this.onShapeFileDataSelectedFromMap(shapefileDataList[0], screenX, screenY); // open shapefile details
                                }
                                if ((images.length + segments.length + shapefileDataList.length) > 1) {
                                    // Use the map picker
                                    this.setState({ mapPickerConfig: { images: images, segments: segments, shapefileData: shapefileDataList, screenX: screenX, screenY: screenY } });
                                }
                            }
                        }}
                        onLinkPole={(segmentClicked: ISegment) => {
                            if (this.state.selectedImageIds.length >= USER_LINK_WARNING_COUNT) {
                                this.setState({ isLinkConfirmationRequired: true, selectedSegment: segmentClicked });
                            }
                            else {
                                this.setState({ selectedSegment: segmentClicked });
                                this.linkSelectedImagesToPole();
                            }
                        }}
                    />
                    <Box display="flex" flexDirection="column" className={styles.tabsContainer} style={{width: this.state.isSelectModeEnabled ? 480 : 450}}>

                        <AppBar position="static" color='default'>
                            <Tabs
                                style={{ backgroundColor: '#292A2B', color: 'white' }}
                                value={this.state.tabValue}
                                onChange={(evt: any, val: number) => this.setState({ tabValue: val })}
                                indicatorColor="primary"
                            >
                                <Tab style={tabStyle} label="Images" {...a11yProps(TabIndex.ImageSearch)} />
                                <Tab style={tabStyle} label="Assets" {...a11yProps(TabIndex.Assets)} />
                                <Tab style={tabStyle} label="Layers" {...a11yProps(TabIndex.Layers)} />
                            </Tabs>
                        </AppBar>

                        <TabPanel value={this.state.tabValue} index={TabIndex.ImageSearch} >

                            {imagesTab}

                        </TabPanel>

                        <TabPanel value={this.state.tabValue} index={TabIndex.Assets} >

                            {assetsTab}

                        </TabPanel>

                        <TabPanel value={this.state.tabValue} index={TabIndex.Layers} >

                            {layersTab}

                        </TabPanel>
                    </Box>
                </div>

            </div>
        )
    }
}
