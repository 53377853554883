import React, { useState, useEffect, createRef, useRef } from "react";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import styles from "../AssetLinkFilter/AssetLinkFilter.module.css";
import checkCircle from "../../../../images/check_circle_24px.png";
import cancelCircle from "../../../../images/cancel_24px.png";
import inactiveLinkCircle from "../../../../images/check_circle_inactivelink_24px.png";
import { Avatar, Box } from "@material-ui/core";
import { LinkStatus } from "../../../../models/LinkStatus";

const makeControlStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14,
  },
  labelSelected: {
    fontSize: 14,
    color: "#F7B500",
  },
}));

interface IAssetLinkFilterProps {
  onFilterChanged: Function;
  clearFilters: number;
}

interface IAssetLink {
  name: string;
  isSelected: boolean;
}

export function AssetLinkFilter(props: IAssetLinkFilterProps) {
  const controlClasses = makeControlStyles();

  const allAssetLinks: IAssetLink[] = [
    { name: LinkStatus.Linked, isSelected: false },
    { name: LinkStatus.NoLink, isSelected: false },
    { name: LinkStatus.InactiveLink, isSelected: false }
  ];

  const [assetLinks, setAssetLinks] = useState(allAssetLinks);
  const [lastClearFilters, setLastClearFilters] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  let statusGreen = "#AED581";
  let statusYellow = "#FFB74D";
  let statusWhite = "#fafcff";

  // Handle when the filters have been cleard
  // A number is passed as a flag so we can tell when it has changed
  useEffect(() => {
      assetLinks.forEach((s) => (s.isSelected = false));
      setAssetLinks(assetLinks.slice());
      setSelectAll(false);
      setLastClearFilters(props.clearFilters);
  }, [props.clearFilters]);

  function onSelectAll(selectAll: boolean) {
    if (!selectAll) {
      assetLinks.forEach((c) => (c.isSelected = true));
    } else {
      assetLinks.forEach((c) => (c.isSelected = false));
    }
    setAssetLinks(assetLinks.slice());
    let selectedAssetLinks = assetLinks
      .filter((c) => c.isSelected)
      .map((c) => c.name);
    props.onFilterChanged(selectedAssetLinks);
    setSelectAll(!selectAll);
  }

  function onCheckboxChanged(assetLink: IAssetLink) {
    assetLink.isSelected = !assetLink.isSelected;
    setAssetLinks(assetLinks.slice());
    let selectedAssetLink = assetLinks
      .filter((s) => s.isSelected)
      .map((s) => s.name);
    props.onFilterChanged(selectedAssetLink);
    syncSelectAll();
  }

  function syncSelectAll() {
    let selectedAssetLink = assetLinks.filter((c) => c.isSelected);
    if (!selectAll && selectedAssetLink.length == assetLinks.length) {
      setSelectAll(true);
    } else if (selectAll && selectedAssetLink.length < assetLinks.length) {
      setSelectAll(false);
    }
  }

  let assetLinkCheckboxes: JSX.Element[] = [];
  assetLinks.forEach((assetLink) => {

    let color;
    let icon;

    if (assetLink.name == LinkStatus.Linked)
    {
      color= statusGreen;
      icon = checkCircle;
    }
   else if (assetLink.name == LinkStatus.NoLink)
    {
      color = statusYellow;
      icon = cancelCircle;
    }
   else if (assetLink.name == LinkStatus.InactiveLink)
    {
      color = statusWhite;
      icon =  inactiveLinkCircle;
    }
    let test: JSX.Element = (
      <Box display="flex" flexDirection="row"
        style={{
                 color: color,
        }}
      >
        <Avatar style={{width:'20px', height:'20px', paddingRight: '5px'}} src={icon} />
        {assetLink.name}
      </Box>
    );

    assetLinkCheckboxes.push(
      <FormControlLabel
        key={assetLink.name}
        classes={{
          label: assetLink.isSelected
            ? controlClasses.labelSelected
            : controlClasses.label,
        }}
        control={
          <Checkbox
            checked={assetLink.isSelected}
            checkedIcon={<CheckBoxIcon style={{ color: "#F7B500" }} />}
            onChange={() => onCheckboxChanged(assetLink)}
            name={assetLink.name}
            size={"small"}
          />
        }
        label={test}
      />
    );
  });

  let selectAllCheckbox = (
    <FormControlLabel
      key={"Select All"}
      classes={{
        label: selectAll ? controlClasses.labelSelected : controlClasses.label,
      }}
      control={
        <Checkbox
          style={{ color: "#F7B500", opacity: "0.5 !important" }}
          checked={selectAll}
          checkedIcon={
            <CheckBoxIcon
              style={{ color: "#F7B500", opacity: "0.5 !important" }}
            />
          }
          onChange={() => onSelectAll(selectAll)}
          name={"Select All"}
          size={"small"}
        />
      }
      label={"Select All"}
    />
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FormGroup>
          {selectAllCheckbox}
          {assetLinkCheckboxes}
        </FormGroup>
      </div>
    </div>
  );
}
