import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useKeyPress } from 'react-flow-renderer';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import MenuItem from '@material-ui/core/MenuItem';

import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import { postWithAuthorisationHeader, fetchWithAuthorisationHeader } from "../../../services/AuthenticationService";
import { ImageSearchResult } from '../ImageSearchResult/ImageSearchResult';

import styles from './ImageSearchResults.module.css';
import CircularProgress from '@material-ui/core/CircularProgress';


// Models
import { IImage } from '../../../models/IImage';
import { IImageSearchCreateResponse } from '../../../models/IImageSearchCreateResponse';
import { IImageSearchFetchRequest } from '../../../models/IImageSearchFetchRequest';
import { IImageSearchFetchResponse } from '../../../models/IImageSearchFetchResponse';
import { Link } from '@material-ui/core';
import { IImageStatus } from '../../../models/IImageStatus';
import {ILinkingResult} from '../../../models/ILinkingResult';
import { IImageDefectCount } from '../../../models/IImageDefectCount';
import { IDefect } from '../../../models/IDefect';

const makeSelectStyles = makeStyles((theme) => ({
    root: {
        color: "#EAEBEB"
    },
    icon: {
        color: "#F9FCFF",
        fontSize: 22
    },
}));


const makeMenuItemStyles = makeStyles((theme) => ({
    root: {
        fontSize: 14,
        "&:hover": {
            color: '#93BAF5',
            backgroundColor: "rgba(147, 186, 245, 0.08)"
        }
    },
    selected: {
        color: '#93BAF5',
        backgroundColor: "rgba(147, 186, 245, 0.08)"
    },
}));


interface IImageSearchResultsProps {
    isVisible: boolean;
    imageSearch: IImageSearchCreateResponse | null;
    dziSelectionSelectsRow: boolean;
    onDziImageSelected: Function;
    onImageRowSelected: Function;
    selectedDziImageId: number;
    isImageSelectionEnabled: boolean;
    toggleIsImageSelectionEnabled: Function;
    onImageCheckboxChecked: Function;
    selectedImageIds: number[];
    updatedImageIds: number[] | null;
    imageStatuses: IImageStatus[];
    onImagesSelected: Function;
    linkingResult: ILinkingResult | null;
    onSearchResultsNeedRefreshing: Function;
    imageSearchFiltersExpanded: boolean;
    imageDefectCount : IImageDefectCount[];
    canViewDefects: boolean;
}

interface IFetchCallbackProps {
    pageIndex: number;
    sortOrder: string;
}

interface IFetchUpdatedImagesCallbackProps {
    imageIds: number[];
}

interface IRowProps {
    index: number;
    style: string;
}


export function ImageSearchResults(props: IImageSearchResultsProps) {
    const selectClasses = makeSelectStyles();
    const menuItemClasses = makeMenuItemStyles();

    const defaultSortOrder = 'ascending';
    const pageSize: number = 40;
    const defaultPageIndex: number = 1;
    const defaultSearchArray: IImage[] = [];
    const defaultRenderedSearchResults: JSX.Element[] = [];
    const defaultImageIds: number[] = [];
    const sortTypes = ['date & time', 'pole Id'];
    const prevSearchIdRef = useRef('');
    const listRef = useRef(null);
    const fixedListRef = React.createRef<List>();
    const scrollPos = useRef(0);
    const prevSelectedDziImageIdRef = useRef(0);
    const prevSelectionModeRef = useRef(false);
    const dziSelectionSelectsRowRef = useRef(props.dziSelectionSelectsRow);

    function getSearchId(props: IImageSearchResultsProps): string {
        return props != null && props.imageSearch != null && props.imageSearch.searchId != null ? props.imageSearch.searchId : '';
    }

    // State
    const [pageIndex, setPageIndex] = useState(defaultPageIndex);
    const [imageSearchResults, setImageSearchResults] = useState(defaultSearchArray);
    const [sortType, setSortType] = useState(sortTypes[0]);
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);
    const [loading, setLoading] = useState(false);
    const [renderedSearchResults, setRenderedSearchResults] = useState(defaultRenderedSearchResults);
    const [initialised, setInitialised] = useState(false);
    const [selectedImageRowId, setSelectedImageRowId] = useState(0);
    const [triggerRenderFlag, setTriggerRenderFlag] = useState(0);
    const [listHeightSize, setListHeightSize] = useState(500);
    const [imageSearchImageIds,setImageSearchImageIds] = useState(defaultImageIds);
    const [redrawFlag,setRedrawFlag] = useState(1);

    const searchId = getSearchId(props);
    const prevSearchId = prevSearchIdRef.current;
    const prevSelectedDziImageId = prevSelectedDziImageIdRef.current;
    
    const downArrowPressed = useKeyPress(['ArrowDown']);
    const upArrowPressed = useKeyPress(['ArrowUp']);

    useEffect(() => {
        if (props.isVisible && props.selectedDziImageId) {
            let nextImageIndex = null;
            setRenderedSearchResults(defaultRenderedSearchResults);
            let prevImageIndex = imageSearchResults.findIndex(i => i.id == props.selectedDziImageId);

            if (downArrowPressed)  nextImageIndex = prevImageIndex + 1;
            if (upArrowPressed)  nextImageIndex = prevImageIndex - 1;
           
            if (nextImageIndex != null && nextImageIndex >= 0 && nextImageIndex < imageSearchResults.length) {
                let nextImage = imageSearchResults[nextImageIndex];
                props.onDziImageSelected(nextImage);
                
                if (dziSelectionSelectsRowRef.current) {
                    selectImageRow(nextImage);
                }

                if (fixedListRef != null && fixedListRef.current != null) {
                    fixedListRef.current.scrollToItem(nextImageIndex);
                }
            }
        }
    }, [downArrowPressed,upArrowPressed]);

    useEffect(() => {
        prevSelectedDziImageIdRef.current = props.selectedDziImageId;
        for(let i=0;i<imageSearchResults.length;++i){
            if ( imageSearchResults[i].id === props.selectedDziImageId)
            {
                delete renderedSearchResults[i];
            }
            if (imageSearchResults[i].id === prevSelectedDziImageId) {
                delete renderedSearchResults[i];
            }
        }
        setRenderedSearchResults([...renderedSearchResults]);

    },[props.selectedDziImageId]);
        
    useEffect(() => {
        prevSelectionModeRef.current = props.isImageSelectionEnabled;   
    },[props.isImageSelectionEnabled]);

    useEffect(() => {
        dziSelectionSelectsRowRef.current = props.dziSelectionSelectsRow;
    }, [props.dziSelectionSelectsRow]);

    useEffect(() => {
        for(let i=0;i<imageSearchResults.length;++i)
        {
            delete renderedSearchResults[i];
        }
        setRenderedSearchResults(defaultRenderedSearchResults);
    },[props.selectedImageIds]);

    useEffect(() => {
        if (props.updatedImageIds != null) {

            let imagesToFetch: number[] = [];

            props.updatedImageIds.forEach(id => {
                if (imageSearchResults.some(x => x.id === id) ){
                    imagesToFetch.push(id);
                }
            } );
            
            if (imagesToFetch.length > 0) 
                fetchUpdatedSearchResults( {imageIds:imagesToFetch} );

        }
    },[props.updatedImageIds]);


  useEffect(() => {

    // trap when an image has been linked or unlinked and we are sorting by pole id
    if (props.linkingResult != null && ( props.linkingResult.result === 'linked' || props.linkingResult.result === 'unlinked' ) && sortType === 'pole Id' ) {
        props.onSearchResultsNeedRefreshing();
    }
  }, [props.linkingResult]);

  useEffect(() => {
    // trap when image filters are shown or hidden so we can refresh and window sizes be correct
    setRedrawFlag(redrawFlag+1);

  }, [props.imageSearchFiltersExpanded]);

    function getImageStatusColour(status:string) {
        let statusObj = props.imageStatuses.find(s=>s.status===status);
        return statusObj != null ? statusObj.colour : 'ffffff';
    }

    function selectImageRow(image: IImage) {
        setRenderedSearchResults(defaultRenderedSearchResults);
        setSelectedImageRowId(image.id);
        props.onImageRowSelected(image);
    }

    function ImageRow(rowProps: ListChildComponentProps) {
        let image = imageSearchResults[rowProps.index];
        if (image != null) {

            let poleIdHasChanged = (rowProps.index > 0 ) ? imageSearchResults[rowProps.index-1].poleId != image.poleId : false;

            if (renderedSearchResults[rowProps.index] == null || renderedSearchResults[rowProps.index] == undefined || prevSelectionModeRef.current != props.isImageSelectionEnabled) {

                // Render it if it is not already rendered.
                renderedSearchResults[rowProps.index] = <div key={image.id} className={styles.imageSearchResultContainer} style={rowProps.style}>
                                                               
                                                                <ImageSearchResult 
                                                                    poleIdHasChanged={poleIdHasChanged}
                                                                    image={image} 
                                                                    imageStatusColour={getImageStatusColour(image.status)}
                                                                    isImageRowSelected={image.id === selectedImageRowId} 
                                                                    onDziImageSelected={(image: IImage) => {
                                                                        setRenderedSearchResults(defaultRenderedSearchResults)
                                                                        props.onDziImageSelected(image);

                                                                        if (dziSelectionSelectsRowRef.current) {
                                                                            selectImageRow(image);
                                                                        }

                                                                        }
                                                                    } 
                                                                    onImageRowSelected={(image: IImage) => {
                                                                        selectImageRow(image);
                                                                    }}
                                                                    selectedDziImageId={props.selectedDziImageId}
                                                                    isImageSelectionEnabled={props.isImageSelectionEnabled}
                                                                    isImageSelected={props.selectedImageIds.some(imageId => (imageId === image.id))}
                                                                    imageDefectCount={props.imageDefectCount.filter((x) => x.imageId === image.id)[0]}
                                                                    canViewDefects={props.canViewDefects}
                                                                    onImageCheckboxChecked={(image: any) => {
                                                                        props.onImageCheckboxChecked(image);
                                                                    }}
                                                                />
                                                    </div>
          }

          return renderedSearchResults[rowProps.index];
        }
        else {
            return null;
        }
    }

    const [hasNextPage, setHasNextPage] = useState(true);
    const [isNextPageLoading, setIsNextPageLoading] = useState(false);

    /*const handleScroll = (e: React.UIEvent<HTMLElement>): void => {
        e.stopPropagation();
        const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
        if (scrollTop != 0 && scrollHeight - scrollTop == clientHeight && (props.imageSearch != null && props.imageSearch.totalImagesCount != imageSearchResults.length)) {
            setLoading(true);
            let newPageIndex = pageIndex + 1;
            setPageIndex(newPageIndex);
            fetchSearchResults({ pageIndex: newPageIndex, sortOrder: sortOrder });
        }
    }*/

    function loadNextPage(startIndex: number, stopIndex: number) {
        setLoading(true);
        setIsNextPageLoading(true);
        let newPageIndex = pageIndex + 1;
        if (!initialised) {
          // Make sure InititeScroll loads from the first index      
          newPageIndex = 1;
        }

        setPageIndex(newPageIndex);

        return fetchSearchResults({ pageIndex: newPageIndex, sortOrder: sortOrder });
    };
    const itemCount = hasNextPage ? imageSearchResults.length + 1 : imageSearchResults.length;
    const isItemLoaded = (index: number) => {

        return !hasNextPage || index < imageSearchResults.length;
    }
    const emptyFunc = (startIndex: number, stopIndex: number) => { return null };
    let loadMoreItems = isNextPageLoading  ? emptyFunc : loadNextPage;

    // This code is called when props.imageSearch changes
    useEffect(() => {
        let doSearch = false;

        let thePageIndex = pageIndex;
        let theSortOrder = sortOrder;
 
        if (searchId !== prevSearchId) {
            doSearch = true;
            setRenderedSearchResults(defaultRenderedSearchResults);
            setInitialised(false); 
            //The search has changed so reset to defaults
            thePageIndex = defaultPageIndex;
            setPageIndex(thePageIndex);
            theSortOrder = defaultSortOrder;
            setSortOrder(theSortOrder);
        }

        //Store the searchid so we can work out when it changes
        prevSearchIdRef.current = searchId;

        if (doSearch && searchId.length > 0) {
            setInitialised (true);
            setImageSearchResults(defaultSearchArray);
            //dont fetch here let inifinite scroll take care of it
        }

        if (props.imageSearch == null) {
            setImageSearchResults(defaultSearchArray);
        }

    }, [props.imageSearch]);

    const fetchSearchResults = useCallback(async (cbprops: IFetchCallbackProps) => {
        
        if (props == null || props.imageSearch == null || props.imageSearch.searchId == null || props.imageSearch.searchId.length === 0)
            return;

        try {

            const searchFetchRequest: IImageSearchFetchRequest = {
                searchId: props.imageSearch.searchId,
                sortOrder: cbprops.sortOrder,
                sortField: sortType,
                paginated: true,
                pageIndex: cbprops.pageIndex,
                pageSize: pageSize
            };

            setRenderedSearchResults(defaultRenderedSearchResults);
            let response = await postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "search/fetch", searchFetchRequest);
            if (response.status === 200) {
                let searchResponse = response.data as IImageSearchFetchResponse;
                if (searchResponse.searchId === props.imageSearch.searchId) {
 
                   let newLength = imageSearchResults.length + searchResponse.images.length;
                   setImageSearchResults(prev => [...prev, ...searchResponse.images]);

                    setInitialised(true);
                    setLoading(false);
                    if (newLength < props.imageSearch.totalImagesCount) {
                        setHasNextPage(true);
                    }
                    
                    setIsNextPageLoading(false);
                }
                else {
                    console.log("Search id mismatch");
                }
            }
            else {
                console.log("Failed to get search results. Code = " + response.status);
            }
        }
        catch (e) {
            console.log(e);
        }

    }, [pageIndex, pageSize, props.imageSearch, imageSearchResults]);

    const fetchUpdatedSearchResults = useCallback(async ( cbprops: IFetchUpdatedImagesCallbackProps) => {
        
        if (imageSearchResults == null || imageSearchResults.length === 0)
            return;

        try {
            let response = await postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "search/fetchBySessionImageIds", cbprops.imageIds);
            if (response.status === 200) {
                let images = response.data as IImage[];
                
                images.forEach((image: IImage) => {
                    let idx = imageSearchResults.findIndex( (img:IImage) => img.id === image.id);
                    if ( idx != -1){
                       imageSearchResults[idx] = image;
                    
                       if (renderedSearchResults.length > idx )
                            delete renderedSearchResults[idx];
                    }
                });

                setImageSearchResults([...imageSearchResults]);
                setRenderedSearchResults([...renderedSearchResults]);
            }
            else {
                console.log("Failed to get search results. Code = " + response.status);
            }
        }
        catch (e) {
            console.log(e);
        }

    }, [imageSearchResults, renderedSearchResults]);

    // Get the full list of imageids for the search
    const fetchSearchResultImageIds = useCallback(async () => {
        
        if (imageSearchResults == null || imageSearchResults.length === 0 || props.imageSearch == null)
            return;

        try {
            let response = await fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "search/fetchImageIds/" + props.imageSearch.searchId);
            if (response.status === 200) {
                let images = response.data as number[];       
                setImageSearchImageIds(images);
                props.onImagesSelected(images);
            }
            else {
                console.log("Failed to get search image ids. Code = " + response.status);
            }
        }
        catch (e) {
            console.log(e);
        }

    }, [imageSearchResults]);

    function onClickToggleSortOrder() {
        if (props == null || props.imageSearch == null)
            return;

        let newSortOrder = sortOrder === 'descending' ? 'ascending ' : 'descending';
        setSortOrder(newSortOrder);

        let newPageIndex = defaultPageIndex;
        if(pageIndex != defaultPageIndex) {
            setPageIndex(newPageIndex);
        }

        setInitialised(false); 
        setImageSearchResults(defaultSearchArray);
        //Removed fetchSearchResults({ pageIndex: newPageIndex, sortOrder: newSortOrder }); on 03/02/2021
        //This was causing duplication in calling fetchSearchResults and a race condition with page index
        //onClickToggleSortOrder will call fetchSearchResults due to setting state of imageSearchResults or pageIndex 

    }

    function onClickChangeSortType() {
        if (props == null || props.imageSearch == null)
            return;

        setPageIndex(defaultPageIndex);
        setInitialised(false); 
        setImageSearchResults(defaultSearchArray);
    }

    function onSelectAllImages() {
        if (imageSearchImageIds.length > 0) {
            props.onImagesSelected(imageSearchImageIds);
        }
        else {
            fetchSearchResultImageIds();
        }
    }

    //If this component is not visible dont bother rendering as the InfiniteLoader will have problems
    if (!props.isVisible) 
        return null;

   // let searchResults: JSX.Element[] = [];
    let footerText = '';

    let sortSection: JSX.Element = (<div></div>);

    let hasImages = imageSearchResults != null && imageSearchResults.length > 0;
    if (props != null && props.imageSearch != null && hasImages) {

      /*  imageSearchResults.forEach(i => {
            searchResults.push(
                <div key={i.id} className={styles.imageSearchResultContainer}>
                    <ImageSearchResult image={i} onImageThumbnailSelected={(image: IImage) => props.onImageThumbnailSelected(image)} onImageRowSelected={(image: IImage) => props.onImageRowSelected(image)} />
                </div>
            )
        });*/

        let endImage = imageSearchResults.length;
        if (endImage > props.imageSearch.totalImagesCount)
            endImage = props.imageSearch.totalImagesCount;

        footerText = 'Showing ' + endImage + ' out of ' + props.imageSearch.totalImagesCount + ' images';

        let iconStyle = { fontSize: 10, height: 20 };
        let sortIcon: JSX.Element = <div></div>;
        let sortTypeMenuItems: JSX.Element[] = [];
        sortIcon = sortOrder === 'descending' ? <ArrowDownwardIcon onClick={onClickToggleSortOrder} style={iconStyle} /> : <ArrowUpwardIcon onClick={onClickToggleSortOrder} style={iconStyle} />;

        sortSection = (<Box height={30} className={styles.headerContainer}>  
            <Box display="flex" flexDirection="row" className={styles.headerContent}>
            <Box flexGrow={1} display="flex" flexDirection="row" >
                <Link style={{fontSize:14,marginRight:10, marginTop:"6px"}} onClick={() => props.toggleIsImageSelectionEnabled()}>
                   {props.isImageSelectionEnabled ? "CANCEL" : "SELECT"}
                </Link>
                { props.isImageSelectionEnabled  &&
                    <Link style={{fontSize:14,marginTop:"6px"}} onClick={() => onSelectAllImages() }>
                        SELECT ALL
                    </Link>
                }
            </Box>
                <Box width={25} style={{ marginTop: 4 }}>
                    {sortIcon}
                </Box>
                <Box flexGrow={0} display="flex" flexDirection="row" >
                    <Box style={{ marginRight: 5, lineHeight: '30px' }} >
                        by: 
              </Box>
                    <Box >
                        <FormControl >
                            <Select
                                value={sortType}
                                onChange={(event) => {
                                    setSortType(event.target.value as string);
                                    onClickChangeSortType();
                                }}
                                disableUnderline
                                style={{ fontSize: 14 }}
                                classes={{ root: selectClasses.root, icon: selectClasses.icon }}
                            >
                                {sortTypeMenuItems}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </Box>
        </Box>);

        sortTypes.forEach(st => {
            sortTypeMenuItems.push(<MenuItem classes={menuItemClasses} key={st} value={st}>{st}</MenuItem>)
        });

    }

    let noSearchResults = (<div key={1} style={{ height: '100%' }}>
        {props.imageSearch == null ? '' :
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '80%' }}>
                <img src={process.env.PUBLIC_URL + '/images/noresults.png'} title='No Results' style={{ paddingBottom: '10%', width: '38%' }} />
                <span className={styles.noResults}>No results found</span>
                <span className={styles.noResultsSub}>Try adjusting your search criteria</span>
            </Box>}
    </div>);

    let listHeight = listHeightSize;

    if (listRef != null && listRef.current != null) {
        let domObj = listRef.current as any;
        if (domObj.clientHeight > 0) {

            if (domObj.clientHeight != listHeightSize)
              setListHeightSize(domObj.clientHeight);

            listHeight = domObj.clientHeight;
        }
        else {
            // The clientHeight is 0 - this happens after clicking to a different tab and then back to this one
            // Do a timeout then trigger a render to give react time to fix up the dom because we need the correct clientHeight
            window.setTimeout( function(){
                setTriggerRenderFlag(triggerRenderFlag+1);
            }, 10 );
        }
    }

    return (
       
        <Box display="flex" flexDirection="column" className={styles.content} >
            {props.imageSearch && props.imageSearch.totalImagesCount > 0 && (<Box>{sortSection}</Box>)}
            <Box flexGrow={1} >
                <div style={{ height: '100%' }} ref={listRef} >
                {props.imageSearch && props.imageSearch.totalImagesCount > 0 ?
                        <Box style={{ height: '0px' }} >
                        
                        <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={itemCount}
                                loadMoreItems={loadMoreItems}
                        >
                            {({ onItemsRendered, ref }) => (
                                <List 
                                        className="List"
                                        height={listHeight}
                                        itemCount={itemCount}
                                        itemSize={106}
                                        ref={fixedListRef}
                                        width={props.isImageSelectionEnabled ? 480 : 450}
                                        initialScrollOffset={scrollPos != null ? scrollPos.current : 0}
                                        onItemsRendered={onItemsRendered}
                                        onScroll={(e: any) => scrollPos.current = e.scrollOffset}
                                >
                                    {ImageRow}
                                </List>
                            )}
                        </InfiniteLoader>
                    </Box> : noSearchResults} 
                 </div>
            </Box>
            <Box height={40} className={styles.footerContainer}>
                <Box display="flex" flexDirection="row" className={styles.footerContent}>
                    <Box flexGrow={1} className={styles.footerText}>
                        {footerText}
                    </Box>
                    <Box className={styles.loader}>
                        {loading && <CircularProgress disableShrink style={{ 'color': '#F7B500', 'width': '25px', 'height': '25px' }} />}
                    </Box>
                </Box>
            </Box>

        </Box>
    );
}
