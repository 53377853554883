import { Box, Button, Grid, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SelectDropDown } from '../../../SelectDropDown/SelectDropDown';
import styles from './TimeFilter.module.css';
import 'moment-timezone';

interface ITimeFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
}

const makeButtonStyles = makeStyles((theme) => ({
    root: {
        fontSize: 14,
        backgroundColor: '#95C5E7',

        '&:hover': {
            color: 'red',
            background: 'linear-gradient(0deg, rgba(249, 252, 255, 0.08), rgba(249, 252, 255, 0.08)), #95C5E7',
        },
    },
    label: {
        color: '#1E1E20',
        '&:hover': {
            color: '#1E1E20',
        },
    },
}));

export function TimeFilter(props: ITimeFilterProps) {
    const buttonClasses = makeButtonStyles();

    const [startHour, setStartHour] = React.useState('0');
    const [startMinute, setStartMinute] = React.useState('0');
    const [endHour, setEndHour] = React.useState('0');
    const [endMinute, setEndMinute] = React.useState('0');

    const [clearValues, setClearValues] = React.useState(false);
    const [lastClearFilters, setLastClearFilters] = useState(0);

    const [key, setKey] = useState(1);
    const [error, setError] = useState('');

    const onSave = () => {
        if (startHour !== '' && startMinute !== '' && endHour !== '' && endMinute !== '') {
            if (startHour === '0' && startMinute === '0' && endHour === '0' && endMinute === '0') {
                //if everything is 0 clear times
                props.onFilterChanged([]);
                setError('');
            } else {
                let startDate = moment().startOf('day');
                let endDate = moment().startOf('day');

                startDate.add(startHour, 'hours');
                endDate.add(endHour, 'hours');
                startDate.add(startMinute, 'minutes');
                endDate.add(endMinute, 'minutes');

                if (endDate <= startDate) {
                    setError('End time is earlier than start time');
                } else {
                    setError('');
                    let times = [];
                    let currentTimezone = moment.tz.guess();
                    times.push(startHour + ':' + startMinute);
                    times.push(endHour + ':' + endMinute);
                    times.push(currentTimezone);
                    props.onFilterChanged(times);
                }
            }
        }
    };

    const triggerClearValues = () => {
        if (clearValues) {
            setClearValues(false);
        }
    };

    const startHourChange = (value: any) => {
        setStartHour(value);
        triggerClearValues();
    };
    const startMinuteChange = (value: any) => {
        setStartMinute(value);
        triggerClearValues();
    };
    const endHourChange = (value: any) => {
        setEndHour(value);
        triggerClearValues();
    };
    const endMinuteChange = (value: any) => {
        setEndMinute(value);
        triggerClearValues();
    };

    let hours = [];
    let minutes = [];

    for (let i = 0; i < 60; i++) {
        if (i < 24) {
            hours.push(<option key={'hour_'+ i} value={i}>{i < 10 ? '0' + i : i}</option>);
        }
        //interval of 5 minutes
        if (i % 5 === 0) {
            minutes.push(<option key={'minutes_'+ i} value={i}>{i < 10 ? '0' + i : i}</option>);
        }
    }

    const resetValues = () => {
        setStartHour('0');
        setStartMinute('0');
        setEndHour('0');
        setEndMinute('0');
    };

    useEffect(() => {
        if (props.clearFilters !== lastClearFilters) {
            setLastClearFilters(props.clearFilters);
            setClearValues(true);
            resetValues();
        }
    }, [props.clearFilters, lastClearFilters]);

    const clearFilters = () => {
        setKey(key + 4);
        setClearValues(true);
        resetValues();
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Box className={styles.layout}>
                    <Typography variant="h5" noWrap>
                        Select time range
                    </Typography>
                    <Grid style={{ marginTop: 20 }} container spacing={1}>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 14 }} noWrap>
                                Start time
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectDropDown keyId={key} id={'start-hour'} name={'Hour'} clearValues={clearValues} dropdownValues={hours} onSelected={startHourChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectDropDown keyId={key + 1} id={'start-minute'} name={'Minute'} clearValues={clearValues} dropdownValues={minutes} onSelected={startMinuteChange} />
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: 20 }} container spacing={1}>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: 14 }} noWrap>
                                End time
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <SelectDropDown keyId={key + 2} id={'end-hour'} name={'Hour'} clearValues={clearValues} dropdownValues={hours} onSelected={endHourChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectDropDown keyId={key + 3} id={'end-minute'} name={'Minute'} clearValues={clearValues} dropdownValues={minutes} onSelected={endMinuteChange} />
                        </Grid>
                    </Grid>
                    <Grid style={{ marginTop: 20 }} container spacing={0}>
                        <Grid item xs={6}>
                            <Typography style={{ marginTop: 4 }} variant={'subtitle1'} color={'error'}>
                                {error}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="flex-end">
                                <Link style={{ fontSize: 14, marginRight: 20, marginTop: 10 }} onClick={clearFilters}>
                                    CLEAR ALL
                                </Link>
                                <Button variant="contained" onClick={onSave} classes={{ root: buttonClasses.root, label: buttonClasses.label }}>
                                    SAVE
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </div>
    );
}
