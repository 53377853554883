import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

import { IGeneralSnackbarConfig } from '../../../models/IGeneralSnackbarConfig';

interface IGeneralSnackbarProps {
    config: IGeneralSnackbarConfig | null;
}
 
const useStyles = makeStyles((theme) => ({
    root: {
        width: '50%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    anchorOriginTopCenter: {
        top: 70
    },

}));

const makeSuccessStyles = makeStyles((theme) => ({
    root: {
        background: '#A3D776 !important'
    },
    message: {
        color:'black',
        backgroundColor: '#A3D776', 
    }
}));

const makeErrorStyles = makeStyles((theme) => ({
    root: {
        background: '#FF9999 !important'
    },
    message: {
        color:'black',
        backgroundColor: '#FF9999', 
    }
}));

const makeInfoStyles = makeStyles((theme) => ({
    root: {
        background: 'linear-gradient(0deg, rgba(249, 252, 255, 0.12), rgba(249, 252, 255, 0.12)), #292A2B !important'
    },
    message: {
        color: '#F9FCFF',
        backgroundColor: 'linear-gradient(0deg, rgba(249, 252, 255, 0.12), rgba(249, 252, 255, 0.12)), #292A2B'
    }
}));

const makeWarningStyles = makeStyles((theme) => ({
    root: {
        background: 'linear-gradient(0deg, rgba(249, 252, 255, 0.12), rgba(249, 252, 255, 0.12)), #FFB74D !important'
    },
    message: {
        color: 'black',
        backgroundColor: 'linear-gradient(0deg, rgba(249, 252, 255, 0.12), rgba(249, 252, 255, 0.12)), #FFB74D'
    }
}));

type SnackbarVerticalPosition = "bottom" | "top";

export function GeneralSnackbar(props: IGeneralSnackbarProps) { 
    const classes = useStyles();
    const infoClasses = makeInfoStyles();
    const errorClasses = makeErrorStyles();
    const successClasses = makeSuccessStyles();
    const warningClasses = makeWarningStyles();

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (props.config != null) {
            setOpen(true);
        }       
    }, [props.config]);

    if ( props.config == null)
        return null;

    let contentClasses = infoClasses;

    switch (props.config.messageType) {
        case "error": contentClasses = errorClasses; break;
        case "success": contentClasses = successClasses; break;
        case "info": contentClasses = infoClasses; break;
        case "warning": contentClasses = warningClasses; break;
    }

    return (
        <div>
            <Snackbar 
                classes={classes}
                open={open}
                action={props.config.action != null ? props.config.action : undefined }
                autoHideDuration={props.config.autoHideDuration != null ? props.config.autoHideDuration : undefined }
                message={props.config.message}
                anchorOrigin={{ vertical: props.config.verticalAnchorOrigin as SnackbarVerticalPosition, horizontal: 'center' }}
                ContentProps={{ classes: contentClasses }}
                onClose={() => setOpen(false) }
            />
        </div>
    );
}
