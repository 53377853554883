import React, {useState, useEffect, useCallback } from 'react';
import { Box, Tooltip } from '@material-ui/core/';
import { IImage } from '../../models/IImage';
import styles from './ImageCarousel.module.css';
import axios from 'axios';
import { postWithAuthorisationHeader, getAuthorizationData, refreshToken } from "../../services/AuthenticationService";
import ImageDefectIndicator from '../Shared/ImageDefectindicator/ImageDefectIndicator';
import { IImageDefectCount } from '../../models/IImageDefectCount';
const axiosApiInstance = axios.create();

interface IImageCarouselProps {
    selectedImageIds: number[];
    onImageRemoved: Function;
    onDziImageSelected: Function;
    selectedDziImageId: number;
    onClearSelectedImages :Function;
    imageDefectCount: IImageDefectCount[];
}

interface ILoadedImage {
    imageId:number;
    data:any | null;
}

interface IGetImageCallbackProps {
    imageId:number;
    loadedImages:ILoadedImage[];
}

export function ImageCarousel(props:IImageCarouselProps) {
    const defaultLoadedImagesArray: ILoadedImage[] = [];

    const [isMinimised, setIsMinimised] = useState(false);
    const [loadedImages, setLoadedImages] = useState(defaultLoadedImagesArray);
    const [redrawFlag, setRedrawFlag] = useState(0);
    const [imageDefectCount, setImageDefectcount] = useState([] as IImageDefectCount[]);

    //Dzi zoom level for image just slightly bigger than we need
    let zoomLevel = 9;
    let maxImagesToDisplay = 12;

    useEffect(() => {
        // We need to load images here as they need auth which means we cant just use img with a url
        // We queue them for loading and when loaded we get the data and update the images ILoadedImage object

        // remove any loaded ones that have been unselected
        let newLoadedImages = loadedImages.filter(il => props.selectedImageIds.includes(il.imageId));

        // load newly selected images
        let loadedImageIds = newLoadedImages.map(il => il.imageId);
        let imagesToLoad = props.selectedImageIds.filter(imageId => !loadedImageIds.includes(imageId));

        let numImagesToLoad = maxImagesToDisplay - newLoadedImages.length >= 0 ? maxImagesToDisplay - newLoadedImages.length : 0;

        imagesToLoad = imagesToLoad.slice(0, numImagesToLoad);
        imagesToLoad.forEach(imageId => newLoadedImages.push({imageId:imageId,data:null}));
        setLoadedImages(newLoadedImages);

        if (newLoadedImages.length > 0) {
          let imageIds = newLoadedImages.map((x) => x.imageId);
          getImageDefectCount(imageIds);
        }

        imagesToLoad.forEach(imageId => getImage({imageId:imageId,loadedImages:newLoadedImages}));
    }, [props.selectedImageIds]);


    const getImageDefectCount = async (imageIds: number[]) => {
      try {
        let response = await postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + 'defectDetection/defectsByImageIds', imageIds);
        if (response.status === 200) {
          let images = response.data as IImageDefectCount[];
          setImageDefectcount(images);
          
        } else {
          console.log('Failed to get full image for DZI Viewer. Code = ' + response.status);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const onFullImageSuccess = (response: any, cbprops: IGetImageCallbackProps) => {
        const data: any = `data:${response.headers['content-type']};base64,${new Buffer(response.data).toString('base64')}`;

        let loadedImage = cbprops.loadedImages.find(i => i.imageId === cbprops.imageId);
        if (loadedImage != null) {
            loadedImage.data = data;
            setRedrawFlag(new Date().getTime());
        }
    }

    const getImage = useCallback(async (cbprops: IGetImageCallbackProps) => {
        var authData = await getAuthorizationData();
        var url = process.env.REACT_APP_VAA_API_URL + "dzi/fullImage/" + cbprops.imageId + "/" + zoomLevel;
        axiosApiInstance.get(url, {
            method: "GET",
            headers: { Authorization: 'Bearer ' + authData.token },
            responseType: "arraybuffer",
        }).then((response: any) => {
            onFullImageSuccess (response, cbprops);
        }).catch(async (error: any) => {
            if (error.response.status === 401) {
                const access_token = await refreshToken();
                if (access_token != null) {
                    axiosApiInstance.get(url, {
                        method: "GET",
                        headers: { Authorization: 'Bearer ' + access_token },
                        responseType: "arraybuffer",
                    }).then((response: any) => {
                        onFullImageSuccess(response, cbprops);
                    });
                }
                else {
                    //Redirect to aims as there is no valid token
                    window.location.href = (process.env.REACT_APP_AIMS3D_URL as string);
                }
            }
        })

    },[loadedImages]);


    const getDefectCount = (imageId: number) => {
      let imagedefectCount = imageDefectCount.find((i) => i.imageId === imageId);
      return imagedefectCount !== undefined ? imagedefectCount?.defectCount : 0;
    };

    const fetchFullImageForDziViewer = useCallback(async ( imageId: number) => {
        
        try {

            let response = await postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "search/fetchBySessionImageIds", [imageId]);
            if (response.status === 200) {
                let images = response.data as IImage[];
                if ( images.length === 1){
                    props.onDziImageSelected(images[0]);
                }
            }
            else {
                console.log("Failed to get full image for DZI Viewer. Code = " + response.status);
            }            
        }
        catch (e) {
            console.log(e);
        }

    },[]); 


    if ( props.selectedImageIds == null || props.selectedImageIds.length === 0 )
        return null;

    let displayMessage = props.selectedImageIds.length > maxImagesToDisplay ? 'First ' + maxImagesToDisplay + ' images of selection shown only' : '';

    let images: JSX.Element[] = [];

    let i=0;
    loadedImages.filter(i=>i.data != null).forEach((loadedImage: ILoadedImage) => {
        let imageId = loadedImage.imageId;
        images.push(
                <Box key={imageId} style={{ marginLeft: i > 0 ? 15 : 0,position:'relative' }}>
                    <Tooltip title='Remove from selection'>
                        <img style={{position:'absolute',top:10,left:10}} src={process.env.PUBLIC_URL + '/images/close.png'} className={styles.linkPointer} onClick={() => props.onImageRemoved(imageId) } />
                    </Tooltip>
                    <ImageDefectIndicator
                        imageId={imageId}
                        imageDefectCount={getDefectCount(imageId)}
                        imageDefectCountLatest={props.imageDefectCount.filter(x => x.imageId === imageId)[0]?.defectCount}
                        canViewDefects={true}
                        height={199}
                        left={1}
                        top={2}
                    />
                    <img style={{height:'auto',width:'auto',maxHeight:200,maxWidth:300,border: props.selectedDziImageId == imageId ? '2px solid #FF6107' : '2px solid transparent'}}  src={loadedImage.data} onClick={() => fetchFullImageForDziViewer(imageId)}/>
                </Box>
            );
        ++i;
    });
    
    return <div>
                <div className={styles.minimisedToolbar} style={{display: isMinimised ? 'block' : 'none'}}>
                    <Box display="flex" flexDirection="row" style={{color:'#F9FCFF'}} >
                        <Box>
                            <span style={{fontSize:14}}>Selected images</span>
                        </Box> 
                        <Box flexGrow={1} >                   
                            <Box style={{marginBottom:10,textAlign:'right'}} >
                                <Tooltip title='Maximise'>
                                    <img style={{width:27,height:27,marginRight: 5}} src={process.env.PUBLIC_URL + '/images/maximise.png'} className={styles.linkPointer} onClick={() => setIsMinimised(false) } />
                                </Tooltip>
                            </Box>  
                        </Box>
                        
                        <Box style={{marginBottom:10,textAlign:'right'}} >
                                    <Tooltip title='Close'>
                                        <img style={{width:24,height:26}} src={process.env.PUBLIC_URL + '/images/close.png'} className={styles.linkPointer} onClick = {()=>props.onClearSelectedImages ()} />
                                    </Tooltip>
                                </Box> 
                                
                    </Box>
                </div>        
                <div className={styles.container} style={{display: loadedImages.filter(i=> i.data !=null).length > 0 && !isMinimised ? 'block' : 'none'}} >
                    <div className={styles.content}>
                        <Box display="flex" flexDirection="row" style={{color:'#F9FCFF'}}>
                            <Box>
                                <span style={{fontSize:14}}>Selected images</span>
                            </Box>  
                            <Box flexGrow={1} style={{textAlign:'right',marginRight:15}}>
                                <span style={{fontSize:12}}>{displayMessage}</span> 
                            </Box>
                            <Box >                   
                                <Box style={{marginBottom:10,textAlign:'right'}} >
                                    <Tooltip title='Minimise'>
                                        <img style={{width:27,height:27,marginRight: 5}} src={process.env.PUBLIC_URL + '/images/minimise.png'} className={styles.linkPointer} onClick={() => setIsMinimised(true)} />
                                    </Tooltip>
                                  
                                </Box> 
                              
                            </Box>  
                            <Box style={{marginBottom:10,textAlign:'right'}} >
                                    <Tooltip title='Close'>
                                        <img style={{width:24,height:26}} src={process.env.PUBLIC_URL + '/images/close.png'} className={styles.linkPointer} onClick = {()=>props.onClearSelectedImages ()} />
                                    </Tooltip>
                                </Box> 
                                            
                        </Box>
                        <Box display="flex" flexDirection="row" style={{ maxHeight: '100%', height: '100%', overflowX:'auto', overflowY:'hidden' }} onWheel={ (e:any) => e.currentTarget.scrollLeft -= e.nativeEvent.wheelDeltaY } >       
                            {images}
                        </Box>
                    </div>
                </div>
            </div>
}
