import React, { useState, useEffect, createRef, useRef } from 'react';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import styles from './PackFilter.module.css';
import TextField from '@material-ui/core/TextField';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';

const makeControlStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14
  },
  labelSelected: {
    fontSize: 14,
    color: '#F7B500'
  }
}));


interface IPackFilterProps {
  onFilterChanged: Function;
  clearFilters: number;
  campaignId: number;
}

interface IPack {
  name: string;
  isSelected: boolean;
}

export function PackFilter(props: IPackFilterProps) {

  const controlClasses = makeControlStyles();
  const initialPacks: IPack[] = [];
  const [packs, setPacks] = useState(initialPacks);
  const [filteredPacks, setFilteredPacks] = useState(initialPacks);
  const [lastClearFilters, setLastClearFilters] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  // Handle when the filters have been cleard
  // A number is passed as a flag so we can tell when it has changed
  useEffect(() => {

    if (props.clearFilters != lastClearFilters) {
      packs.forEach(s => s.isSelected = false);
      setPacks(packs.slice());
      setSelectAll(false);
      setLastClearFilters(props.clearFilters);
    }

  }, [props.clearFilters, lastClearFilters, packs]);

  
  //Handle when campaign has been changed
  useEffect(() => {

    async function getPacks() {
      try {
        var packList = initialPacks;
        packs.forEach(s => s.isSelected = false);
        let selectedPacks = packs.filter(s => s.isSelected && s.name != 'Select All').map(s => s.name);
        props.onFilterChanged(selectedPacks);
        if(props.campaignId > 0) {
          var response = await fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "campaign/" + props.campaignId + "/exif/" + "Pack" );
          var responseJson = JSON.parse(response.data);
          responseJson.sort((a:any, b:any) => (a.pack.toLowerCase() > b.pack.toLowerCase()));
          responseJson.forEach(((row: { pack: string}) => {
            let newPack = { name: row.pack , isSelected: false};
            packList.push(newPack);
          }));
          setPacks(packList);
          setFilteredPacks(packList);
        }
      }
      catch(e) {
         console.log(e);
      }
    }
      getPacks();
    }, [ props.campaignId]);

    function onSelectAll(selectAll: boolean) {
      if(!selectAll)
      {
        filteredPacks.forEach(p => p.isSelected = true);
      } else {
        filteredPacks.forEach(p => p.isSelected = false);
      }

      setPacks(packs.slice());
      let selectedPacks = packs.filter(p => p.isSelected).map(p => p.name);
      props.onFilterChanged(selectedPacks);
      setSelectAll(!selectAll);
    }

    function onCheckboxChanged(pack: IPack) {
      pack.isSelected = !pack.isSelected;
      setPacks(packs.slice());
      let selectedPacks = packs.filter(p => p.isSelected).map(p => p.name);
      props.onFilterChanged(selectedPacks);
      syncSelectAll();
    };
  
    function syncSelectAll(){
      let selectedPacks = packs.filter(s => s.isSelected);
      if(!selectAll && selectedPacks.length == packs.length)
      {
        setSelectAll(true);
      } else if(selectAll && selectedPacks.length < packs.length)
      {
        setSelectAll(false);
      }
    }
  
    function onPackChange(value:string) {
      if(value=="")
      {
        syncSelectAll();
        setFilteredPacks(packs);
      } else 
      {
        setFilteredPacks(packs.filter(p => p.name.toLowerCase().includes(value.toLowerCase())));
      }
    };
  
    let packCheckboxes: JSX.Element[] = [];
    filteredPacks.forEach(pack => {
      packCheckboxes.push(
        <FormControlLabel
          key={pack.name}
          classes={{ label: pack.isSelected ? controlClasses.labelSelected : controlClasses.label }}
          control={<Checkbox checked={pack.isSelected} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500' }} />} onChange={() => onCheckboxChanged(pack)} name={pack.name} size={'small'} />}
          label={pack.name}
        />
      );
    });
  
    let selectAllCheckbox = (
      <FormControlLabel
          key={"Select All"}
          classes={{ label: selectAll ? controlClasses.labelSelected : controlClasses.label }}
          control={<Checkbox style={{ color: '#F7B500', opacity: '0.5 !important' }} checked={selectAll} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500', opacity: '0.5 !important' }} />} onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
          label={"Select All"}
        />
    );
  
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <FormGroup>
          <TextField  style={{ color: '#F7B500' }} id="pack-filter" label="Search" onChange={(event) => onPackChange(event.target.value)} />
            {selectAllCheckbox}
            {packCheckboxes}
          </FormGroup>
        </div>
      </div>
    );
  }