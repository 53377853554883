import React, { useState, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import styles from './FileNameFilter.module.css';
import TextField from '@material-ui/core/TextField';

interface IFileNameFilterProps {
  onFilterChanged: Function;
  clearFilters: number;
  urlImage: string | undefined;
  urlCampaignExists : boolean;
  urlSessionExists : boolean;
}

export function FileNameFilter(props: IFileNameFilterProps) {
  const [fileNameString, setFileNameString] = useState('');
  const [lastClearFilters, setLastClearFilters] = useState(0);

  // Handle when the filters have been cleared
  // A number is passed as a flag so we can tell when it has changed
  useEffect(() => {
    if (props.clearFilters != lastClearFilters) {
      //checking none of the flags are 0 as initally it wipes the urlImage name
      if(props.clearFilters !== 0 && lastClearFilters !== 0){
        setFileNameString('');
      }

      setLastClearFilters(props.clearFilters);
    }
  }, [props.clearFilters, lastClearFilters]);

  useEffect(() => {
    if (props.urlCampaignExists && props.urlSessionExists && props.urlImage) {
      onFileNameChange(props.urlImage);
    }
  }, [props.urlCampaignExists, props.urlSessionExists, props.urlImage]);

  function onFileNameChange(value: string) {
    setFileNameString(value);
    props.onFilterChanged(value);
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FormGroup>
          <TextField
            style={{ color: '#F7B500', marginBottom: '5px' }}
            id="filename-filter"
            label="Search"
            onChange={(event) => onFileNameChange(event.target.value)}
            value={fileNameString}
          />
        </FormGroup>
      </div>
    </div>
  );
}
