import React, { useState, useEffect } from "react";
import renderToStaticMarkup from "react-dom/server";
import { Box, Typography } from "@material-ui/core/";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Popper, { PopperProps } from "@material-ui/core/Popper";
import styles from "./ShapefileDataDetails.module.css";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IShapefileDataDetailsConfig } from "../../models/IShapefileDataDetailsConfig";

interface IShapefileDataDetailsProp {
  config: IShapefileDataDetailsConfig | null;
  onClose: Function;
}

export function ShapefileDataDetails(props: IShapefileDataDetailsProp) {
  let shapefileName: string = "";
  let iconPath: string = "../../../images/MapLayerIcons/";
  let fullIconPath: string = "";
  const [attributesPanelOpen, setattributesPanelOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);

  useEffect(() => {
    if (props.config) {
      const selection: ClientRect = {
        top: props.config.screenY + 5,
        right: props.config.screenX + 12,
        width: 1,
        height: 1,
        bottom: props.config.screenY + 1,
        left: props.config.screenX - 1,
      };
      const getBoundingClientRect = () => selection;

      setAnchorEl({
        clientWidth: getBoundingClientRect().width,
        clientHeight: getBoundingClientRect().height,
        getBoundingClientRect: getBoundingClientRect,
      });
    }
  }, [props.config]);

  let attributesElement: JSX.Element[] = [];

  if (
    props.config &&
    props.config.shapefileData &&
    props.config.screenX &&
    props.config.screenY &&
    props.config &&
    props.config.shapefileData
  ) {
    shapefileName = props.config.shapefileData.shapefileName;
    fullIconPath = `${iconPath}${props.config.shapefileData.icon ? props.config.shapefileData.icon : props.config.shapefileData.lineType}${props.config.shapefileData.displayColor?"_" + props.config.shapefileData.displayColor:""}.png`;
    var attributes = JSON.parse(props.config.shapefileData.attributes);
    attributesElement.push(
      <GridList cols={1}>
        {Object.keys(attributes).map((key) => (
          <GridListTile key={key} cols={1} className={styles.attributes}>
            <Grid container style={{color: props.config?.shapefileData?.attributeSearchField && props.config?.shapefileData.attributeSearchField.toLowerCase() == key.toLowerCase() ? '#F7B500' : '#D6D7D8'}}>
              <Grid item xs={6}>
                {key.toLowerCase()}
              </Grid>
              <Grid item xs={6} style={{ fontSize: 12, paddingRight: 5 }}>
                {attributes[key]}
              </Grid>
            </Grid>
          </GridListTile>
        ))}
      </GridList>
    );
  }


return (
  <Popper
    placement="right-start"
    disablePortal={false}
    open={true}
    anchorEl={anchorEl}
    modifiers={{
      flip: {
        enabled: true,
      },
      preventOverflow: {
        enabled: true,
        boundariesElement: "viewport",
      },
    }}
  >
    <div className={styles.container}>
      <Grid container className={styles.content} alignItems="center"style={{display:'flex'}}>

        <Grid item  className={styles.header} >
          <Grid container justify="space-between" alignItems="center"style={{display:'flex'}}>
            <Grid item alignItems="center"style={{display:'flex'}} >
              <img src={fullIconPath} style={{ paddingRight:6}}></img>
            </Grid>

            <Grid item>
              <IconButton
                disableRipple={true}
                disableFocusRipple={true}
                onClick={() => props.onClose()}
              >
                <ClearIcon className={styles.clear} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={styles.header} style={{paddingTop:7}} >
          <Grid container justify="space-between" alignItems="center"style={{display:'flex'}}>
            <Grid item alignItems="center" style={{display:'flex'}} >
              <a style={{ fontWeight: 400, fontSize:16}}>
                  {shapefileName}
              </a>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ width: '100%',  display: 'block', marginRight: 6}}>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{display:'flex'}}>
            <Grid item  alignItems="center" style={{display:'flex',}}>
              <span
                className={styles.header}
                style={{fontWeight: 500 }}
              >
                Attributes
              </span>
            </Grid>

            <Grid item >
              <IconButton
                style={{marginRight: -10, fontWeight: 500 }}
                onClick={() => setattributesPanelOpen(!attributesPanelOpen)}
              >
                <ExpandMoreIcon />
              </IconButton>
            </Grid>

          </Grid>
          <Grid item>
          {attributesPanelOpen && (
            <div style={{ marginBottom: 24 }}> {attributesElement} </div>
          )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  </Popper>
);
          }
