export class ImageStatus {
    public static Linked: string = "Linked";
    public static NoLink: string = "NoLink";
    public static Processed: string = "Processed";
    public static Created: string = "Created";
    public static Error: string = "Error";

    //Commented Statuses for future use
    /*
    public static Assessed: string = "Assessed";
    public static Assessing: string = "Assessing";
    public static Manual_Flagged: string = "Manual_Flagged";
    public static Manual_Linked: string = "Manual_Linked";
    public static Manual_NoLink: string = "Manual_NoLink";
    public static Multilink: string = "Multilink";
    */
  
}
