import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { makeStyles } from '@material-ui/core/styles';
import { postWithAuthorisationHeader } from '../../../services/AuthenticationService';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Search } from '@material-ui/icons';
import Cancel from '@material-ui/icons/Cancel';
import { AssetSearchLayerFilter } from '../Filters/AssetSearchLayerFilter/AssetSearchLayerFilter';
import { IMapLayer } from '../../../models/IMapLayer';
import useComponentVisible from '../../../hooks/useComponentVisible';

import styles from './AssetSearch.module.css'

interface IAssetSearchProps {
     shapefileLayers: IMapLayer[];
     onAssetSearchCreated: Function;
     onSearchStarted: Function | null;
     onAssetSearchUpdated: Function;
     onUpdateIsLoadingCheck: Function;
     triggerSearch:string;
};

interface ISearchFilter {
  name: string;
  isDisplayed: boolean;                 //whether the filter component is currently shown
  displayX: number;                     //where we show the filter component x
  displayY: number;                     //where we show the filter component y  
  filterItemsSelectedCount: number;     //how many items are selected in the filter component
  clearFiltersFlag: number;             //used to signal to the filter component to clear itself
};

const makeChipStyles = makeStyles((theme) => ({
  outlined: {
    backgroundColor: '#3C3D3E',
    marginRight: '6px',
    marginBottom: '8px'
  },
  icon: {
    position: 'absolute',
    right: 5,
  },
  iconSelected: {
    position: 'absolute',
    right: 5,
    color:'#F7B500'
  },
  label:{
    marginRight:15,
    color: '#F9FCFF'
  },
  labelSelected:{
    marginRight:15,
    color: '#F7B500'
  },
}));

const makeBadgeStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor:'#F7B500',
    color: '#000000',
    marginLeft: '4px',
    marginTop: '4px'
  }
}));

export function AssetSearch(props:IAssetSearchProps){
    const chipClasses = makeChipStyles();
    const badgeClasses = makeBadgeStyles();

    const initialMapLayers: (number|null|undefined)[] = [];
    const [searchString, setSearchString] = React.useState('');
    const [isClearEnabled, setIsClearEnabled] = React.useState(false);
    const allSearchFilters: ISearchFilter[] = [
                                              { name: 'Asset type', isDisplayed: false, displayX:0, displayY:0, filterItemsSelectedCount: 1, clearFiltersFlag: 0 },
                                            ];

    const [searchFilters, setSearchFilters] = useState( allSearchFilters);
    const { ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);
    const [shapefileLayerFileIdsToSearch, setShapefileLayerFileIdsToSearch] = useState( initialMapLayers);
    const [searchAssets, setSearchAssets] = useState(true);
    
    /* required as a callback for triggering a search */
    useEffect(() => {
      if (searchString != "" && props.triggerSearch === searchString)
        createSearch();
    },[searchString])

    /* handle a parent call to search */
    useEffect(()=> {
      setSearchString(props.triggerSearch); 
      setIsClearEnabled(false);
      setSearchAssets(true);
    },[props.triggerSearch]);


    // If a filter is shown then hide it
    function hideFilter(){
        searchFilters.forEach(f=>f.isDisplayed = false);
        setSearchFilters(searchFilters.slice());
    }

    async function createSearch(){
      hideFilter();
      props.onUpdateIsLoadingCheck(true);

        try {
            if (props.onSearchStarted) props.onSearchStarted(searchString);
            let assetSearchUrl = process.env.REACT_APP_VAA_API_URL + "asset/fetchByString/";

            let data = {
                 "searchString": searchString,
                 "segmentSearch": searchAssets,
                 "shapefileFileIds": props.shapefileLayers.filter(l=> shapefileLayerFileIdsToSearch.includes(l.fileId) && l.isActive ).map(l=>l.fileId)
            };

            let response = await postWithAuthorisationHeader(assetSearchUrl, data);
            if (response.status === 200) { 
              props.onAssetSearchUpdated(response.data);
              setIsClearEnabled(true);
            }
            else {
              console.log("Failed to search assets " +  response.status );
            }
        }
        catch(e) {
            console.log(e);
        }
        props.onUpdateIsLoadingCheck(false);
    }

    function handleKeyPress(event: any){

      if(event.key === 'Enter' && searchString.length >= 2){
        createSearch();
      }
    }

    function clearAssetSearch() 
    {
      setSearchString('');
      props.onAssetSearchUpdated(null);
      setIsClearEnabled(false);
    }

    // Show or hide a filter
    function toggleFilterDisplay(clickEvent:any, filter: ISearchFilter) {

        let newState = !filter.isDisplayed;

        searchFilters.forEach(f=>f.isDisplayed = false);

        filter.isDisplayed = newState;
        filter.displayX = clickEvent.currentTarget.getBoundingClientRect().x -90 ;
        filter.displayY = clickEvent.currentTarget.getBoundingClientRect().y -44; 
    
        setIsComponentVisible(newState);

        setSearchFilters(searchFilters.slice());
    }

    let filterChips: JSX.Element[] = [];
    let searchFilterInstances: JSX.Element[] = [];

    searchFilters.forEach( (filter:ISearchFilter) => {

        let userHasSelectedFilters = filter.filterItemsSelectedCount > 0;

        filterChips.push( 
            <span key={filter.name}>
                <Badge 
                    classes={badgeClasses}
                    max={9}
                    badgeContent={filter.filterItemsSelectedCount} 
                    color="primary" 
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
           
                    <Chip
                        style={userHasSelectedFilters ? { border: '1px solid #F7B500'} : {}}
                        classes={{icon: userHasSelectedFilters ? chipClasses.iconSelected : chipClasses.icon, label: userHasSelectedFilters ? chipClasses.labelSelected : chipClasses.label, outlined: chipClasses.outlined}}
                        variant="outlined"
                        icon={ filter.isDisplayed ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                        label={filter.name}
                        onClick={(e) => toggleFilterDisplay(e,filter)}
                    />
                </Badge>
            </span>
        );

        let searchFilterInstance: JSX.Element | null = null;

         let leftOffset = 71;

        let filterStyle = {
              left: leftOffset + filter.displayX,
	          top: filter.displayY,
            display: filter.isDisplayed ? 'block' : 'none',
            overflowY:"auto" as "auto",
            maxHeight:"90%"
        };


        if (filter.name === 'Asset type') {
          searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
           <AssetSearchLayerFilter 
              clearFilters={filter.clearFiltersFlag} 
              shapefileLayers={props.shapefileLayers}
              onFilterChanged={ (selectedShapefileLayers:number[],searchAssets:boolean)=> { 

                  setSearchAssets(searchAssets);
                  setShapefileLayerFileIdsToSearch(selectedShapefileLayers);
 
                  // Set the count for the badge
                  filter.filterItemsSelectedCount = selectedShapefileLayers.length + (searchAssets ? 1 : 0 ) ;
                  
                  setSearchFilters(searchFilters.slice());
               }}  />
            </div>
        }

        if ( searchFilterInstance != null)
            searchFilterInstances.push(searchFilterInstance);

    });
    
    if (searchFilters.find(f=>f.isDisplayed) != null && !isComponentVisible ) {
        hideFilter();
    }

    return (
        <Box display="flex" flexDirection="column" className="searchBox" flexWrap="nowrap" style={{backgroundColor:'#2F3132', display: "block", marginLeft: "auto", marginRight: "auto"}}>

          <TextField
            className="searchInput"
            id="search-input"
            style={{backgroundColor:'rgb(62, 63, 65)',  width:'95%', margin:8, color:"white", borderRadius:"4px" }}
            placeholder="Search for assets by asset ID"
            value={searchString}
            variant="outlined"
            onChange={(e:any) => {setSearchString(e.target.value); setIsClearEnabled(false);}}
            onKeyPress={handleKeyPress}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                  {searchString.length < 1 ? <IconButton disabled={true}><Search/></IconButton> : 
                  isClearEnabled ? <IconButton onClick={clearAssetSearch}><Cancel/></IconButton> :
                  <IconButton disabled={ searchString.length < 2 || (searchAssets === false && shapefileLayerFileIdsToSearch.length === 0 )} onClick={createSearch}><ArrowForward/></IconButton>
                  }
              </InputAdornment>,
            }}
          />    

            <Box display="flex" justifyContent="flex-start" flexWrap="wrap" marginLeft={2} > 
                {filterChips}
                <div ref={ref} onClick={() => setIsComponentVisible(true)}>
                    {searchFilterInstances}   
                </div>
            </Box>
        </Box>
        );
}
      