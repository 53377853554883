import {Box} from '@material-ui/core';
import * as React from 'react';

let styles =  require('./Common.module.css');


//---------- no results found message -----------------------------------------
interface INoResultsFoundMessageProps {
    imageUrl:string;
    message:string;
}

export const NoResultsFoundMessage = (props:INoResultsFoundMessageProps) => {
    return (
        <Box className={styles.noResultsBoilerPlate} display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{height:'90%'}}>
            <img src={props.imageUrl} title={props.message} style={{paddingBottom:'10%', width:'38%'}}/>
            <span>{props.message}</span>
        </Box>);
} 




//-------------------------------- UTIL functions ---------------------------
export function deepClone<T>(o:T):T {
    const JSClone = (o:any) => JSON.parse(JSON.stringify(o));
    return JSClone(o) as T;
}
