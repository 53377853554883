import React, { useState, useEffect, useCallback } from 'react';
import { fetchWithAuthorisationHeader } from "../../../../services/AuthenticationService";
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import styles from './DefectTypeFilter.module.css';
import TextField from '@material-ui/core/TextField';

const makeControlStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14
  },
  labelSelected: {
    fontSize: 14,
    color: '#F7B500'
  },
  labelSelectedNoLongerUsed: {
    fontSize: 14,
    color: '#808080'
  }
}));

interface IDefectTypeFilterProps {
  campaignId: number;
  isDisplayed:boolean;
  onFilterChanged: Function;
  clearFilters: number;
}

interface IFilterDefectType {
  name: string;
  isSelected: boolean;
  isNoLongerUsed: boolean;
}

export function DefectTypeFilter(props: IDefectTypeFilterProps) {

  const controlClasses = makeControlStyles();
  const initialDefectTypes: IFilterDefectType[] = [];
  const [defectTypes, setDefectTypes] = useState(initialDefectTypes);
  const [filteredDefectTypes, setFilteredDefectTypes] = useState(initialDefectTypes);
  const [lastClearFilters, setLastClearFilters] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  // Handle when the filters have been cleard
  // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {

        if (props.clearFilters != lastClearFilters) {
            defectTypes.forEach(s => s.isSelected = false);
            setDefectTypes(defectTypes.slice());
            setSelectAll(false);
            setLastClearFilters(props.clearFilters);
        }

    }, [props.clearFilters, lastClearFilters, defectTypes]);

    useEffect(() => {
        
        if ( props.isDisplayed )
            getDefectTypesForCurrentCampaign();

    }, [props.isDisplayed]);

    useEffect(() => {
        getDefectTypesForCurrentCampaign();

    }, [props.campaignId]);


    const getDefectTypesForCurrentCampaign = useCallback(async () => {
        let url = process.env.REACT_APP_VAA_API_URL + "defectDetection/defectTypesInUseByCampaign/" + props.campaignId;

        fetchWithAuthorisationHeader(url).then(res => {

            let campaignDefectTypes = res.data as string[];

            campaignDefectTypes.push('<No defect type>');

            let selectedDefectTypes = defectTypes.filter(d => d.isSelected);
            let selectedDefectTypeNames = selectedDefectTypes.map(d=>d.name);

            let noLongerUsedSelectedDefectTypes = selectedDefectTypes.filter(d => !campaignDefectTypes.includes(d.name));

            let theDefectTypes: IFilterDefectType[] = []; 

            campaignDefectTypes.forEach(defectTypeName => {
                theDefectTypes.push({
                        name: defectTypeName,
                        isSelected: selectedDefectTypeNames.includes(defectTypeName),
                        isNoLongerUsed: false
                    })
                });

            noLongerUsedSelectedDefectTypes.forEach(d => {
                theDefectTypes.push({
                        name: d.name,
                        isSelected: true,
                        isNoLongerUsed: true
                    })
                });

            theDefectTypes.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0));

            setDefectTypes(theDefectTypes.slice());
            setFilteredDefectTypes(theDefectTypes.slice());
            syncSelectAll();
        })
        .catch(err => {
            console.log('Error: Failed to get ' + url + ' with status code ' + err.status);
        });
    },[props.campaignId,defectTypes]);

    function onSelectAll(selectAll: boolean) {
        
        filteredDefectTypes.forEach(u => u.isSelected = !selectAll);

        setDefectTypes(defectTypes.slice());
        let selectedDefectTypes = defectTypes.filter(u => u.isSelected).map(u => u.name);
        props.onFilterChanged(selectedDefectTypes);
        setSelectAll(!selectAll);
    }

    function onCheckboxChanged(user: IFilterDefectType) {
        user.isSelected = !user.isSelected;
        setDefectTypes(defectTypes.slice());
        let selectedDefectTypes = defectTypes.filter(u => u.isSelected).map(u => u.name);
        props.onFilterChanged(selectedDefectTypes);
        syncSelectAll();
    };
  
    function syncSelectAll(){
        let selectedUsers = defectTypes.filter(u => u.isSelected);
        if(!selectAll && selectedUsers.length == defectTypes.length && defectTypes.length > 0)
        {
            setSelectAll(true);
        } else if(selectAll && selectedUsers.length < defectTypes.length)
        {
            setSelectAll(false);
        }
    }
  
    function onSearchChange(value:string) {
        if(value=="")
        {
            syncSelectAll();
            setFilteredDefectTypes(defectTypes);
        } else 
        {
            setFilteredDefectTypes(defectTypes.filter(u => u.name.toLowerCase().includes(value.toLowerCase())));
        }
    };
  
    let defectTypeCheckboxes: JSX.Element[] = [];
    filteredDefectTypes.forEach(defectType => {
      defectTypeCheckboxes.push(
            <FormControlLabel
              key={defectType.name}
              classes={{ label: defectType.isSelected ? ( defectType.isNoLongerUsed ? controlClasses.labelSelectedNoLongerUsed : controlClasses.labelSelected) : controlClasses.label }}
              control={<Checkbox checked={defectType.isSelected} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500' }} />} onChange={() => onCheckboxChanged(defectType)} name={defectType.name} size={'small'} />}
              label={defectType.name}
            />
      );
    });

    let selectAllCheckbox = (
      <FormControlLabel
          key={"Select All"}
          classes={{ label: selectAll ? controlClasses.labelSelected : controlClasses.label }}
          control={<Checkbox style={{ color: '#F7B500', opacity: '0.5 !important' }} checked={selectAll} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500', opacity: '0.5 !important' }} />} onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
          label={"Select All"}
        />
    );
  
    return (
      <div className={styles.container}>
        <div className={styles.content}>
            <FormGroup>
                <TextField  style={{ color: '#F7B500' }} id="user-filter" label="Search" onChange={(event) => onSearchChange(event.target.value)} />
                {selectAllCheckbox}
                <div className={styles.users}>
                    {defectTypeCheckboxes}
                </div>
            </FormGroup>
        </div>
      </div>
    );
  }