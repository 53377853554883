import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

interface ILinkConfirmationSnackBarProps {
    imagesCount: number;
    isLinkConfirmationRequired: boolean;
    isLinkModeEnabled: boolean;
    onCancel: Function;
    onConfirm: Function;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    unlinkStyle: {
        top: 513,
        right: 76
    },
    linkStyle: {
        top: 454,
        right: 76
    }

}));

export function LinkConfirmationSnackBar(props: ILinkConfirmationSnackBarProps) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [message, setMessage] = React.useState("Confirm linking of images");
   
    const handleCancel = () => {
        setOpen(false);
        props.onCancel();
    };

    useEffect(() => {
        let operation = "linking";
        setOpen(props.isLinkConfirmationRequired);
        if (!props.isLinkModeEnabled) operation = "unlinking";
        setMessage("Confirm " + operation + " of " + props.imagesCount +" images?");
    }, [props.isLinkConfirmationRequired, props.isLinkModeEnabled]);

    return (
        <div >
            {props.isLinkConfirmationRequired &&
            <Snackbar
                classes={{ root: classes.root, anchorOriginTopRight: props.isLinkModeEnabled ? classes.linkStyle : classes.unlinkStyle }}
                open={open}
                message={message}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                action={
                    <React.Fragment>
                        <IconButton
                            onClick={handleCancel}
                            style={{ height: 17, width: 17, marginRight: 6 }}>
                            <ClearIcon style={{ fontSize: 20, color: 'rgb(255, 153, 153)' }} />
                        </IconButton>
                        <IconButton
                            onClick={() => props.onConfirm()}
                            style={{
                                height: 17, width: 17, marginLeft: 4}}>
                            <DoneIcon style={{
                                fontSize: 20, color: 'rgb(174, 213, 129)' }} />
                        </IconButton>
                    </React.Fragment>
                }
            />
            }
        </div>
    );
}
