import React, {useState, useEffect} from 'react';
import {IDefect} from '../../models/IDefect';
import {Grid, Tooltip, Box} from '@material-ui/core/';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';
import ComputerIcon from '@material-ui/icons/Computer';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import AssignmentIcon from '@material-ui/icons/Assignment'
import FavoriteIcon from '@material-ui/icons/Favorite';
import styles from './DefectListItem.module.css';
import completeForm from "../../images/complete_3x.png";
import draftForm from "../../images/draft_3x.png";
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { UserIcon } from "../UserIcon/UserIcon";
import { IUser } from '../../models/IUser';

import moment from 'moment';

interface IDefectListItemProps {
    organisationUsers:IUser[];
    defect:IDefect,
    onOperation:Function,
    canForm:Boolean,
    canEdit:Boolean,
    canRead:Boolean,
    onHover:Function,
    focusedDefect:IDefect | null
}

export interface IDefectOperationEvent {
    event:any,
    defect:IDefect,
    operation:DefectOperationState
}

export enum DefectOperationState {
    None,
    Create,
    Edit,
    Delete,
    FormAction,
    DeleteActionRequired
}

  const makeItemStyles = makeStyles((theme) => ({
    root: {
        flexWrap: 'nowrap',
        paddingTop:6,
        textAlign:'center',
        paddingLeft:8,
        paddingBottom:6,
        "&:hover": {
            backgroundColor: "rgba(0,0,0,0.2)"
        }
    },
}));

const deleteCancelHex = "#FF9999";
const deleteConfirmHex = "#AED581";


export const DefectListItem = (props:IDefectListItemProps) => {
    const [formStatus,setFormStatus] = useState("");
    const menuItemClasses = makeItemStyles();
    const [deleteStateIsActive, setDeleteStateIsActive] = useState(false);
    var deleteEvent:Event | null = null;
    const onDeleteEvent = (e:Event) => {
        if (props.defect.status.toUpperCase() === "CREATED") {
            onConfirmDelete(e);
        } else {
            props.onOperation({event:e, defect:props.defect, operation:DefectOperationState.DeleteActionRequired} as IDefectOperationEvent)
            setDeleteStateIsActive(true);
            deleteEvent = e;
        }
    }

    const onCancelDelete = () => {
        setDeleteStateIsActive(false);
        deleteEvent = null;
    }

    const onConfirmDelete = (e:Event) => {
        setDeleteStateIsActive(false);
        props.onOperation({event:deleteEvent, defect:props.defect, operation:DefectOperationState.Delete} as IDefectOperationEvent);

    }

    const formatUTCDateForLocal = (utcDate: Date) => {
        return moment.utc(utcDate,"DD-MM-YYYY hh:mm:ss a").local().format('DD/MM/YY HH:mm:ss');
    }

    useEffect(() => {
        if (props.focusedDefect !== props.defect) {
            onCancelDelete();
        }
    }, [props.focusedDefect]);

    useEffect(() => {
        setFormStatus(props.defect.status.toUpperCase());
    }, [props.defect]);

    let tooltipItems: JSX.Element[] = [];

    let createdUser = props.organisationUsers.find(u => u.emailAddress == props.defect.createdBy);
    let updatedUser = props.defect.updatedBy != null && props.defect.updatedBy.length > 0 ? props.organisationUsers.find(u => u.emailAddress == props.defect.updatedBy) : null;

    if ( createdUser != null )
        tooltipItems.push(<div>{'Created  ' + formatUTCDateForLocal(props.defect.dateCreated) + '  ' + createdUser.firstName + ' ' + createdUser.surname + '  ' + createdUser.emailAddress}</div>);
    else
        tooltipItems.push(<div>{'Created  ' + formatUTCDateForLocal(props.defect.dateCreated)}</div>);

    if (props.defect.dateUpdated != null && updatedUser != null ) 
        tooltipItems.push(<div>{'Updated ' + formatUTCDateForLocal(props.defect.dateUpdated) + '  ' + updatedUser.firstName + ' ' + updatedUser.surname + '  ' + updatedUser.emailAddress}</div>);
    else if (props.defect.dateUpdated != null) 
        tooltipItems.push(<div>{'Updated ' + formatUTCDateForLocal(props.defect.dateUpdated)}</div>);

    let tooltipText = <div key={props.defect.id}>{tooltipItems}</div>

    let firstName = '?';
    let surname = '';
    let emailAddress = '';
    let creator = '';

    if ( createdUser != null )
    {
        firstName = createdUser.firstName;
        surname = createdUser.surname;
        emailAddress = createdUser.emailAddress;
        creator = 'Created by ' + firstName + ' ' + surname + ' ' + createdUser.emailAddress;
    }

    return props.defect && (
        <Grid className={menuItemClasses.root} key={props.defect.id} container justify="space-between" direction="row" onMouseEnter={() => props.onHover(props.defect, "onMouseEnter")} onMouseLeave={() => props.onHover(props.defect,"onMouseLeave")}>
        {deleteStateIsActive ? 
        <>
            {/* delete confimation fragment */}
            <Grid item zeroMinWidth style={{paddingTop:4}} className={styles.defectName} >
            Are you sure?
                </Grid>
                <Grid item zeroMinWidth style={{ maxWidth:70, minWidth:53 }}>
                    <Grid container direction="row" style={{flexWrap: 'nowrap'}}>
                    <Grid item zeroMinWidth>
                    <IconButton
                        onClick={() => onCancelDelete()} 
                        style={{height:17, width:17}}>
                        <ClearIcon style={{ fontSize: 20, color: deleteCancelHex}}  />
                    </IconButton>
                </Grid>
                <Grid item zeroMinWidth>
                    <IconButton 
                        disabled={!props.canEdit} 
                        onClick={(e:any) => onConfirmDelete(e)} 
                        style={{height:17, width:17}}>
                        <CheckIcon style={{ fontSize: 20, color: deleteConfirmHex }} />
                    </IconButton>
                </Grid>
            </Grid>
            </Grid>
        </> : 
        <>
            {/* user CRUD tools fragment */}
            <Grid item zeroMinWidth style={{paddingTop:4}} className={styles.defectName} >
            <span className={styles.defectColourDot} style={{backgroundColor: props.defect.colour }}></span>
            {props.defect.name}
                </Grid>
                <Grid item zeroMinWidth style={{ maxWidth:120, minWidth:70}}>
                <Grid container direction="row" style={{flexWrap: 'nowrap'}}>
                <Grid item zeroMinWidth >
                <Tooltip title={'Enter details'}>
                    <IconButton 
                        disabled={!props.canForm } 
                        onClick={(e:any) => props.onOperation({event:e, defect:props.defect, operation:DefectOperationState.FormAction} as IDefectOperationEvent)} 
                        style={{height:17, width:17, paddingRight:4}}>
                        {props.defect.formData && formStatus != "CREATED" && formStatus != ""
                        ?  
                        <Box className={styles.formStatusBox} style={{height:18}} >
                            <img className={styles.formStatusImg} src={formStatus == "COMPLETED" ? completeForm : draftForm}></img>
                            <AssignmentIcon className={styles.formStatusFormIcon} style={{ fontSize:20, marginTop:2}}/>
                        </Box>
                        :
                        <AssignmentIcon  style={{ fontSize:20, marginRight:20}}/>}
                    </IconButton>
                </Tooltip>
                </Grid>
                <Grid item zeroMinWidth>
                <Tooltip title={'Edit defect selection'}>
                    <IconButton disabled={!props.canEdit} 
                        onClick={(e:any) => props.onOperation({event:e, defect:props.defect, operation:DefectOperationState.Edit} as IDefectOperationEvent)} 
                        style={{height:17, width:17}}>
                        <EditIcon style={{ fontSize: 20}}  />
                    </IconButton>
                </Tooltip>
                </Grid>
                <Grid item zeroMinWidth>
                <Tooltip title={'Delete defect'}>
                    <IconButton 
                        disabled={!props.canEdit} 
                        onClick={(e:any) => onDeleteEvent(e)} 
                        style={{height:17, width:17}}>
                        <DeleteIcon style={{ fontSize: 20 }} />
                    </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item zeroMinWidth>
                  <Box style={{width:30}} >
                      { props.defect.createdByMachineLearning &&
                         <Tooltip title={'Created by machine learning'}>
                            <ComputerIcon style={{ fontSize: 20,marginTop:2 }} />  
                         </Tooltip>
                      }
                      { !props.defect.createdByMachineLearning &&
                       <Box style={{marginTop:2}} >
                          <UserIcon marginLeft={5} marginRight={0} height={21} width={21} fontSize={10} emailAddress={emailAddress} firstName={firstName} surname={surname} tooltip={creator} />  
                       </Box>
                      }
                  </Box>
               </Grid>
                <Grid item zeroMinWidth>
                   <Tooltip title={tooltipText}>
                     <InfoIcon style={{ fontSize: 20,marginRight:5,marginTop:2 }} />
                   </Tooltip>
               </Grid>
            </Grid>
        </Grid>
        </>
        }
    </Grid>
    )
}
