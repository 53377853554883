import React from 'react';

interface IProps {
    url: string | null;
}

export function UrlViewer(props: IProps) {
      return <div id={'urlviewer'} style={{height:'100%',width:'100%', background:'black',overflow: 'hidden !important'}} >   
        { props.url != null &&
            <iframe title="VIEWER" id="urlvieweriframe" style={{height:'100%',width:'100%',backgroundColor:'black',overflow: 'hidden !important'}} frameBorder={0} src={ props.url }></iframe>
        }
      </div>
}