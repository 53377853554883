import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import styles from './ImageAssignMenu.module.css'
import { FormGroup } from '@material-ui/core';
import { IUser } from '../../models/IUser';
import { Tooltip} from '@material-ui/core/';
import TextField from '@material-ui/core/TextField';
import { UserIcon } from "../UserIcon/UserIcon";

const makeMenuStyles = makeStyles((theme) => ({
  list: {
    padding:'0px',
  }
}));

interface IImageAssignMenuProps {
    organisationActiveUsers: IUser[];
    anchorImageAssignMenuEl: any;
    selectedImageIds:number[];
    onMenuClosed: Function;
    onAssignUser: Function;
    onCancel: Function;
}

export function ImageAssignMenu(props: IImageAssignMenuProps) {
    const menuClasses = makeMenuStyles();

    let emptyJsxArr:JSX.Element[] = [];
    let emptyUsers:IUser[] = [];
    const [clickedUser, setClickedUser] = useState({});
    const [confirmationMessages, setConfirmationMessages] = useState(emptyJsxArr);
    const [filteredOrganisationActiveUsers,setFilteredOrganisationActiveUsers] = useState(emptyUsers);

    const changeCountConfirmation = 15;

    const handleCancel = (event:any) => {
      if (event.keyCode === 27){
	        props.onCancel();}
    };

   useEffect(() => {

       setFilteredOrganisationActiveUsers(props.organisationActiveUsers);

   }, [props.organisationActiveUsers]);

    function onAssignUser(user:IUser){
                
        setClickedUser(user);

        let messages:JSX.Element[] = [];
        
        if (props.selectedImageIds.length >= changeCountConfirmation) {
            messages.push(<div>You are assigning {props.selectedImageIds.length} images to {user.firstName} {user.surname}</div>);
        }
        else if (messages.length === 0) {
            props.onAssignUser(user);
        }
        setConfirmationMessages(messages);
    }

    function onUserFilterChange(value:string) {
        if(value=="")
        {
            setFilteredOrganisationActiveUsers(props.organisationActiveUsers);
        } else 
        {
            let filteredUsers:IUser[] = [];
            props.organisationActiveUsers.forEach( (user:IUser) => {
                let userName = user.firstName + " " + user.surname;
                if ( userName.toLowerCase().includes(value.toLowerCase()))
                    filteredUsers.push(user);

            });

            setFilteredOrganisationActiveUsers(filteredUsers);
        }
    };

    let idx = 1;
    let userMenuItems: JSX.Element[] = [];
    
    filteredOrganisationActiveUsers.forEach( (user:IUser) => {
        userMenuItems.push(
                <MenuItem
                    dense
                    key={++idx}
                    className={styles.imageAssignMenuItem}
                    onClick={(e: any) => onAssignUser(user)}
                >
                    <Tooltip title={user.emailAddress}>
                      <div style={{ display: 'flex' }}><UserIcon marginLeft={0} marginRight={10} height={21} width={21} fontSize={10} emailAddress={user.emailAddress} firstName={user.firstName} surname={user.surname} tooltip={null} /><span style={{fontSize:16,marginTop:0,marginLeft:5}}>{user.firstName}&nbsp;{user.surname}</span></div>
                    </Tooltip>
                </MenuItem>                                
            );
          });

    return (
        <div style={{ display: props.anchorImageAssignMenuEl != null ? 'block' : 'none' }}>
    <Menu
        classes={menuClasses}
        anchorEl={props.anchorImageAssignMenuEl}
        style={{ left: -60, top: -30 }}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        elevation={1}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(props.anchorImageAssignMenuEl)}
        onClose={(e: any) => { props.onMenuClosed(); handleCancel(e) }}
    >
        <div className={styles.menuContainer} style={{ height: 472 }} >
            <div className={styles.menuContents} >
                <div className={styles.imageAssignMenuHeader}>&#0913;ssign image</div>
                <TextField key={0} style={{ color: '#F7B500',marginLeft:15 }} label="Search" onChange={(event) => onUserFilterChange(event.target.value)} />
                 <div className={styles.users}>
                    {userMenuItems}
                 </div>
            </div>
            <div className={styles.confirmationMessage} style={{ display: confirmationMessages.length > 0 ? 'block' : 'none' }} >
                <Box display="flex" flexDirection="column" >
                    <div className={styles.confirmationMessageHeader}>Confirm the following as you will not be able to undo the action:</div>
                    <form>
                        <FormGroup>
                            <FormControl>
                                <Box>
                                    {confirmationMessages.map((msg, index) => (
                                        <span>{msg}</span>
                                    ))}
                                </Box>
                            </FormControl>
                            <FormControl>
                                <Box display="flex" justifyContent="flex-end">
                                    <IconButton
                                        
                                        onClick={(e: any) => {
                                            props.onMenuClosed();
                                        }}
                                        style={{ height: 17, width: 17}}>
                                        <ClearIcon style={{ fontSize: 20,  color:'#FF9999'  }} />
                                    </IconButton>
                                    <IconButton 
                                        type='submit'
                                        onClick={(e: any) => {
                                            props.onAssignUser(clickedUser);
                                        }}
                                        style={{  height: 17, width: 17}}>
                                        <DoneIcon 
                                            style={{ fontSize: 20, color:"#AED581" }} />
                                    </IconButton>
                                </Box>
                            </FormControl>
                        </FormGroup>
                    </form>
                </Box>
            </div>
        </div>
    </Menu>
</div>
    );
}
