import { createMuiTheme } from '@material-ui/core/styles'

export const vaaTheme = createMuiTheme({
  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff"
    },
    "text": {
        "primary": "#F9FCFF"
    },
    "background": {
      "paper": "#292A2B",
      "default": "#fafafa"
    },
    "primary": {
      "light": "#95C5E7",
      "main": "#95C5E7",
      "dark": "#95C5E7",
      "contrastText": "#fff"
    },
    "secondary": {
      "light": "rgba(89, 210, 188, 1)",
      "main": "#F9FCFF",
      "dark": "rgba(0, 113, 95, 1)",
      "contrastText": "#fff"
    },  
  },
  "typography": {
    "fontFamily": "\"Roboto\", \"Poppins\",\"Helvetica\", \"Arial\", sans-serif",
    "fontSize": 16,
    "h4": {
          "fontSize": 16,
          "fontWeight": 100,
          "fontFamily": "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
          "letterSpacing": "-.04em",
          "lineHeight": 22.5,
          "marginLeft": "-.04em",
          "color": "rgba(255, 0, 0, 0.54)"
        },
    },
  
});
export default vaaTheme;