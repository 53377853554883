import React, { useState, useEffect, createRef, useRef } from 'react';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { Tooltip, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import styles from './AssetSearchLayerFilter.module.css';
import TextField from '@material-ui/core/TextField';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import { IMapLayer } from '../../../../models/IMapLayer';

const makeControlStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14
  },
  labelSelected: {
    fontSize: 14,
    color: '#F7B500'
  }
}));


interface IAssetSearchLayerFilterProps {
  onFilterChanged: Function;
  clearFilters: number;
  shapefileLayers: IMapLayer[];
}

interface IAssetSearchMapLayer extends IMapLayer {
  isSelected?: boolean;
}

export function AssetSearchLayerFilter(props: IAssetSearchLayerFilterProps) {

    const controlClasses = makeControlStyles();
    const initialMapLayers: IAssetSearchMapLayer[] = [];
    const [mapLayers, setMapLayers] = useState(initialMapLayers);
    const [filteredMapLayers, setFilteredMapLayers] = useState(initialMapLayers);
    const [lastClearFilters, setLastClearFilters] = useState(0);
    const [selectAll, setSelectAll] = useState(false);
    const [searchAssets, setSearchAssets] = useState(true);
    let newSearchAssets = true;

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => { 

        if (props.clearFilters != lastClearFilters) {
          mapLayers.forEach(s => s.isSelected = false);
          setMapLayers(mapLayers.slice());
          setSelectAll(false);
          setLastClearFilters(props.clearFilters);
        }

    }, [props.clearFilters, lastClearFilters, mapLayers]);

    function compareLayers(a:IMapLayer, b:IMapLayer ) {
        if ( a.label < b.label ){
            return -1;
        }
        else if ( a.label > b.label ){
            return 1;
        }

        return 0;
    }

    useEffect(() => {

        let layers = [...props.shapefileLayers] as IAssetSearchMapLayer[];

        //sort by name
        layers.sort(compareLayers);

        let oldSelectedCount = layers.filter(l=> l.isSelected).length;

        layers.forEach(l=> {
            if (l.isSelected == null || (l.isSelected && !l.isActive)) {
                l.isSelected = false  
            } 
         });

        setMapLayers(layers);
        setFilteredMapLayers(layers);
        
        if (oldSelectedCount > layers.filter(l=> l.isSelected).length) {

            let selectedMapLayers = layers.filter(s => s.isSelected).map(l=>l.fileId);
            props.onFilterChanged(selectedMapLayers,searchAssets);
        }

    }, [props.shapefileLayers, searchAssets]);

    function onSelectAll(selectAll: boolean) {

      filteredMapLayers.filter(l=>l.isActive).forEach(s => s.isSelected = !selectAll);
    
      setSearchAssets(!selectAll);
      

      setMapLayers(mapLayers.slice());
      let selectedMapLayers = mapLayers.filter(s => s.isSelected).map(l=>l.fileId);
      props.onFilterChanged(selectedMapLayers,!selectAll);
      setSelectAll(!selectAll);
    }

    function onAssetsCheckboxChanged() {
        newSearchAssets = !searchAssets;
        setSearchAssets(newSearchAssets);
        let selectedMapLayers = mapLayers.filter(s => s.isSelected).map(l=>l.fileId);
        props.onFilterChanged(selectedMapLayers,newSearchAssets);
        syncSelectAll();
    }

    function onMapLayerCheckboxChanged(mapLayer: IAssetSearchMapLayer) {
        mapLayer.isSelected = !mapLayer.isSelected;
        setMapLayers([...mapLayers]);
        let selectedMapLayers = mapLayers.filter(s => s.isSelected).map(l=>l.fileId);
        props.onFilterChanged(selectedMapLayers,searchAssets);
        syncSelectAll();
    };

    function syncSelectAll(){
        let selectedMapLayers = mapLayers.filter(s => s.isSelected);
        if(!selectAll && selectedMapLayers.length == mapLayers.length && newSearchAssets )
        {
            setSelectAll(true);
        } else if(selectAll && selectedMapLayers.length < mapLayers.length )
        {
            setSelectAll(false);
        }
        else if (!newSearchAssets)
        {
          setSelectAll(false);
        }
    }

  function onMapLayerChange(value:string) {
    if(value=="")
    {
      syncSelectAll();
      setFilteredMapLayers(mapLayers);
    } else 
    {
      setFilteredMapLayers(mapLayers.filter(s => s.label.toLowerCase().includes(value.toLowerCase())));
    }
  };

  let assetCheckbox = <FormControlLabel
                        style={{marginLeft:0}}
                        classes={{ label: searchAssets? controlClasses.labelSelected : controlClasses.label }}
                        control={<Checkbox checked={searchAssets} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500' }} />} onChange={() => onAssetsCheckboxChanged()} name={'Assets'} size={'small'} />}
                        label={'Assets'}
                      />

  let mapLayerCheckboxes: JSX.Element[] = [];
  filteredMapLayers.forEach(mapLayer => {
    mapLayerCheckboxes.push(
       
          <FormControlLabel
            disabled={!mapLayer.isActive}
            style={{marginLeft:0}}
            key={mapLayer.label}
            classes={{ label: mapLayer.isSelected ? controlClasses.labelSelected : controlClasses.label }}
            control={<Checkbox checked={mapLayer.isSelected} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500' }} />} onChange={() => onMapLayerCheckboxChanged(mapLayer)} name={mapLayer.label} size={'small'} />}
            label={
                    <Box display="flex" flexDirection="row">
                        <Box>
                            <img src={mapLayer.thumbnail} width="16px" style={{marginRight:10,marginTop:7}} />
                        </Box>
                        <Box style={{marginTop:5}}>
                            <Tooltip title={ (!mapLayer.isActive ? 'layer is turned off' : '')} >
                                <span>{mapLayer.label}</span>
                            </Tooltip>
                        </Box>
                    </Box>
            }
          />
    );
  });

  let selectAllCheckbox = (
    <FormControlLabel
        style={{marginLeft:0}}
        key={"Select All"}
        classes={{ label: selectAll ? controlClasses.labelSelected : controlClasses.label }}
        control={<Checkbox style={{ color: '#F7B500', opacity: '0.5 !important' }} checked={selectAll} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500', opacity: '0.5 !important' }} />} onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
        label={"Select All"}
      />
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FormGroup>
          <TextField style={{ color: '#F7B500' }} id="maplayer-filter" label="Search" onChange={(event) => onMapLayerChange(event.target.value)} />
          { selectAllCheckbox}
           <div className={styles.subHeadingText}>
               Assets
           </div>
          {assetCheckbox}
          { mapLayerCheckboxes.length > 0 &&
           <div className={styles.subHeadingText}>
               Network shapefiles
           </div>}
          {mapLayerCheckboxes}
        </FormGroup>
      </div>
    </div>
  );
}
