import React from 'react';
import { Box } from '@material-ui/core/';
import Grid from '@material-ui/core/Grid';
import { Popover } from '@material-ui/core';
import { IMapPickerConfig } from '../../models/IMapPickerConfig';
import PhotoCameraIcon from "../../images/photo_camera_24px.png";
import CheckCircle from '../../images/check_circle_24px.png';
import CancelCircle from '../../images/cancel_24px.png';
import inactiveAssetCircle from '../../images/check_circle_inactivelink_24px.png';
import PoleIcon from "../../images/custom_pole.png";
import styles from "./MapPicker.module.css";

interface IMapPickerProps {
    config: IMapPickerConfig | null;
    onImageSelected: Function;
    onSegmentSelected: Function;
    onShapefileDataSelected: Function;
    onClose: Function;
}

export function MapPicker(props: IMapPickerProps) {


    function getStatusIcon(poleId:string, active:boolean){

        if(poleId && active)
        {
          return CheckCircle;
        }
        else if(poleId && !active)
        {
          return inactiveAssetCircle;
        }
        else
        {
          return CancelCircle;
        }
    
      }

        
    if (props.config == null || (props.config.images.length + props.config.segments.length +  props.config.shapefileData.length) == 0 || props.config.screenX == null || props.config.screenY == null)
        return null;

    let images: JSX.Element[] = [];

    props.config.images.forEach(image => {
        images.push(
           <Grid key={image.id} container justify="flex-start" className={styles.objectContainer} onClick={ () => props.onImageSelected(image) } >
                <Grid item style={{width: 37,marginLeft:3}}>
                    <img src ={getStatusIcon(image.poleId,image.active)} width="30px"/>
                  
                     
                </Grid>
                <Grid item >
                    <Box className={styles.bigProp}>{image.pack || "---"}</Box>
                    <Box className={styles.sessionLabel}>
                        {"Session " + image.sessionName || "---"}
                        </Box>     
                        <Box>
                        {image.created &&
                            <span>                                    
                            <img
                                src={PhotoCameraIcon}
                                style={{ width: 15,paddingTop: 3 }}
                            />  
                            <span style={{marginLeft:3,paddingTop:10}}>   
                                {image.created.toString().split(" ")[0] || "---"}
                            </span>   
                            </span>  
                        }
                    </Box>
                </Grid>
            </Grid>     
            );
    });

    let segments: JSX.Element[] = [];
    props.config.segments.forEach(segment => {
        segments.push(
            <Grid key={segment.id} container justify="flex-start" className={styles.objectContainer} onClick={() => props.onSegmentSelected(segment, props?.config?.screenX,props?.config?.screenY)} >
                <Grid item style={{ width: 37, marginLeft: 3 }}>
                    <img src={PoleIcon} width="30px" />
                </Grid>
                <Grid item >
                    <Box className={styles.bigProp} style={{paddingTop: 6}}>{segment.externalReferenceId}</Box>
                </Grid>
            </Grid>
        );
    });

    let shapefileDataList: JSX.Element[] = [];    
    props.config.shapefileData.forEach(shapefileData => {
        shapefileDataList.push(
            <Grid key={shapefileData.id} container justify="flex-start" className={styles.objectContainer} onClick={() => props.onShapefileDataSelected(shapefileData, props?.config?.screenX,props?.config?.screenY)} >
                <Grid item style={{ width: 37, marginLeft: 3 }}>
                    <img src={`../../../images/MapLayerIcons/${shapefileData.icon != '[icon]' ? shapefileData.icon : shapefileData.lineType}${shapefileData.displayColor?"_" + shapefileData.displayColor:""}.png`} width="30px" />
                </Grid>
                <Grid item >
                    <Box className={styles.bigProp} style={{paddingTop: 6}}>{shapefileData.shapefileName}</Box>
                </Grid>
            </Grid>
        )
    });
     
    return <Popover
            open={true}
            onClose={ () => props.onClose()}
            anchorPosition={{left: props.config.screenX - 6,top: props.config.screenY + 10}}
            anchorReference={'anchorPosition'}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
           >    
              <div className={styles.container}>
                <div className={styles.content}>
                   <div className={styles.objectsContainer}>
                    {images}
                    {segments}
                    {shapefileDataList}
                   </div>
                </div>
              </div>
           </Popover>          
          ;
}