import React, { useEffect, useState, useRef,useCallback } from 'react';
import Form, { withTheme } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import { Theme as MaterialUITheme } from '@rjsf/material-ui';
import { fetchWithAuthorisationHeader, postWithAuthorisationHeader } from "../../services/AuthenticationService";
import { IFormDetails } from '../../models/IFormDetails';
import { Grid, Tooltip } from '@material-ui/core';
import { IDefect } from '../../models/IDefect';
import { IDefectFavourite} from '../../models/IDefectFavourite';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FavoriteIcon from '@material-ui/icons/Favorite';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import FormHelperText from '@material-ui/core/FormHelperText'

import styles from "./DefectForm.module.css";

interface IDefectFormProps {
    form: IFormDetails | null;
    defect: IDefect;  
    onDefectFormEnd: Function;
    onFormIsOpened:Function;
    readOnly: Boolean;
    formList: IFormDetails[];
    defectFavouritesList: IDefectFavourite[];
 }

const makeButtonStyles = makeStyles((theme) => ({
    rootComplete: {
        fontSize: 14,
        backgroundColor: "#95C5E7",

        "&:hover": {
            background: 'linear-gradient(0deg, rgba(249, 252, 255, 0.08), rgba(249, 252, 255, 0.08)), #95C5E7'
        },
    },
    labelComplete: {
        "&:hover": {
            color: '#1E1E20',
        },
    },
    rootSave: {
        fontSize: 14,
        border: "1px solid rgba(249, 252, 255, 0.11)",

        "&:hover": {
            background: 'linear-gradient(0deg, rgba(249, 252, 255, 0.08), rgba(249, 252, 255, 0.08)), #95C5E7'
        },
    },
    labelSave: {
        color: '#95C5E7',
        fontSize: 14,
        "&:hover": {
            color: '#1E1E20',
        },
    },
    labelSaveDisabled: {
        color: '#949698',
        fontSize: 14,
        "&:hover": {
            color: '#949698',
        },
    },
    buttonHidden: {
        display: "none"
    },
}));

export function DefectForm(props: IDefectFormProps) {

    const [editFormType, setEditFormType] = React.useState(false);

    const TitleFieldWithHiddenEditIcon = (obj:any) => {
        return <div>{obj.title}</div>;
    };

    const TitleFieldWithEditIcon = (obj:any) => {
      const legend = obj.required ? obj.title + '*' : obj.title;
      return <div>
                <div>{legend}&nbsp;&nbsp;&nbsp;
                   <Tooltip title={'Click to change the parent form type'}>
                    <IconButton 
                        onClick={ () => {   
                            setEditFormType(!editFormType);
                            setShowFormTypeList(true);  
                        }}
                        style={{height:17, width:17, paddingRight:16}}>
                        <EditIcon style={{ fontSize:20, marginRight:20}}/>
                    </IconButton>
                   </Tooltip> 
                </div>
            </div>;
    };

    const [jsonSchema, setJsonSchema] = React.useState({});
    const [uiSchema, setUiSchema] = React.useState({});
    const [formData, setFormData] = React.useState({});
    const Form = withTheme(MaterialUITheme);
    const buttonClasses = makeButtonStyles();
    const [isFormValid, setIsFormValid] = useState( true );
    const [initialErrorsChecked, setInitialErrorsChecked] = useState( false );
    const prevErrorCount = useRef(-1);
    const prevFormData = useRef();
    const [showFormTypeList,setShowFormTypeList] = useState( true );
    const [formTypelistInit,setFormTypelistInit] = useState( false );
    const [isLoadingDefect,setIsLoadingDefect] = useState( false );

    useEffect(() => {
        if (props.form && props.form.jsonSchema && props.form.uiSchema) {
            setUiSchema(JSON.parse(props.form.uiSchema));
            setJsonSchema(JSON.parse(props.form.jsonSchema));
        }
        else {
            setUiSchema({});
            setJsonSchema({});
        }

        if (props.form && props.form.formData){
            setFormData(JSON.parse(props.form.formData));
            prevFormData.current = JSON.parse(props.form.formData);
        }
        else {
            setFormData({});
        }
        props.onFormIsOpened(true);
    }, [props.form]);

    const onChange = (e: any) => {   
        
        if ( isLoadingDefect )
            return;

        prevFormData.current = e.formData;
        // If prevError = -1, initialise to current error Count
        // On change of prevError, setState
        if (prevErrorCount.current < 0) {
            prevErrorCount.current = e.errors.length;
        }
        else {
            if (prevErrorCount.current == 0 && e.errors.length > 0) { 
                setIsFormValid(false); 
                setFormData(e.formData);  
            }
            
            if (prevErrorCount.current > 0 && e.errors.length == 0) { 
                setIsFormValid(true); 
                setFormData(e.formData); 
            }
        }

        //update previous
        prevErrorCount.current = e.errors.length;  
    }

    const transformErrors = (errors: any) => {

        if ( isLoadingDefect )
            return [];

        if (!initialErrorsChecked) {
            setInitialErrorsChecked(true);
            setIsFormValid(true);
            if (errors.length > 0) {
                setIsFormValid(false);                 
            }
            prevErrorCount.current = errors.length;
        }

        let changedErrors = errors.map((error: any) => {
            if (error.name === "required") {
                error.message = "This field is mandatory"
            }
            return error;
        });

        // Remove duplicate per property type. .eg.g only one 'enum'  and one 'oneOf' per property type
        let finalErrors: any[] = [];
        let doneEnumProperty: any[] = [];
        let doneOneOfProperty: any[] = [];

        changedErrors.forEach((error: any) => {
            if (error.name == "enum") {
                if (!doneEnumProperty.includes(error.property)) {
                    finalErrors.push(error);
                    doneEnumProperty.push(error.property);
                }
            }
            else if (error.name == "oneOf") {
                if (!doneOneOfProperty.includes(error.property)) {
                    finalErrors.push(error);
                    doneOneOfProperty.push(error.property);
                }
            }
            else {
                finalErrors.push(error);
            }
        });
        return finalErrors;
    }

    const onSaveAsDraft = () => {
        let data = {
            id: props.defect.id,
            status: "Draft",
            formData: JSON.stringify(prevFormData.current)
        };

        saveFormData(data);
        props.onFormIsOpened(false);
    }

    const onSubmit = (e: any) => {
        let data = {
            id: props.defect.id,
            status: "Completed",
            formData: JSON.stringify(e.formData)
        };

        saveFormData(data);
        props.onFormIsOpened(false);
    }

    const saveFormData = (data: any) =>{
        let postUrl = process.env.REACT_APP_VAA_API_URL + "form/saveFormData/";

        postWithAuthorisationHeader(postUrl, data).then(res => {

            let updatedDefect = res.data as IDefect;
            props.defect.dateUpdated = updatedDefect.dateUpdated;
            props.defect.updatedBy = updatedDefect.updatedBy;

            props.onDefectFormEnd(data);
        })
        .catch(err => {
            console.log('Error: Failed to save data with  ' + postUrl + ' with status code ' + err.status);
            props.onDefectFormEnd(data);
        });
    }

    const fetchDefect = useCallback(async (defectId:number) => {
        let fetchDefectUrl = process.env.REACT_APP_VAA_API_URL + "form/fetchFormDetailsByDefectId/" + defectId;

        fetchWithAuthorisationHeader(fetchDefectUrl).then(res => {

            let form = res.data;
            
            setIsFormValid(true);
            prevErrorCount.current = -1;
            setInitialErrorsChecked(false);

            setUiSchema(JSON.parse(form.uiSchema));
            setJsonSchema(JSON.parse(form.jsonSchema));

            let formData = form.formData ? JSON.parse(form.formData) : {};

            setFormData(formData);            
            prevFormData.current = formData;

            setIsLoadingDefect(false);
        })
        .catch(err => {
            console.log('Error: Failed to get ' + fetchDefectUrl + ' with status code ' + err.status);
            setIsLoadingDefect(false);
        });
    },[]);


    const onFormTypeClicked = (formId: number) => { 
        changeFormType(props.defect, formId, '');
    }
       
    const onDefectFavouriteClicked = (defectFavouriteId: number) => { 

       let selectedFavourite = props.defectFavouritesList.find(x => x.defectFavouriteId == defectFavouriteId);
       
        if ( selectedFavourite == null )
            return;       

       changeFormType(props.defect, selectedFavourite.formId, selectedFavourite.formData);
    }

    const changeFormType = useCallback(async (defect:IDefect, formId:number, formData:string) => {

        let form = props.formList.find(f=>f.id === formId);
        if ( form == null || defect == null || defect.id == null )
            return;

        setIsLoadingDefect(true);

        setEditFormType(false);

        let defectId = defect.id;

        defect.name = form.name;
        defect.status = "Draft";
        defect.formId = form.id;
        defect.formData = formData;
        defect.dateUpdated = new Date();

        setJsonSchema({});

        // Update the defects form type, update the defect, then refresh the defect

        fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "form/saveFormType" + "/" + defectId + "/" + formId + "/" + defect.status )
        .then(res => {
            return postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "form/updateDefect", defect);

        }).then(res => {
         
            let updatedDefect = res.data as IDefect;
            defect.dateUpdated = updatedDefect.dateUpdated;

            return fetchDefect(defectId);
        })
        .catch(err => {
            console.log('Error: Failed to changeFormType with status code ' + err.status);
            return fetchDefect(defectId);
        });

   },[]);

    const onFormTypeListScroll = (event:any) => {
        // Had a weird issue when the list was shown it would show the last item in the list
        // Workaround here is to trap the first scroll event and set the scroll position to the top of the list
        if ( !formTypelistInit )
        {
            event.target.scrollTop = 0;
            setFormTypelistInit(true);
        }
    }
      
    let selectListItems: JSX.Element[] = [];
    let idx = 0;
    for (var i = 0; i < props.defectFavouritesList.length; i++) {
        let defectFavouriteId = props.defectFavouritesList[i].defectFavouriteId;
        selectListItems.push( 
           <MenuItem dense
              key={++idx}
              tabIndex={0}
              onClick={(e) => onDefectFavouriteClicked(defectFavouriteId)}>
              <div style={{ overflow: "hidden", textOverflow: "ellipsis",width:400,color:'#FFF59D'}}>
                {props.defectFavouritesList[i].name}
              </div>
              <FavoriteIcon style={{ fontSize:20, marginRight:5,color:'#FFF59D'}}/>
            </MenuItem>)
    }

    for (var i = 0; i < props.formList.length; i++) {
        let formId = props.formList[i].id;
        selectListItems.push( 
           <MenuItem dense
              key={++idx}
              tabIndex={0}
              onClick={(e) => onFormTypeClicked(formId)}>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    {props.formList[i].name}
                </div>
            </MenuItem>)
    }

    return (
     <div>

        <Dialog
            
            disableBackdropClick
            disableEscapeKeyDown
            fullWidth
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
                <DialogContent>
                    {!props.readOnly && (Object.keys(jsonSchema).length === 0 || editFormType ) &&
                        <div style={{marginBottom: editFormType ? 10 : 20 }}>
                        <Grid container justify="space-between" direction="row" >
                            <Grid item zeroMinWidth >
                                { editFormType &&
                                    <FormHelperText style={{color:'#ff9999',padding:0,margin:0}}>Changing the form type will delete previously entered data</FormHelperText>
                                }
                                <FormControl 
                                  component='span'
                                  style={{width: 395}}
                                  disabled={props.defect.formId > 0 && !editFormType}
                                  >
                        
                                  <InputLabel>Select form type</InputLabel>
                                  <Select
                                      open={showFormTypeList}                                                    
                                      onOpen={ () => { 
                                                       setShowFormTypeList(true); 
                                                       setFormTypelistInit(false);
                                                      }}
                                      onClose={ () => {
                                          setFormTypelistInit(false);
                                          if (editFormType){
                                            setEditFormType(!editFormType);
                                            setShowFormTypeList(true);                                             
                                          } 
                                          else {
                                           setShowFormTypeList(false);
                                         }
                                      }}
                                      MenuProps={{
                                                 PaperProps: {
                                                      onScroll:onFormTypeListScroll
                                                    },
                                                  anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                  },
                                                  getContentAnchorEl: null,
                                                }}

                                    label="Select form type"
                                  >
                                  {selectListItems}
                                  </Select>
                               
                                 </FormControl>
                            </Grid>
                          </Grid>
                        </div>
                    }
                    <Form
                        fields={{ TitleField: props.readOnly ? TitleFieldWithHiddenEditIcon : TitleFieldWithEditIcon }} 
                        className={styles.defectForm}
                        schema={jsonSchema}
                        uiSchema={uiSchema}
                        formData={formData}
                        onSubmit={onSubmit}
                        onChange={onChange}
                        showErrorList={false}
                        disabled={Boolean(props.readOnly)}
                        transformErrors={transformErrors}
                        liveValidate={!Boolean(props.readOnly)}
                        omitExtraData
                        liveOmit
                    >
                   {Object.keys(jsonSchema).length === 0 && <div style={{height:300}}></div> }
                    <Grid container justify="space-between" style={{marginTop:'30px', marginBottom:'10px'}}>
                        <Grid item >
                                <Button
                                    variant="outlined"
                                    disabled={props.defect.formId === 0 || Object.keys(jsonSchema).length === 0 }
                                    style={{ marginRight: 15 }}
                                    classes={{ root: Boolean(props.readOnly) ? buttonClasses.buttonHidden : buttonClasses.rootSave, label: (props.defect.formId === 0 || Object.keys(jsonSchema).length === 0) ? buttonClasses.labelSaveDisabled : buttonClasses.labelSave }}
                                    onClick={() =>  onSaveAsDraft() }>
                                    SAVE DRAFT
                                </Button>
                                </Grid>
                                <Grid item>
                                <Link
                                    style={{ fontSize: 14, marginRight: 15 }}
                                    onClick={() => { props.onFormIsOpened(false); props.onDefectFormEnd();  } }>
                                    CANCEL
                                </Link>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={!isFormValid || Boolean(props.readOnly) || props.defect.formId === 0 || Object.keys(jsonSchema).length === 0 }
                                    classes={{ root: Boolean(props.readOnly)? buttonClasses.buttonHidden : buttonClasses.rootComplete, label: buttonClasses.labelComplete  }}>
                                    COMPLETE
                                </Button>
                       </Grid>
                    </Grid>               
                </Form>


            </DialogContent>
        </Dialog>
        </div>
     );
}