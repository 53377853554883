import React, { useState, useCallback, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IMapLayerControlProps } from '../../models/IMapLayerControlProps';
import {MapLayerControl} from '../MapLayerControl/MapLayerControl';
import Box from '@material-ui/core/Box';
import {IMapLayer} from '../../models/IMapLayer';
import {ICoordinate} from '../../models/ICoordinate';

const makeMenuItemStyles = makeStyles((theme) => ({
    root: {
        fontSize:14,
        "&:hover": {
            color: '#93BAF5',
            backgroundColor: "rgba(147, 186, 245, 0.08)"
        }
    },
    selected: {
        color: '#93BAF5',
        backgroundColor: "rgba(147, 186, 245, 0.08)"
    },
})); 

interface ILayerListProps {
    mapLayers:IMapLayer[][],
    onToggleVisibility:Function
}

export const LayerList = (props:ILayerListProps) => {

    const [layersJsx, setLayersJsx] = useState(new Array<JSX.Element>());

    useEffect(() => {
        let tempJsx:JSX.Element[] = [];
        
        for (let i = 0; i < props.mapLayers.length; i++) {
            for (let j = 0; j < props.mapLayers[i].length; j++) {
                if (!props.mapLayers[i][j].hideFromMenu) {
                    tempJsx.push(<div key={i}>
                        <MapLayerControl
                            group              =  {props.mapLayers[i][j].group}
                            label              =  {props.mapLayers[i][j].label}
                            isActive           =  {props.mapLayers[i][j].isActive}
                            isAvailable        =  {props.mapLayers[i][j].isAvailable}
                            thumbnail          =  {props.mapLayers[i][j].thumbnail}
                            onVisibilityToggle =  {(group:string, label:string, checked:boolean) => {
                                //bubble to vaacontainer
                                props.onToggleVisibility(group, label, checked)}
                            }
                        />   
                    </div>)
                }
            }
        }
        setLayersJsx(tempJsx);

    },[props.mapLayers]);

    

    return (
        // just a spacer
        <Box height={'100%'} style={{overflowY:'auto', overflowX: 'hidden'}}>
            <div style={{height:'0px',  marginTop:10}}>
                {layersJsx}
            </div>
        </Box>
    )
}