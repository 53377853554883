import { useEffect, useState } from 'react';
import styles from './ImageDefectIndicator.module.css';
import { IImageDefectCount } from '../../../models/IImageDefectCount';

interface ImageDefectIndicatorProps {
  imageId: number; //image id.
  imageDefectCount: number; //count from API
  imageDefectCountLatest: number | undefined; //count of defects available in front end.
  canViewDefects: boolean; //user has permission to view defects.
  left: number; //space from left in pixels.
  top: number; //space from top in pixels.
  height: number; //height of the defect indicator in pixels.
}

const ImageDefectIndicator = (props: ImageDefectIndicatorProps) => {
  const [showIndicator, setShowIndicator] = useState(false);

  useEffect(() => {
    if (props.canViewDefects) {
      if (props.imageDefectCountLatest && props.imageDefectCountLatest > 0) {
        setShowIndicator(true);
      } else if (props.imageDefectCountLatest === undefined && props.imageDefectCount > 0) {
        setShowIndicator(true);
      } else {
        setShowIndicator(false);
      }
    }
  }, [props.imageDefectCount, props.imageDefectCountLatest, props.canViewDefects]);

  return showIndicator ? (
    <div key={props.imageId} style={{ marginLeft: props.left + 'px', marginTop: props.top + 'px', height: props.height + 'px' }} className={styles.defect}></div>
  ) : (
    <></>
  );
};

export default ImageDefectIndicator;
