import React, { useState, useCallback, useEffect, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import IconButton from '@material-ui/core/IconButton';

import { postWithAuthorisationHeader } from "../../../services/AuthenticationService";

import useComponentVisible from '../../../hooks/useComponentVisible';

import styles from './ImageSearch.module.css'

//Models
import {IImageSearchCreateRequest} from '../../../models/IImageSearchCreateRequest';
import {IImageSearchCreateResponse} from '../../../models/IImageSearchCreateResponse';
import {IImageSearchFilters} from '../../../models/IImageSearchFilters';
import {ILinkingResult} from '../../../models/ILinkingResult';
import { IAction } from '../../../models/IAction';
import { IUser } from '../../../models/IUser';
import { IImageStatus } from '../../../models/IImageStatus';

//Filters
import {StatusFilter} from '../Filters/StatusFilter/StatusFilter';
import {CaptureTypeFilter } from '../Filters/CaptureTypeFilter/CaptureTypeFilter';
import {DateFilter } from '../Filters/DateFilter/DateFilter';
import {SessionFilter} from '../Filters/SessionFilter/SessionFilter';
import {PackFilter} from '../Filters/PackFilter/PackFilter';
import {AssetLinkFilter} from '../Filters/AssetLinkFilter/AssetLinkFilter';
import {DefectFilter} from '../Filters/DefectFilter/DefectFilter';
import {FileNameFilter } from '../Filters/FileNameFilter/FileNameFilter';
import {AssigneeFilter} from '../Filters/AssigneeFilter/AssigneeFilter';
import {DefectTypeFilter} from '../Filters/DefectTypeFilter/DefectTypeFilter';

import {deepClone} from '../../Common/Common'
import { TimeFilter } from '../Filters/TimeFilter/TimeFilter';
import { DefectFormFilter } from '../Filters/DefectFormFilter/DefectFormFilter';
import { IDefectFormKeyValue } from '../../../models/IDefectFormKeyValue';
import { is } from 'date-fns/locale';

const makeButtonStyles = makeStyles((theme) => ({
  root: {
      fontSize: 14,
      backgroundColor: "#95C5E7",
        
      "&:hover": {
          color: 'red',
          background: 'linear-gradient(0deg, rgba(249, 252, 255, 0.08), rgba(249, 252, 255, 0.08)), #95C5E7'
      },
  },
  label: {
    color: '#1E1E20',
    "&:hover": {
        color: '#1E1E20',
    },
  }
}));


const makeChipStyles = makeStyles((theme) => ({
  outlined: {
    backgroundColor: '#3C3D3E',
    marginRight: '6px',
    marginBottom: '8px'
  },
  icon: {
    position: 'absolute',
    right: 5,
  },
  iconSelected: {
    position: 'absolute',
    right: 27,
    color:'#F7B500'
  },
  label:{
    marginRight:15,
    color: '#F9FCFF'
  },
  labelSelected:{
    marginRight:15,
    color: '#F7B500'
  },
}));

const makeBadgeStyles = makeStyles((theme) => ({
  badge: {
    backgroundColor:'#F7B500',
    color: '#000000',
    marginLeft: '4px',
    marginTop: '4px'
  }
}));

/*used for displaying filter container windows 
  at the correct coordinates*/
const FILTER_DISPLAY_OFFSET_X:number = 95;
const FILTER_DISPLAY_OFFSET_Y:number = 20;
const FILTER_DISPLAY_MIN_X:number    = 88;

interface IImageSearchProps {
  organisationUsers:IUser[];
  linkingResult: ILinkingResult | null;
  refreshSearchResultsFlag: number;
  campaignId: number;
  imageStatuses: IImageStatus[],
  defectStatuses: string[],
  actionPerformed:IAction | null,
  urlCampaignExists : boolean,
  urlSession : string | undefined,
  urlImage: string | undefined,
  isUrlImageSearchPerformed : boolean,
  onImageSearchCreated: Function;
  onUpdateIsLoadingCheck: Function;
  onSearchResultsNeedRefreshing: Function;
  onFiltersExpandedChanged: Function;
  onUrlSearchPerformed : Function;   //the function to call when the search is performed based on url image search.
};

interface ISearchFilter {
  name: string;
  hasChip: boolean,                     //whether to add a ui chip
  isDisplayed: boolean;                 //whether the filter component is currently shown
  displayX: number;                     //where we show the filter component x
  displayY: number;                     //where we show the filter component y  
  filterItemsSelectedCount: number;     //how many items are selected in the filter component
  clearFiltersFlag: number;             //used to signal to the filter component to clear itself
  listOrder: number;                    //used to track where the filter should appear in the filter list
  lastUpdateTime?: Date                 //the last time the filter was updated
};

interface ICreateSearchProps {
    zoomToExtent:boolean;
}

export function ImageSearch(props:IImageSearchProps){
  const buttonClasses = makeButtonStyles();
  const chipClasses = makeChipStyles();
  const badgeClasses = makeBadgeStyles();
  
  const { ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

  // Define all the filters available
  // Add new filters here

  /*
  The ideal display order is: Asset Link (let's change this to 'Link'), Status, Pack, Session, Defect, Type, Captured Date, Processed Date.
  Note: Assignee and image filename will be added eventually too.*/

  // When adding a new filter you need to add code to clearFilter()
  const allSearchFilters: ISearchFilter[] = [
                                              { listOrder: 0, name: 'Link', hasChip: true, isDisplayed: false, displayX:0, displayY:0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },                                        
                                              { listOrder: 1, name: 'Status', hasChip: true, isDisplayed: false, displayX:0, displayY:0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 2, name: 'Pack', hasChip: true, isDisplayed: false, displayX:0, displayY:0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 3, name: 'Session', hasChip: true, isDisplayed: false, displayX:0, displayY:0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 4, name: 'Assignee', hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 5, name: 'Defect', hasChip: true, isDisplayed: false, displayX:0, displayY:0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 6, name: 'Defect Status', hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 7, name: 'Defect Type', hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 8, name: 'Defect Form', hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 9, name: 'Defect Date', hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 10, name: 'Type', hasChip: true, isDisplayed: false, displayX:0, displayY:0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 11, name: 'Capture Date', hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 12, name: 'Capture Time', hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 13, name: 'Processed Date', hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                              { listOrder: 14, name: 'Filename', hasChip: true, isDisplayed: false, displayX: 0, displayY: 0, filterItemsSelectedCount: 0, clearFiltersFlag: 0 },
                                            ];

  const filterOrderSort = (a:ISearchFilter, b:ISearchFilter) => {
    if (a.listOrder > b.listOrder) return 1;
    else if (a.listOrder < b.listOrder) return -1;
    return 0;
  }
  const defaultImageSearchFilters:IImageSearchFilters = { campaignId:0 };
  
  const [imageSearchFilters, setImageSearchFilters] = useState( defaultImageSearchFilters );
  const [searchFilters, setSearchFilters] = useState( allSearchFilters);
  const [showDateControl, setShowDateControl] = useState(true);
  const [prevCampaignId, setPrevCampaignId] = useState(0);
  const defaultImageStatuses: string[] = [];
  const [imageStatuses, setImageStatuses] = useState(defaultImageStatuses);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [urlImageSearch, setUrlImageSearch] = useState(false);
  const [urlSessionExists, setUrlSessionExists] = useState(false);


  function createGuid(){  
     function S4() {  
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);  
     }  
     return (S4() + S4() + "-" + S4() + "-4" + S4().substr(0,3) + "-" + S4() + "-" + S4() + S4() + S4()).toLowerCase();  
  }

  function setShowAllFiltersFlag(isExpanded:boolean){

      setShowAllFilters(isExpanded);
      props.onFiltersExpandedChanged(isExpanded);
  }

  // If a filter is shown then hide it
  function hideFilter(){
    
    if (searchFilters.filter(f=>f.isDisplayed).length > 0 || searchFilters.filter(x => x.filterItemsSelectedCount > 0).length > 0 ) {
        // A filter was shown and is about to be hidden.

        let updatedFilters = searchFilters.filter(f=>f.lastUpdateTime != null);
        if (updatedFilters != null) {
            //Sort filters into correct order

            let updatedFiltersWithValues = updatedFilters.filter(f=>f.filterItemsSelectedCount > 0);

            searchFilters.forEach((f:ISearchFilter) => { f.listOrder += searchFilters.length;})

            if (updatedFiltersWithValues.length > 0) {
                updatedFiltersWithValues.sort((a, b) => (a.lastUpdateTime != null && b.lastUpdateTime != null && a.lastUpdateTime < b.lastUpdateTime) ? 1 : -1);
                let sortOrder = -1;
                updatedFiltersWithValues.forEach(f => f.listOrder = ++sortOrder);
            }
      
            searchFilters.sort(filterOrderSort);
        }
    }

    searchFilters.forEach(f=>f.isDisplayed = false);
    setSearchFilters(searchFilters.slice());
  }

  // Show or hide a filter
  function toggleFilterDisplay(clickEvent:any, filter: ISearchFilter) {

    let newState = !filter.isDisplayed;

    searchFilters.forEach(f=>f.isDisplayed = false);

    filter.isDisplayed = newState;
    //handle for going offscreen
    let rect:any = clickEvent.currentTarget.getBoundingClientRect();
    filter.displayX = rect.x - FILTER_DISPLAY_OFFSET_X > -FILTER_DISPLAY_OFFSET_X 
      ? rect.x - FILTER_DISPLAY_OFFSET_X : -FILTER_DISPLAY_MIN_X;
    filter.displayY = rect.y - FILTER_DISPLAY_OFFSET_Y; 
    
    setIsComponentVisible(newState);

    setSearchFilters(searchFilters.slice());
  }

  // Clear the selected items in all filters
  async function clearFilters() {

    setShowAllFiltersFlag(false);
     
    //had to make async so that we can await this state change to complete before re-rendering date picker
    await setShowDateControl(false);
    await setShowDateControl(true);

    //clear search results
    props.onImageSearchCreated(null);

    // random number that will trigger an update 
    let clearFiltersFlag = new Date().getTime();
    let cache:ISearchFilter[] = deepClone(allSearchFilters);
    cache.forEach(f=>{
      f.clearFiltersFlag = clearFiltersFlag;
    });

    setSearchFilters(cache);

    setImageSearchFilters( {...defaultImageSearchFilters});
    }

    async function clearFilter(filter: ISearchFilter) {

        await setShowDateControl(false);
        await setShowDateControl(true);

        // Set the sort order so this filter is the first filter with no items 
        for (let searchFilter of searchFilters) {
            if (searchFilter.name != filter.name && searchFilter.filterItemsSelectedCount == 0) {
                filter.listOrder = searchFilter.listOrder - 1;
                break;
            }
        }
        searchFilters.sort(filterOrderSort);

        filter.filterItemsSelectedCount = 0;
        filter.lastUpdateTime = undefined;

        // random number that will trigger an update 
        let clearFiltersFlag = new Date().getTime();
        filter.clearFiltersFlag = clearFiltersFlag;

        setSearchFilters(searchFilters.slice());

        //Clear the value on the filter.  New filters will need to be added here
        if (filter.name === 'Status') imageSearchFilters.imageStatus = defaultImageSearchFilters.imageStatus;
        else if (filter.name === 'Pack') imageSearchFilters.pack = defaultImageSearchFilters.pack;
        else if (filter.name === 'Session') imageSearchFilters.sessionId = defaultImageSearchFilters.sessionId;
        else if (filter.name === 'Link') imageSearchFilters.assetLink = defaultImageSearchFilters.assetLink;
        else if (filter.name === 'Capture Date') imageSearchFilters.imageCaptureDateRange = defaultImageSearchFilters.imageCaptureDateRange;
        else if (filter.name === 'Capture Time') imageSearchFilters.imageCaptureTimeRange = defaultImageSearchFilters.imageCaptureTimeRange;
        else if (filter.name === 'Processed Date') imageSearchFilters.imageProcessedDateRange = defaultImageSearchFilters.imageProcessedDateRange;
        else if (filter.name === 'Type') imageSearchFilters.captureType = defaultImageSearchFilters.captureType;
        else if (filter.name === 'Filename') imageSearchFilters.fileName = defaultImageSearchFilters.fileName;
        else if (filter.name === 'Defect Date') imageSearchFilters.defectCreatedDateRange = defaultImageSearchFilters.defectCreatedDateRange;
        else if (filter.name === 'Defect Status') imageSearchFilters.defectStatus = defaultImageSearchFilters.defectStatus;
        else if (filter.name === 'Assignee') imageSearchFilters.assigneeEmailAddress = defaultImageSearchFilters.assigneeEmailAddress;
        else if (filter.name === 'Defect Type') imageSearchFilters.defectType = defaultImageSearchFilters.defectType;
        else if (filter.name === 'Defect Form') imageSearchFilters.defectForm = defaultImageSearchFilters.defectForm;

        if (filter.name === 'Defect') {
            imageSearchFilters.defectCountRange = defaultImageSearchFilters.defectCountRange;
            imageSearchFilters.defectSources = defaultImageSearchFilters.defectSources;
            imageSearchFilters.defectCreatedBy = defaultImageSearchFilters.defectCreatedBy;
        }

        setImageSearchFilters({ ...imageSearchFilters });
    }

// This code is called when props.campaignId changes
  useEffect(() => {
 
    if (props.campaignId !== prevCampaignId) {
      setPrevCampaignId(props.campaignId);
      clearFilters();
    }

  }, [props.campaignId]);

  useEffect(() => {

    if (props.refreshSearchResultsFlag > 0) {
        createSearch({zoomToExtent:false});
    }
  }, [props.refreshSearchResultsFlag]);

  useEffect(() => {
      setImageStatuses(props.imageStatuses.map(s => s.status));
  }, [props.imageStatuses]);


  useEffect(() => {

    // trap when an image has been linked or unlinked and we have a single asset search filter and it means the search results need refreshing
    if (props.linkingResult != null && imageSearchFilters.assetLink != null && imageSearchFilters.assetLink.length === 1) {
        
        if (   (props.linkingResult.result === 'linked' && imageSearchFilters.assetLink[0] === 'No Link' ) 
            || (props.linkingResult.result === 'unlinked' && imageSearchFilters.assetLink[0] === 'Linked' )
        ) {
            props.onSearchResultsNeedRefreshing();
        }
    }
  }, [props.linkingResult]);

  useEffect(() => {

    // trap when an image status has changed and its not on the filter
    if (props.actionPerformed != null 
        && props.actionPerformed.type === 'statusChange' 
        && imageSearchFilters.imageStatus != null 
        && imageSearchFilters.imageStatus.length >= 1 
        && !imageSearchFilters.imageStatus.includes( props.actionPerformed.data.status )) {
        
        props.onSearchResultsNeedRefreshing();

    }

    // trap when an image has been assigned or unassigned and the assignee filter is applied
    if (props.actionPerformed != null 
        && (props.actionPerformed.type === 'assign' || props.actionPerformed.type === 'unassign') 
        && imageSearchFilters.assigneeEmailAddress != null 
        && imageSearchFilters.assigneeEmailAddress.length > 0) {
        
        props.onSearchResultsNeedRefreshing();
    }

  }, [props.actionPerformed]);

  useEffect(() => {
    if (!urlImageSearch && props.urlCampaignExists) {
      let isSearch = false;
      
      if (urlSessionExists && !props.urlImage) {
        isSearch = true;
      } else if (urlSessionExists && props.urlImage && imageSearchFilters.fileName) {
        isSearch = true;
      }

      if (isSearch) {
        createSearch({ zoomToExtent: true });
        setUrlImageSearch(true);
        props.onUrlSearchPerformed();
      }
    }
  }, [props.urlSession, props.urlImage, props.urlCampaignExists, imageSearchFilters, urlSessionExists]);

  const createSearch = useCallback(
      async (cbprops: ICreateSearchProps) => {
          props.onUpdateIsLoadingCheck(true);
          hideFilter();
          setShowAllFiltersFlag(false);

          if (props == null || props.campaignId === 0) return;

          try {
              imageSearchFilters.campaignId = props.campaignId;

              const createSearchRequest: IImageSearchCreateRequest = {
                  searchId: createGuid(),
                  filters: imageSearchFilters,
              };

              let response = await postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + 'search/create', createSearchRequest);
              if (response.status === 200) {
                  let searchResponse = response.data as IImageSearchCreateResponse;
                  props.onImageSearchCreated(searchResponse, cbprops.zoomToExtent);
                  props.onUpdateIsLoadingCheck(false);
              } else {
                  console.log('Failed to get create search. Code = ' + response.status);
              }
          } catch (e) {
              console.log(e);
          }
      },
      [props.campaignId, imageSearchFilters]
  );

  let filterChips: JSX.Element[] = [];
  let debug:number[] = []
  let searchFilterInstances: JSX.Element[] = [];

  searchFilters.forEach( (filter:ISearchFilter) => {
    debug.push(filter.listOrder);
    let userHasSelectedFilters = filter.filterItemsSelectedCount > 0;

    if (filter.hasChip) {
      // Create a chip

      filterChips.push( 
        <span key={filter.name} style={{height:35}}>
         <Badge 
            classes={badgeClasses}
            max={9}
            badgeContent={filter.filterItemsSelectedCount} 
            color="primary" 
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
           
          <Chip
            style={userHasSelectedFilters ? { border: '1px solid #F7B500' } : {}}
            classes={{ icon: userHasSelectedFilters ? chipClasses.iconSelected  : chipClasses.icon, label: userHasSelectedFilters ? chipClasses.labelSelected : chipClasses.label, outlined: chipClasses.outlined }}
            variant="outlined"
            icon={filter.isDisplayed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            label={filter.name}
            onClick={(e:any) => toggleFilterDisplay(e, filter)}
            onDelete={userHasSelectedFilters ? () => clearFilter(filter) : undefined }
            />
          </Badge>
        </span>
      );
    }

    let searchFilterInstance: JSX.Element | null = null;

    // Add an offset so the menus line up nicely
    let leftOffset = 90;

    let filterStyle = {
	      left: leftOffset + filter.displayX,
	      top: filter.displayY,
        display: filter.isDisplayed ? 'block' : 'none',
        overflowY:"auto" as "auto",
        maxHeight:"90%"
    };

    // Create an instance of each filter component

    if (filter.name === 'Status') {
      searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
       <StatusFilter 
          clearFilters={filter.clearFiltersFlag} 
          statuses={imageStatuses}
          onFilterChanged={ (selectedStatuses:string[])=> { 

            // Take the filter output and set it on the filter object that will be passed to the create search api
            imageSearchFilters.imageStatus = selectedStatuses;
            // update state 
            setImageSearchFilters( {...imageSearchFilters});
        
            // Set the count for the badge
            filter.filterItemsSelectedCount = selectedStatuses.length;

            // Set the last update time
            filter.lastUpdateTime = new Date();

            // update state 
            setSearchFilters(searchFilters.slice());
           }}  />
        </div>
    }

    if (filter.name === 'Defect Status') {
        searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
        <StatusFilter
            clearFilters={filter.clearFiltersFlag}
            statuses={props.defectStatuses}
            onFilterChanged={(selectedStatuses: string[]) => {

                // Take the filter output and set it on the filter object that will be passed to the create search api
                imageSearchFilters.defectStatus = selectedStatuses;
                // update state 
                setImageSearchFilters({ ...imageSearchFilters });

                // Set the count for the badge
                filter.filterItemsSelectedCount = selectedStatuses.length;

                // Set the last update time
                filter.lastUpdateTime = new Date();

                // update state 
                setSearchFilters(searchFilters.slice());
            }} />
        </div>
    }

    if (filter.name === 'Type') {
      searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
       <CaptureTypeFilter 
          clearFilters={filter.clearFiltersFlag} 

          onFilterChanged={ (selectedCaptureType:string[])=> { 

            // Take the filter output and set it on the filter object that will be passed to the create search api
            imageSearchFilters.captureType = selectedCaptureType;
            // update state 
            setImageSearchFilters( {...imageSearchFilters});
        
            // Set the count for the badge
            filter.filterItemsSelectedCount = selectedCaptureType.length;

            // Set the last update time
            filter.lastUpdateTime = new Date();

            // update state 
            setSearchFilters(searchFilters.slice());
           }}  />
        </div>
      }

      if (filter.name === 'Capture Date') {
          searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
              {showDateControl &&
                  <DateFilter
                      clearFilters={filter.clearFiltersFlag}
                      isDisplayed={filter.isDisplayed}

                      onFilterChanged={(selectedCaptureDates: string[]) => {

                          // Take the filter output and set it on the filter object that will be passed to the create search api
                          imageSearchFilters.imageCaptureDateRange = selectedCaptureDates;
                          // update state 
                          setImageSearchFilters({ ...imageSearchFilters });

                          // Set the count for the badge
                          if (selectedCaptureDates[0] === selectedCaptureDates[1])
                              filter.filterItemsSelectedCount = 1;
                          else {
                              filter.filterItemsSelectedCount = selectedCaptureDates.length;
                          }

                          // Set the last update time
                          filter.lastUpdateTime = new Date();

                          // update state 
                          setSearchFilters(searchFilters.slice());
                      }} />
              }
          </div>
      }

      if (filter.name === 'Capture Time') {
          searchFilterInstance = (
              <div key={filter.name} className={styles.filterContainer} style={filterStyle}>
                  <TimeFilter
                      clearFilters={filter.clearFiltersFlag}
                      onFilterChanged={(selectedtime: string[]) => {
                        // Take the filter output and set it on the filter object that will be passed to the create search api
                          imageSearchFilters.imageCaptureTimeRange = selectedtime;
                          // update state
                          setImageSearchFilters({ ...imageSearchFilters });

                          // Set the count for the badge, -1 because not considering timezone in filter
                          filter.filterItemsSelectedCount = selectedtime.length-1;

                          // Set the last update time
                          filter.lastUpdateTime = new Date();

                          // update state
                          setSearchFilters(searchFilters.slice());
                      }}
                  />
              </div>
          );
      }

      if (filter.name === 'Processed Date') {
          searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
              {showDateControl &&
                  <DateFilter
                      clearFilters={filter.clearFiltersFlag}
                      isDisplayed={filter.isDisplayed}

                      onFilterChanged={(selectedProcessedDates: string[]) => {

                          // Take the filter output and set it on the filter object that will be passed to the create search api
                          imageSearchFilters.imageProcessedDateRange = selectedProcessedDates;
                          // update state 
                          setImageSearchFilters({ ...imageSearchFilters });

                          // Set the count for the badge
                          if (selectedProcessedDates[0] === selectedProcessedDates[1])
                              filter.filterItemsSelectedCount = 1;
                          else {
                              filter.filterItemsSelectedCount = selectedProcessedDates.length;
                          }
            
                          // Set the last update time
                          filter.lastUpdateTime = new Date();

                          // update state 
                          setSearchFilters(searchFilters.slice());
                      }} />
                }
          </div>
      }

      if (filter.name === 'Defect Date') {
          searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
              {showDateControl &&
                  <DateFilter
                      clearFilters={filter.clearFiltersFlag}
                      isDisplayed={filter.isDisplayed}

                      onFilterChanged={(selectedProcessedDates: string[]) => {

                          // Take the filter output and set it on the filter object that will be passed to the create search api
                          imageSearchFilters.defectCreatedDateRange = selectedProcessedDates;
                          // update state 
                          setImageSearchFilters({ ...imageSearchFilters });

                          // Set the count for the badge
                          if (selectedProcessedDates[0] === selectedProcessedDates[1])
                              filter.filterItemsSelectedCount = 1;
                          else {
                              filter.filterItemsSelectedCount = selectedProcessedDates.length;
                          }

                          // Set the last update time
                          filter.lastUpdateTime = new Date();

                          // update state 
                          setSearchFilters(searchFilters.slice());
                      }} />
              }
          </div>
      }

    if (filter.name === 'Session') {
      searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
       <SessionFilter 
          clearFilters={filter.clearFiltersFlag} 
          campaignId={props.campaignId}
          urlSession={props.urlSession}
          urlCampaignExists = {props.urlCampaignExists}
          isUrlImageSearchPerformed = {props.isUrlImageSearchPerformed}
          onUrlSessionFind={() => {
            setUrlSessionExists(true);
          }}
          onFilterChanged={ (selectedSession:number[])=> { 

            // Take the filter output and set it on the filter object that will be passed to the create search api
            imageSearchFilters.sessionId = selectedSession;
            // update state 
            setImageSearchFilters( {...imageSearchFilters});
        
            // Set the count for the badge
            filter.filterItemsSelectedCount = selectedSession.length;

            // Set the last update time
            filter.lastUpdateTime = new Date();

            // update state 
            setSearchFilters(searchFilters.slice());
           }}  />
          
        </div>
    }

    if (filter.name === 'Pack') {
      searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
       <PackFilter 
          clearFilters={filter.clearFiltersFlag} 
          campaignId={props.campaignId}
          onFilterChanged={ (selectedPack:string[])=> { 

            // Take the filter output and set it on the filter object that will be passed to the create search api
            imageSearchFilters.pack = selectedPack;
            // update state 
            setImageSearchFilters( {...imageSearchFilters});
        
            // Set the count for the badge
            filter.filterItemsSelectedCount = selectedPack.length;

            // Set the last update time
            filter.lastUpdateTime = new Date();

            // update state 
            setSearchFilters(searchFilters.slice());
           }}  />
        </div>
    }

    if (filter.name === 'Link') {
      searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
       <AssetLinkFilter 
          clearFilters={filter.clearFiltersFlag} 
          onFilterChanged={ (selectedAssetLink:string[])=> { 

            // Take the filter output and set it on the filter object that will be passed to the create search api
            imageSearchFilters.assetLink = selectedAssetLink;
            // update state 
            setImageSearchFilters( {...imageSearchFilters});
        
            // Set the count for the badge
            filter.filterItemsSelectedCount = selectedAssetLink.length;

            // Set the last update time
            filter.lastUpdateTime = new Date();

            // update state 
            setSearchFilters(searchFilters.slice());
           }}  />
        </div>
    }
  

    if (filter.name === 'Defect') {
      searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
      <DefectFilter
          organisationUsers={props.organisationUsers}
          isDisplayed={filter.isDisplayed}
          campaignId={props.campaignId}
          clearFilters={filter.clearFiltersFlag} 
          onFilterChanged={(defectCountRange: number[], selectedSources: string[], selectedUserNames:string[] )=> {

            // Take the filter output and set it on the filter object that will be passed to the create search api     
            
            imageSearchFilters.defectCountRange = defectCountRange;
            imageSearchFilters.defectSources = selectedSources;
            imageSearchFilters.defectCreatedBy = selectedUserNames;
              
            // update state 
            setImageSearchFilters( {...imageSearchFilters});

            let count = 1 + selectedUserNames.length;
            if (selectedSources != null) count += selectedSources.length;

            // Set the count for the badge
            filter.filterItemsSelectedCount = count;

            // Set the last update time
            filter.lastUpdateTime = new Date();

            // update state 
            setSearchFilters(searchFilters.slice());
           }}  />
        </div>
    }
  
    if (filter.name === 'Filename') {
      searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
       <FileNameFilter 
          clearFilters={filter.clearFiltersFlag}
          urlImage={props.urlImage}
          urlCampaignExists = {props.urlCampaignExists}
          urlSessionExists = {urlSessionExists}
          onFilterChanged={ (fileNameString:string)=> { 
            // Take the filter output and set it on the filter object that will be passed to the create search api
            imageSearchFilters.fileName = fileNameString;
            // update state 
            setImageSearchFilters( {...imageSearchFilters});
            // Set the count for the badge
            filter.filterItemsSelectedCount = fileNameString.length > 0 ? 1 : 0;

            // Set the last update time
            filter.lastUpdateTime = new Date();

            // update state 
            setSearchFilters(searchFilters.slice());
           }}  />
        </div>
    }

    if (filter.name === 'Assignee') {
      searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
       <AssigneeFilter 
          campaignId={props.campaignId}
          isDisplayed={filter.isDisplayed}
          organisationUsers={props.organisationUsers}
          clearFilters={filter.clearFiltersFlag} 
          
          onFilterChanged={ (assigneeEmailAddresses:string[])=> { 

            imageSearchFilters.assigneeEmailAddress = assigneeEmailAddresses;
            setImageSearchFilters( {...imageSearchFilters});
        
            // Set the count for the badge
            filter.filterItemsSelectedCount = assigneeEmailAddresses.length;

            // Set the last update time
            filter.lastUpdateTime = new Date();

            // update state 
            setSearchFilters(searchFilters.slice());
           }}  />
        </div>
    }

    if (filter.name === 'Defect Type') {
      searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyle} >
       <DefectTypeFilter 
          campaignId={props.campaignId}
          isDisplayed={filter.isDisplayed}
          clearFilters={filter.clearFiltersFlag} 

          onFilterChanged={ (defectTypes:string[])=> { 

            imageSearchFilters.defectType = defectTypes;
            setImageSearchFilters( {...imageSearchFilters});
        
            // Set the count for the badge
            filter.filterItemsSelectedCount = defectTypes.length;

            // Set the last update time
            filter.lastUpdateTime = new Date();

            // update state 
            setSearchFilters(searchFilters.slice());
           }}  />
        </div>
    }

    let filterStyleMod: Partial<typeof filterStyle> = filterStyle;
    delete filterStyleMod.overflowY; // remove overflow for this filter only
    if (filter.name === 'Defect Form') {
          searchFilterInstance = <div key={filter.name} className={styles.filterContainer} style={filterStyleMod} >
              <DefectFormFilter
                  clearFilters={filter.clearFiltersFlag}
                  isDisplayed={filter.isDisplayed}
                  onFilterChanged={(defectForm: IDefectFormKeyValue[]) => {
                      imageSearchFilters.defectForm = defectForm;
                      setImageSearchFilters({ ...imageSearchFilters });

                      // Set the count for the badge
                      filter.filterItemsSelectedCount = defectForm.length;

                      // Set the last update time
                      filter.lastUpdateTime = new Date();

                      // update state 
                      setSearchFilters(searchFilters.slice());
                  }} />
          </div>
      }

    // Add new filters here
    if ( searchFilterInstance != null)
      searchFilterInstances.push(searchFilterInstance);
  });

  searchFilters.sort(filterOrderSort);


  if (searchFilters.find(f=>f.isDisplayed) != null && !isComponentVisible ) {
    hideFilter();
  }

  return ( <div className={styles.imageSearchContainer}> 
            <div className={styles.imageSearchContent} > 
                <Box display="flex" flexDirection="column">
                   <Box display="flex" justifyContent="flex-start" >
                      <Box display="flex" justifyContent="flex-start" flexWrap={ showAllFilters ? "Wrap" : "noWrap"} onWheel={ (e:any) => { if (!isComponentVisible) e.currentTarget.scrollLeft -= e.nativeEvent.wheelDeltaY; }} style={{height: showAllFilters ? 160 : 50 ,overflowX:'auto' as 'auto', paddingTop: 7, paddingLeft: 7}} > 
                         {filterChips}
                         <div ref={ref} onClick = {() => setIsComponentVisible(true)}>
                          {searchFilterInstances}   
                         </div>
                      </Box>
                    </Box>
                    <Grid
                      container
                      spacing={0}
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      style={{ flexWrap: "nowrap" }}
                    >
                        <Grid item>
                            <Box>
                                { !showAllFilters &&
                                    <IconButton 
                                        onClick={(e:any) => setShowAllFiltersFlag(true)} 
                                        style={{height:17, width:17}}>
                                        <ExpandMoreIcon  />  
                                    </IconButton>
                                }
                                { showAllFilters &&
                                    <IconButton 
                                        onClick={(e:any) => setShowAllFiltersFlag(false)} 
                                        style={{height:17, width:17}}>
                                        <ExpandLessIcon  />  
                                    </IconButton>
                                }
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box justifyContent="flex-end" style={{marginTop:5}}>
                                <Link style={{fontSize:14,marginRight:15}} onClick={clearFilters}>
                                CLEAR ALL
                                </Link>
                                <Button variant="contained" onClick={ () => createSearch({zoomToExtent:true})} classes={{root: buttonClasses.root, label:buttonClasses.label}}>
                                APPLY
                                </Button>
                            </Box>
                        </Grid>
                   </Grid>
                  </Box>
               </div>
           </div>
      );

}
