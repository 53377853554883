import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './DefectFormFilter.module.css';
import { DefectFormKeyValuePair } from './DefectFormKeyValuePair/DefectFormKeyValuePair';
import { IDefectFormKey } from '../../../../models/IDefectFormKey';
import { IDefectFormKeyValue } from '../../../../models/IDefectFormKeyValue';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';

interface IDefectFormFilterProps {
    onFilterChanged: Function;
    clearFilters: number;
    isDisplayed: boolean;
}

export function DefectFormFilter(props: IDefectFormFilterProps) {
    const numberOfKeys = 2;
    const initialkeyValuePairInstanceStatuses = new Array(numberOfKeys).fill(false);

    const [lastClearFilters, setLastClearFilters] = useState(0);

    const [defectFormKeys, setDefectFormKeys] = useState<IDefectFormKey[]>([]);
    const [selectedDefectFormKeyValues, setSelectedDefectFormKeyValues] = useState<IDefectFormKeyValue[]>([]);

    let keyValuePairInstances: JSX.Element[] = [];
    const [keyValuePairInstanceStatuses, setKeyValuePairInstanceStatuses] = useState<boolean[]>(initialkeyValuePairInstanceStatuses);
 
    useEffect(() => {

        if (props.clearFilters !== lastClearFilters) {
            setLastClearFilters(props.clearFilters);
            setSelectedDefectFormKeyValues([]);
        }

    }, [props.clearFilters, lastClearFilters]);

    useEffect(() => {

       setKeyValuePairInstanceStatuses(initialkeyValuePairInstanceStatuses);

    }, [props.isDisplayed]);

    useEffect(() => {

        getDefectFormKeys();

    }, []);

    const getDefectFormKeys = useCallback(async () => {

        let url = process.env.REACT_APP_VAA_API_URL + "defectForm/getKeys";

        fetchWithAuthorisationHeader(url).then(res => {
            let response = res.data as IDefectFormKey[];
            var sortedDeprecatedKeys = response.filter(x => x.isDeprecated == true).sort((a: IDefectFormKey, b: IDefectFormKey) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
            var sortedCurrentVersionKeys = response.filter(x => x.isDeprecated == false).sort((a: IDefectFormKey, b: IDefectFormKey) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));
            setDefectFormKeys(sortedCurrentVersionKeys.concat(sortedDeprecatedKeys));
        })
            .catch(err => {
                console.log('Error: Failed to get ' + url + ' with status code ' + err.status);
            });
    }, []);

    const onKeyValuPairSelected = (selectedKeyId: number, selectedValues: string[], keyValuePairId: number) => {

        let newArray: IDefectFormKeyValue[] = selectedDefectFormKeyValues.slice();

        if (selectedKeyId && selectedValues.length > 0) {

            let selectedKey = defectFormKeys.find(x => x.id == selectedKeyId);

            if (selectedKey) {
                let newDefectFormKeyValue: IDefectFormKeyValue = {
                    defectFormSearchId: selectedKeyId,
                    jsonPath: selectedKey.jsonPath,
                    values: selectedValues,
                }
                //If already added, replace the object else insert the object
                if (newArray[keyValuePairId]) {

                    newArray.splice(keyValuePairId, 1, newDefectFormKeyValue);
                }
                else {
                    newArray[keyValuePairId] = newDefectFormKeyValue;
                }
            }
            else {
                console.log("Key not found.");
            }
        }
        else {
            // Clear the key value pair as user has cleared the selection. 
            newArray.splice(keyValuePairId, 1, {} as IDefectFormKeyValue);
        }

        setSelectedDefectFormKeyValues(newArray);

        //Filter only the ones that have key value pair and send them to parent
        props.onFilterChanged(newArray.filter(x => x.defectFormSearchId != null));
    }

    // On click of key or value expand icon, close the other open value dropdowns and open the current value dropdown. 
    const onExpandIconClick = (expandStatus: boolean, keyValuePairId: number) => {
        let statusList = initialkeyValuePairInstanceStatuses;
        statusList[keyValuePairId] = expandStatus; 
        setKeyValuePairInstanceStatuses(statusList);
    }
  
    for (let i = 0; i < numberOfKeys; i++) {
        keyValuePairInstances.push(
            <DefectFormKeyValuePair
                key={i}
                keyValuePairInstanceId={i}
                keys={defectFormKeys}
                onKeyValuePairSelected={onKeyValuPairSelected}
                onExpandIconClick={onExpandIconClick}
                openValueDropdown={keyValuePairInstanceStatuses[i]}
                clearFilters={lastClearFilters}
            />
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Box className={styles.layout}>
                    <Typography variant="h5" noWrap style={{ marginBottom: 10, marginLeft: 10 }}>
                        Select Defect Form Values
                    </Typography>
                    {keyValuePairInstances}
                </Box>
            </div>
        </div>
    );
}
