import React, { useState, useEffect } from 'react'
import {
  Box
} from '@material-ui/core/'
import {ISegment} from '../../../models/ISegment';
import Grid from '@material-ui/core/Grid'
import styles from './AssetSearchSegmentResult.module.css';

interface IAssetSearchSegmentResultProps {
    segment: ISegment,
    isAssetRowSelected: boolean,
    thumbnail: string | "",
    onMouseClick:Function
}
export const AssetSearchSegmentResult = (props:IAssetSearchSegmentResultProps) => {

    return (
        <Grid
        container
        justify="flex-start"
        className={styles.gridRow}
        
        spacing={1}>
            <Grid item 
            className={styles.content} 
            style={{ minWidth: "100%" }}
            onClick={() => {props.onMouseClick(props.segment)}}
            >
                <Grid container justify="flex-start" spacing={3} className={props.isAssetRowSelected? styles.container + ' ' + styles.selected : styles.container}>
                    <Grid item

                     style={{"minWidth": "20px", paddingLeft: "20px"}}>
                    <img
                        src={props.thumbnail} width="40px"></img>
                    </Grid>
                    <Grid item 
                        
                        style={{"minWidth": "50%"}}>
                        <Box className={styles.bigProp}>
                                {props.segment.externalReferenceId || "---"}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

