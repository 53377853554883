import Analytics from '@aws-amplify/analytics';
import Auth from '@aws-amplify/auth';
import { getTokenClaims} from "./AuthenticationService";

//Configure Pinpoint
const amplifyConfig = {
    Auth: {
        identityPoolId: process.env.REACT_APP_PINPOINT_IDENTITY_POOL_ID as string, // VAA-Southeast-Pinpoint Cognito identity pool id
        region: 'ap-southeast-2'
    }
}

const analyticsConfig = {
    AWSPinpoint: {
        appId: process.env.REACT_APP_PINPOINT_APP_ID as string, // Amazon Pinpoint App Client ID 
        region: 'ap-southeast-2', // Amazon service region
        mandatorySignIn: false,
        endpointId: "VaaEndpoint",
        endpoint: {
            userId: "VaaUser"
        },
    }
}

Auth.configure(amplifyConfig);
Analytics.configure(analyticsConfig);

let orgName = "";
let userName = "";

export function setCommonPinpointEventAttributes() {
    let auth = getTokenClaims();
    if (auth) {
        orgName = auth.orgName;
        userName = auth.userName;
    }
}

export function sendEventToPinpoint(event: any) {
    event.attributes.orgName = orgName;
    event.attributes.userName = userName;

    Analytics.record({
            name: event.eventName,
            attributes: event.attributes
    });
}



