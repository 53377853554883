import React from 'react';
import {IDefect} from '../../models/IDefect';
import {Box, Tooltip} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FeedbackIcon from '@material-ui/icons/Feedback';
import Badge from '@material-ui/core/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './DefectViewer.module.css';

  const StyledBadge = withStyles({
    badge: {
      backgroundColor:'#F7B500',
      color: '#000000',
      marginLeft: '4px',
      marginTop: '4px'
    }
  })(Badge);
  
  const SelectedIconButton = withStyles({
      root: {
          height:40,
          width:40,
          background: 'rgba(147, 186, 245, 0.15)',
         "&:hover": {
              background: 'rgba(147, 186, 245, 0.15)',
          },
      },
      label: {
          color: '#95C5E7',
          "&:hover": {
              color: '#F9FCFF',
          },
      }
  })(IconButton);
  
  
  const NormalIconButton = withStyles({
      root: {
          height:40,
          width:40,
      }
  })(IconButton);

interface IDefectViewerProps {
    defects:IDefect[],
    isDefectModeEnabled:boolean,
    isDefectFetchingComplete:boolean,
    onClick:Function

}


export const DefectViewer = (props:IDefectViewerProps) => {
    return ( 
        <StyledBadge 
            max={9}
            invisible={ props.isDefectFetchingComplete && ( props.isDefectModeEnabled || props.defects.length == 0)}
            badgeContent={props.isDefectFetchingComplete ? props.defects.length : <CircularProgress disableShrink style={{ 'color': '#3C3D3E', 'width': '12px', 'height': '12px' }} />} 
            color="primary" 
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <div className={styles.defectToolbarContainer} style={{padding: 4}} >
                    <Tooltip title='Toggle defect mode'>
                        {props.isDefectModeEnabled 
                        ?
                        <SelectedIconButton
                            disabled = {!props.isDefectFetchingComplete}
                            onClick={() => {props.onClick();}}>
                            <FeedbackIcon style={{fontSize: 25}} />
                        </SelectedIconButton>
                        :
                        <NormalIconButton
                            disabled = {!props.isDefectFetchingComplete}
                            onClick={() => {props.onClick();}}>
                            <FeedbackIcon style={{fontSize: 25}} />
                        </NormalIconButton>
                        }
                    </Tooltip>
                </div>
            </Box>
        </StyledBadge>
    );
}


