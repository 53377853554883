import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';

import { fetchWithAuthorisationHeader, postWithAuthorisationHeader } from "../../services/AuthenticationService";
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import { isNullOrUndefined } from 'util';


const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 480,
        height: 40,
        background: "linear-gradient(0deg, rgba(249, 252, 255, 0.11), rgba(249, 252, 255, 0.11)), #292A2B",
        "&:hover": {
            background: "linear-gradient(0deg, rgba(249, 252, 255, 0.08), rgba(249, 252, 255, 0.08)), linear- gradient(0deg, rgba(249, 252, 255, 0.11), rgba(249, 252, 255, 0.11)), #292A2B"
        },
        "&:active": {
            background: "linear-gradient(0deg, rgba(249, 252, 255, 0.15), rgba(249, 252, 255, 0.15)), linear- gradient(0deg, rgba(249, 252, 255, 0.11), rgba(249, 252, 255, 0.11)), #292A2B"
        },
        marginTop: 2
    },
    inputRoot: {
        height:38
    },
    input: {
        marginTop:-18
    },
    indicator: {
        color: "#F9FCFF"
    },
    listbox: {
        width:480,
        height: 300
    },
    listOption: {
        "&:hover": {
            color: '#93BAF5',
            backgroundColor: "rgba(147, 186, 245, 0.08)"
        }
    },
    label: {
        color: "#F9FCFF"
    },
    noCampaignsLabel: {
        marginTop : 14,
        color: "#F9FCFF"
    },
}));

interface Props {
  onCampaignIdChange: Function;
  organisationId: number;
  lastAccessedCampaignId: number;
  urlCampaignName: string | undefined;
  isUrlImageSearchPerformed: boolean;
}

interface Campaign {
    id: number,
    name: string
}

function Campaign(props: Props) {
    const classes = useStyles();
    const maxCampaignNameCharacters = 40;
    let emptyCampaigns: Campaign[] = [];
      
    /* Initialisation of the states */
    const [campaigns, setCampaigns] = React.useState(emptyCampaigns); //Represents the full list of campiagns
    const [iniliatised, setIniliatised] = React.useState(false); //Indicates whether the api has been called or not

    React.useEffect(() => {
        async function getCampaigns() {
            try {
                let response = await fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "campaign/vaaCampaigns/organisation/" + props.organisationId);
                setIniliatised(true);
                if (response && response.data) {
                    setCampaigns(response.data.sort((a: Campaign, b: Campaign) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
                }
            }
            catch(e) {
               console.log(e);
            }
        }
        if (props.organisationId != 0) {
            getCampaigns();
        }
    }, [props.organisationId]);

    React.useEffect(() => {
        let URLcampaign = campaigns.find((i) => i.name === props.urlCampaignName);
        let lastAccessedCampaign = campaigns.find((i) => i.id === props.lastAccessedCampaignId);

        //Setting a default value in the campaign dropdown does not trigger a onChange event , so explictly calling it
        if (URLcampaign && !props.isUrlImageSearchPerformed) {
          props.onCampaignIdChange(URLcampaign.id, true);
        } else if (lastAccessedCampaign) {
          props.onCampaignIdChange(props.lastAccessedCampaignId, false);
        }
    }, [campaigns, props.lastAccessedCampaignId, props.urlCampaignName]);

    const onCampaignChange = async (event: React.ChangeEvent<{}>, selectedCampaign: any) => {
        if (selectedCampaign != null) {
            props.onCampaignIdChange(selectedCampaign.id, false);
            try {
                await postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "campaign/updateLastAccessedCampaign", selectedCampaign);
            }
            catch (e) {
                console.log(e);
            }
        } else {
            props.onCampaignIdChange(0, false);
        }
    };

    const onRenderCampaignOption = (campaign: Campaign, state: object) => {

      let displayName = campaign.name.length > maxCampaignNameCharacters ? campaign.name.substr(0,maxCampaignNameCharacters) + "..." :  campaign.name;

      return <option title={displayName != campaign.name ?  campaign.name : undefined  }>{displayName}</option>;
    }

   const onRenderInput = (params: AutocompleteRenderInputParams) => {

      let inputProps = params.inputProps as any; 
      let campaignName = inputProps.value;
      let titleText = campaignName.length > maxCampaignNameCharacters ? campaignName : undefined;

      return (
                <TextField {...params} 
                              title={titleText}
                              size="small"
                              variant="filled" fullWidth
                              label = ""
                              placeholder = "Select a campaign"
                              InputLabelProps={{
                                  className: classes.label,
                                  shrink: false
                              }}
            
                          />
      );
    };

    /*Render*/
    if (!iniliatised)
        return null; // Render nothing if the api is not called

    if (campaigns.length === 0) {
        return (
            <div className={classes.noCampaignsLabel} >No campaigns available</div>
        )
    }   

    return (
        <div>
            <FormControl>
                <Autocomplete
                    options={campaigns}
                    renderOption ={onRenderCampaignOption}
                    getOptionLabel={(option => option.name)}
                    value={campaigns.find(i => i.id == props.lastAccessedCampaignId) ? campaigns.find(i => i.id == props.lastAccessedCampaignId):null}
                    classes={{ root: classes.root, inputRoot: classes.inputRoot, input: classes.input, listbox: classes.listbox, option: classes.listOption, popupIndicator: classes.indicator, clearIndicator: classes.indicator}}
                    onChange={(event,value) => {
                        onCampaignChange(event, value)
                    }}
                    ListboxComponent={List}
                    renderInput={onRenderInput}
                />
            </FormControl>
        </div>
    );
}

export default Campaign;