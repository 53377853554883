import * as React from 'react';
import { RouteComponentProps } from "react-router";
import {  getAuthorizationData } from "../../../services/AuthenticationService";

//Components
import { DZIViewer } from '../../DZI/DZIViewer/DZIViewer';
import { ParentWindowPostMessageStateSync } from '../../StateSync/ParentWindowPostMessageStateSync/ParentWindowPostMessageStateSync';

//Models
import { IImage} from '../../../models/IImage';
import { IDZIViewerState} from '../../../models/IDZIViewerState';
import { IDefect } from '../../../models/IDefect';
import { IUser } from '../../../models/IUser';
import { IImageStatus } from '../../../models/IImageStatus';
import { IImageDefectCount } from '../../../models/IImageDefectCount';

let styles = require('./PopupContainer.module.css');

interface IPopupContainerProps extends RouteComponentProps {
  hostedComponentType:string;
};

interface IPopupContainerState {
  selectedDziImage: IImage | null,
  dziViewerPopupState: IDZIViewerState | null;
  isStateInitialised: boolean;
  isNotConnected: boolean;
  userPermissions: string[];
  defects: IDefect[];
  imageStatuses: IImageStatus[];
  organisationUsers: IUser[];
  isDefectModeEnabled: boolean;
  updatedImageIds: number[] | null;
  imageDefectCount : IImageDefectCount[];
}


export class PopupContainer extends React.Component<IPopupContainerProps, IPopupContainerState> {

   constructor(props: IPopupContainerProps) {
      super(props);
      
      this.initaliseState();
    }

    initaliseState() {

      // Setup the container state
      this.state = {
        selectedDziImage: null,
        dziViewerPopupState: null,
        isStateInitialised: false,
        isNotConnected: false,
        userPermissions: [],
        defects: [],
        imageStatuses: [],
        organisationUsers:[],
        isDefectModeEnabled: false,
        updatedImageIds:[],
        imageDefectCount : []
      }
    }
 
    componentDidMount() {

      //Show a message if this window loses connection to the parent (eg if the page is refreshed)
      let this_ = this;
      setTimeout(function () {
        if (!this_.state.isStateInitialised) {
          this_.setState({isNotConnected:true});
        }
      },1000);
    }

    render() {

      // Only one component can be hosted at a time
      let hostedComponent = null;

      if (this.state.isStateInitialised && this.props.hostedComponentType === 'DZIViewer') {
      
          hostedComponent = (
            <DZIViewer
              organisationUsers={this.state.organisationUsers}
              isExpanded={false}
              image={this.state.selectedDziImage}
              imageStatuses={this.state.imageStatuses}
              viewerState={this.state.dziViewerPopupState}
              userPermissions={this.state.userPermissions}
              defects={this.state.defects}
              onDefectsChanged={(defects: IDefect[]) => this.setState({ defects: defects })}
              isDefectModeEnabled={this.state.isDefectModeEnabled}
              onDefectModeChanged={(isEnabled: boolean) => {
                this.setState({ isDefectModeEnabled: isEnabled });
              }}
              onSelectedDZIImageChanged={(selectedImage: IImage) => {
                this.setState({ selectedDziImage: selectedImage });
              }}
              updatedImageIds={this.state.updatedImageIds}
              actionPerformed={null}
              onDefectChanged={(imageId: number, defectCount: number) => {
                if (this.state.userPermissions.includes('CanReadDefect')) {
                  //this function will update count of the defects for each image. It is used to display red bar on the image thumbnail.
                  let imageDefects: IImageDefectCount[] = [...this.state.imageDefectCount];

                  let existingDefect: IImageDefectCount = { ...imageDefects.filter((x) => x.imageId === imageId)[0] };
                  if (existingDefect.imageId) {
                    existingDefect.defectCount = defectCount;
                    imageDefects[imageDefects.findIndex((x) => x.imageId === imageId)] = existingDefect;
                  } else {
                    imageDefects.push({ imageId: imageId, defectCount: defectCount });
                  }

                  this.setState({ imageDefectCount: imageDefects });
                }
              }}
              canViewDefects={this.state.userPermissions.includes('CanReadDefect')}
              imageDefectCount={this.state.imageDefectCount}
            />
          );
       }

      return <div className={styles.popupContainer} onContextMenu={(e: any) => e.preventDefault()}>
                {/* 
                  The ParentWindowPostMessageStateSync component handles property syncing to and from the parent window container.
                  Properties that need syncing need to be added to it as input properties.  Property names should be the same as they are in the container state.
                  For each popup only configured properties are synched. This is defined in ComponentPropertySyncConfig.
                */}
                <ParentWindowPostMessageStateSync 
                    hostComponentType={this.props.hostedComponentType}
                    defects={this.state.defects}
                    isDefectModeEnabled={this.state.isDefectModeEnabled}
                    windowTitle={"Mondo AIMS VAA | " + this.state.selectedDziImage?.fileName}
                    onStateIntialised={() => this.setState({ isStateInitialised: true })}
                    selectedDziImage={this.state.selectedDziImage }
                    updatedImageIds={[]}
                    imageDefectCount={this.state.imageDefectCount}
                    // Property updates from the parent window container come out here
                    onStateChanged={(stateObj: any) => this.setState(stateObj)}

                />
                { !this.state.isStateInitialised && this.state.isNotConnected &&
                  <div className={styles.notConnectedMessage}>Error - This window is not connected to the Virtual Assessment window </div>
                }

                {hostedComponent}
               
              </div>
    }
}