import React, { useState, useEffect, createRef, useRef } from 'react';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import styles from './SessionFilter.module.css';
import TextField from '@material-ui/core/TextField';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';

const makeControlStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14
  },
  labelSelected: {
    fontSize: 14,
    color: '#F7B500'
  }
}));


interface ISessionFilterProps {
  onFilterChanged: Function;
  onUrlSessionFind: Function;
  clearFilters: number;
  campaignId: number;
  urlSession: string | undefined;
  urlCampaignExists: boolean;
  isUrlImageSearchPerformed: boolean;
}

interface ISession {
  id: number;
  name: string;
  isSelected: boolean;
}

export function SessionFilter(props: ISessionFilterProps) {

  const controlClasses = makeControlStyles();
  const initialSessions: ISession[] = [];
  const [sessions, setSessions] = useState(initialSessions);
  const [filteredSessions, setFilteredSessions] = useState(initialSessions);
  const [lastClearFilters, setLastClearFilters] = useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [isUrlSession, setIsUrlSession] = useState(false);

  // Handle when the filters have been cleard
  // A number is passed as a flag so we can tell when it has changed
  useEffect(() => {

    if (props.clearFilters != lastClearFilters) {
      sessions.forEach(s => s.isSelected = false);
      setSessions(sessions.slice());
      setSelectAll(false);
      setLastClearFilters(props.clearFilters);
    }

  }, [props.clearFilters, lastClearFilters, sessions]);

  //Handle when campaign has been changed
  useEffect(() => {

    async function getSessions() {
      try {
        var sessionList = initialSessions;
        sessions.forEach(s => s.isSelected = false);
        let selectedSessions = sessions.filter(s => s.isSelected && s.name != 'Select All').map(s => s.name);
        props.onFilterChanged(selectedSessions);
        if(props.campaignId > 0) {
          var response = await fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "session/campaign/" + props.campaignId);
          var a = JSON.parse(response.data)
          
          let isUrlSession = false;
          
          a.forEach(((session: { Name: any; Id: any;}) => {
            isUrlSession = session.Name === props.urlSession && props.urlCampaignExists;
            var newSession = { id: session.Id, name: session.Name , isSelected: isUrlSession};
            sessionList.push(newSession);
            
            if (isUrlSession) {
              setIsUrlSession(true);
            }
          }));

          setSessions(sessionList);
          setFilteredSessions(sessionList);
        }
      }
      catch(e) {
         console.log(e);
      }
    }
      getSessions();
    }, [ props.campaignId]);

  useEffect(() => {
    if(props.urlCampaignExists && isUrlSession && !props.isUrlImageSearchPerformed){
      let session = sessions.filter(x => x.name === props.urlSession)[0];
      if(session){
        session.isSelected = false;
        onCheckboxChanged(session);
        setIsUrlSession(false);
        props.onUrlSessionFind();
      }
    }
  }, [sessions, isUrlSession, props.isUrlImageSearchPerformed, props.urlCampaignExists]);
    
    function onSelectAll(selectAll: boolean) {
      if(!selectAll)
      {
        filteredSessions.forEach(s => s.isSelected = true);
      } else {
        filteredSessions.forEach(s => s.isSelected = false);
      }

      setSessions(sessions.slice());
      let selectedSessions = sessions.filter(s => s.isSelected).map(s => s.id);
      props.onFilterChanged(selectedSessions);
      setSelectAll(!selectAll);
    }

  function onCheckboxChanged(session: ISession) {
    session.isSelected = !session.isSelected;
    setSessions(sessions.slice());
    let selectedSessions = sessions.filter(s => s.isSelected).map(s => s.id);
    props.onFilterChanged(selectedSessions);
    syncSelectAll();
  };

  function syncSelectAll(){
    let selectedSessions = sessions.filter((s) => s.isSelected);
    if (!selectAll && selectedSessions.length === sessions.length) {
      setSelectAll(true);
    } else if (selectAll && selectedSessions.length < sessions.length) {
      setSelectAll(false);
    }
  }

  function onSessionChange(value:string) {
    if(value=="")
    {
      syncSelectAll();
      setFilteredSessions(sessions);
    } else 
    {
      setFilteredSessions(sessions.filter(s => s.name.toLowerCase().includes(value.toLowerCase())));
    }
  };

  let sessionCheckboxes: JSX.Element[] = [];
  filteredSessions.forEach(session => {
    sessionCheckboxes.push(
      <FormControlLabel
        key={session.name}
        classes={{ label: session.isSelected ? controlClasses.labelSelected : controlClasses.label }}
        control={<Checkbox checked={session.isSelected} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500' }} />} onChange={() => onCheckboxChanged(session)} name={session.name} size={'small'} />}
        label={session.name}
      />
    );
  });

  let selectAllCheckbox = (
    <FormControlLabel
        key={"Select All"}
        classes={{ label: selectAll ? controlClasses.labelSelected : controlClasses.label }}
        control={<Checkbox style={{ color: '#F7B500', opacity: '0.5 !important' }} checked={selectAll} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500', opacity: '0.5 !important' }} />} onChange={() => onSelectAll(selectAll)} name={"Select All"} size={'small'} />}
        label={"Select All"}
      />
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FormGroup>
        <TextField  style={{ color: '#F7B500' }} id="session-filter" label="Search" onChange={(event) => onSessionChange(event.target.value)} />
          {selectAllCheckbox}
          {sessionCheckboxes}
        </FormGroup>
      </div>
    </div>
  );
}
