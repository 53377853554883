import React from 'react';
import {Box, Chip, Grid, Tooltip, Typography} from '@material-ui/core/';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExposureIcon from '@material-ui/icons/Exposure';
import LinkIcon from '@material-ui/icons/Link';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import poleIcon from "../../../images/custom_pole.png";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IGeneralSnackbarConfig } from '../../../models/IGeneralSnackbarConfig';
import { GeneralSnackbar } from '../../SnackBar/GeneralSnackbar/GeneralSnackbar';
import { UserIcon } from "../../UserIcon/UserIcon";

import moment from 'moment';

import { fetchWithAuthorisationHeader, postWithAuthorisationHeader, getAuthorizationData } from '../../../services/AuthenticationService';

import { IImage} from '../../../models/IImage';
import { IDZIViewerState} from '../../../models/IDZIViewerState';
import { IDZIData} from '../../../models/IDZIData';
import { ImageStatus} from '../../../models/ImageStatus';
import { IDefect } from '../../../models/IDefect';
import { IFormDetails } from '../../../models/IFormDetails';
import { DefectForm } from '../../DefectForm/DefectForm';
import { IRect} from '../../../models/IRect';
import { IImageStatus } from '../../../models/IImageStatus';
import { IDefectFavourite} from '../../../models/IDefectFavourite';
import { IUser } from '../../../models/IUser';

import styles from './DZIViewer.module.css';
import {DefectViewer} from '../../DefectViewer/DefectViewer';
import {DefectListItem, DefectOperationState, IDefectOperationEvent} from '../../DefectListItem/DefectListItem';

import { DZICarousel } from '../DZICarousel/DZICarousel';
import { IUserPreference } from '../../../models/IUserPreference';
import { UserPreference } from '../../../enums/UserPreference';
import { IDZIImage } from '../../../models/IDZIImage';
import { IAction } from '../../../models/IAction';
import { IImageDefectCount } from '../../../models/IImageDefectCount';

var XMLParser = require('react-xml-parser');

const StyledButton = withStyles({
  root: {
      fontSize: 14,
      backgroundColor: "#454748",

      "&:hover": {
          color: 'red',
          backgroundColor: "#95C5E7"
      },
  },
  label: {
    color: '#F9FCFF',
    "&:hover": {
        color: '#1E1E20',
    },
  }
})(Button);

interface IDZIViewerProps {
    organisationUsers:IUser[];
    image: IImage | null ;
    viewerState: IDZIViewerState | null;
    isExpanded: boolean;
    getViewerStateFlag?: number;
    defects:IDefect[],
    userPermissions: string[];
    onGetViewerState?: Function;
    onDefectsChanged: Function;
    imageStatuses: IImageStatus[];
    isDefectModeEnabled:boolean;
    onDefectModeChanged: Function;
    onSelectedDZIImageChanged: Function;
    updatedImageIds: number[] | null;
    actionPerformed: IAction | null;
    onDefectChanged: Function;
    canViewDefects: boolean;
    imageDefectCount: IImageDefectCount[];
};

interface IDZIViewerComponentState {
    isImageInitialised: boolean;
    isViewerInitialised: boolean;
    areDefectsInitialised:boolean;
    isLoaded: boolean;
    dziData: IDZIData | null;
    has2dImages: boolean;
    isLinked: boolean;
    isMouseOverImage: boolean;
    brightness: number;
    contrast:number;
    showFilters: boolean;
    isOpenSeadragonLoading: boolean;
    isDrawDefectModeEnabled: boolean;
    imageDefects:IDefect[],
    selectedDefect: IDefect | null;
    defectInDeleteState:IDefect | null;
    isDragging: boolean;
    anchorEl: any;
    showFormList: boolean;
    formList: IFormDetails[] | null;
    showDefectForm: boolean;
    selectedForm: IFormDetails | null;
    generalSnackbarConfig: IGeneralSnackbarConfig | null;
    canEditDefects: Boolean;
    canReadDefects: Boolean;
    defectFormIsOpen:Boolean;
    defectFavouritesList: IDefectFavourite[];
    showDefectFavouritesList: boolean;
    isRightSideCollapsed: boolean;
    selectedImage: IImage | null;
    linkedImages: IDZIImage[];
 };

export class DZIViewer extends React.Component<IDZIViewerProps,IDZIViewerComponentState> {
  viewer: any = null;
  filters: any = null;
  seaDragonDivId: string = 'dziviewer_' + new Date().getTime();
  zoomInButtomId: string = 'zoom_in_' + new Date().getTime();
  zoomOutButtomId: string = 'zoom_out_' + new Date().getTime();
  homeButtomId: string = 'home_' + new Date().getTime();
  baseUrl: string =  process.env.REACT_APP_VAA_API_URL != null ? process.env.REACT_APP_VAA_API_URL : '';
  wnd: any = window as any;
  filterMenuCloseTimeout: any = null;
  queuedImageId: number = 0;
  defaultBrightness: number = 0;
  defaultContrast: number = 1;
  drag:any = null;
  lastNewDefectName:any = null;
  defectColourPallete:string[] = ['#F7B500', '#BE8BFF', '#76D3FF', '#FF9B8C', '#D3FE88', '#FE95C5', '#B8FFDD', '#F8D99B', '#8591FF', '#71E28A'];
  defectColourPalletIdx:number = 0;
  defectUnnamedIdx:number = 0;
  maxDefectId = 0;
  formListElement : JSX.Element = <div></div>;
  defectFavouritesListElement : JSX.Element = <div></div>;


  constructor(props:IDZIViewerProps) {
    super(props);

    this.state = {
        isImageInitialised: false,
        isViewerInitialised: false,
        areDefectsInitialised:false,
        isLoaded: false,
        isLinked: true,
        has2dImages: false,
        dziData: null,
        isMouseOverImage: false,
        brightness: this.defaultBrightness,
        contrast: this.defaultContrast,
        showFilters: false,
        isOpenSeadragonLoading: true,
        isDrawDefectModeEnabled: false,
        imageDefects:[],
        selectedDefect: null,
        isDragging: false,
        anchorEl: null,
        showFormList: false,
        showDefectFavouritesList: false,
        formList: null,
        showDefectForm: false,
        selectedForm: null,
        generalSnackbarConfig: null,
        canEditDefects: false,
        canReadDefects:true,
        defectFormIsOpen:false,
        defectInDeleteState:null,
        defectFavouritesList: [],
        isRightSideCollapsed: true,
        selectedImage: null,
        linkedImages:[]
      };
  }

  componentDidMount() {
    if (this.props.viewerState != null) {
        this.setState({
            isLinked: this.props.viewerState.isLinked,
            brightness: this.props.viewerState.brightness,
            contrast: this.props.viewerState.contrast,
            isDrawDefectModeEnabled: this.props.viewerState.isDrawDefectModeEnabled,
            showDefectForm: this.props.viewerState.showDefectForm,
            formList: this.props.viewerState.formList,
            selectedImage: this.props.image
        });

        this.defectUnnamedIdx = 0;
    }

    if (this.props.image != null) {
        let imageId = this.props.image.id;
        this.setState({ selectedImage: this.props.image});
        this.getLinkedImages();
        this.getImages(imageId);
      }
     

    this.setState({ canReadDefects: this.props.userPermissions.includes('CanReadDefect')});

    let userCanEditDefects = this.props.userPermissions.includes('CanWriteDefect');
    this.setState({ canEditDefects: userCanEditDefects});

    if ( userCanEditDefects ){
        this.getDefectFavourites();
      }

      this.getUserPreferences();
  }

  componentDidUpdate(prevProps: IDZIViewerProps, prevState: IDZIViewerComponentState) {
      let this_ = this;

      if (this.props.image != null && this.props.updatedImageIds != null) {
          if (this.props.updatedImageIds != prevProps.updatedImageIds) {             
              let refreshLinkedImages: boolean = false;
              if (this.props.image.active != prevProps.image?.active)
                  refreshLinkedImages = true;
              this.props.updatedImageIds.forEach(id => {
                  if (this.state.linkedImages.some(x => x.id === id)) {
                      refreshLinkedImages = true;
                  }
              });
              if ( this.state.linkedImages.length == 0) {
                  this.getImages(this.props.image.id);
                  refreshLinkedImages = true;
              }
              if (this.props.actionPerformed != null && ['linkedImages', 'unlinkedImages'].indexOf(this.props.actionPerformed.type) >= 0)                
                  refreshLinkedImages = true;

              if (refreshLinkedImages)
                  this.getLinkedImages();
          }
      }

      if (this.state.imageDefects.length !== prevState.imageDefects.length) {
        //if the defect count is not same, pass the new count to parent, so we can show the red bar on image thumbnail.
        this.props.onDefectChanged(this.state.selectedImage?.id,this.state.imageDefects.length);
      }

    if ( !prevState.isViewerInitialised && this.state.isViewerInitialised && ( this.state.canReadDefects || this.state.canEditDefects ))
        this.refreshDefects({ redraw: this.props.isDefectModeEnabled, resetSelectedDefectState: true }); 

      if (this.state.isLinked && this.props.image != null && (prevProps.image == null || prevProps.image.id != this.props.image.id || prevProps.image.segmentId != this.props.image.segmentId)) {
      // The image has changed
      this.defectUnnamedIdx = 0;

      let imageId = this.props.image.id;
      
      this.setState({ 
        showFilters: false,
        isDrawDefectModeEnabled:false,
        isImageInitialised:false,
        areDefectsInitialised:false,
          imageDefects: [],
          selectedImage: this.props.image
      });

      if ( this.state.canReadDefects || this.state.canEditDefects )
        this.refreshDefects({ redraw: false, resetSelectedDefectState: true });  

        if (!this.state.isOpenSeadragonLoading) {
          this.getImages(imageId);
        
      }
      else {
        // Seadragon is still loading other imagery so queue this image up for loading.  Once it has finished loading it will then load this one
        this.queuedImageId = this.props.image.id;
      
        }
       

      if (this.viewer != null) {
          this.viewer.setMouseNavEnabled(true);
          
      }
    }
    
    if (this.props.getViewerStateFlag != prevProps.getViewerStateFlag && this.props.onGetViewerState != undefined) {

      this.props.onGetViewerState( {
                                    brightness:this.state.brightness,
                                    contrast:this.state.contrast,
                                    zoomLevel: this.getViewer().viewport.getZoom(),
                                    centre: this.getViewer().viewport.getCenter(),
                                    isLinked:true,
                                    isDrawDefectModeEnabled: this.state.isDrawDefectModeEnabled,
                                    formList:this.state.formList
                                  });

    }

    if (this.props.isExpanded != prevProps.isExpanded) {

        setTimeout(function(){
           this_.setState({});
        }, 10);
    }
  }

  componentWillUnmount() {
    if (this.viewer !== null) {
      this.viewer.destroy();
    }
  }


    onCarouselToggle() {
        this.setState({
            isRightSideCollapsed: !this.state.isRightSideCollapsed
        });
        this.updateUserPreference(UserPreference.LAST_DZI_CAROUSEL_STATE, (this.state.isRightSideCollapsed).toString());
    }
    handleChangeThumbnail(img: IImage) {
        if (img) {  
            
            this.setState({ selectedImage: img });
            this.props.onSelectedDZIImageChanged(img)
        }
    
    }
  getDefectFavourites() {
      
      // FYI this api method only returns defectFavourites that are linked to the latest versions of forms
      let url = this.baseUrl + 'defectDetection/defectFavourites';
      fetchWithAuthorisationHeader(url)
      .then(res =>
        {
          if (res.status === 200) {
            return res.data;
          }
          else {
             let msg = 'Server Error';
             if ( res.status === 401) msg = 'Unauthorised';
             if ( res.status === 404) msg = 'Not Found';

             throw Error(msg);
          }
        }
       )
      .then(
        (result) => {

          this.setState({
            defectFavouritesList: result as IDefectFavourite[]
          });

        },
        (error) => {
          console.log('Error getting ' + url + ': ' + error);
        }
      );
  }

    getUserPreferences() {
        fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "user/getUserPreferences").then(res => {
            if (res && res.status !== 200) return;

            var preferenceData: IUserPreference[] = res.data;


            var lastCarouselState = preferenceData.find(i => i.preferenceName == UserPreference.LAST_DZI_CAROUSEL_STATE);
            
            this.setState({ isRightSideCollapsed: lastCarouselState ? lastCarouselState.preferenceValue!='true' : true });
        });
    }

    updateUserPreference(preferenceName: string, value: string) {

        let data = {
            name: preferenceName,
            value: value
        };

        postWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "user/updateUserPreference", data).then(res => {
            if (res.data && res.status === 200) {
                var data = (res.data);
            }
        })
            .catch(err => {
                console.log('Error: Failed to update user preference with status code ' + err.status);
            });
    }

  getViewer() {
    let defaultZoomLevel: number = 1;
    var this_ = this;

    var authData = getAuthorizationData();
    if (this.viewer != null) {
      
        var authData = getAuthorizationData();
        this.viewer.ajaxHeaders = {
            "Authorization": 'Bearer ' + authData.token
        };
    }
    else{
        this.viewer = this_.wnd.OpenSeadragon({
            id: this.seaDragonDivId,
            showNavigator: true,
            navigatorMaintainSizeRatio: true,
            navigatorAutoFade: false,
            defaultZoomLevel: this.props.viewerState != null && this.props.viewerState.zoomLevel != defaultZoomLevel ? this.props.viewerState.zoomLevel : null,
            minZoomLevel: 1,
            maxZoomLevel: 20,
            visibilityRatio: 1,
            zoomInButton: this.zoomInButtomId,
            zoomOutButton: this.zoomOutButtomId,
            homeButton: this.homeButtomId,
            crossOriginPolicy: 'Anonymous',
            prefixUrl: '/javascripts/openseadragon-bin-2.4.2/images/',
            loadTilesWithAjax: true,
            ajaxHeaders: {
                            "Authorization": 'Bearer ' + authData.token,
                        }
            });

        // Force a render on resize
        this.viewer.addHandler('resize', function() {
        setTimeout(function(){
                this_.setState({});
            }, 10);
        });

        // Add a handler for tracking when seadragon is loading so that we can avoid trying to load multiple images at once
        this.viewer.world.addHandler('add-item', function(addItemEvent:any) {

            var tiledImage = addItemEvent.item;
            tiledImage.addHandler('fully-loaded-change', function(fullyLoadedChangeEvent:any) {

                this_.setState({isOpenSeadragonLoading: !fullyLoadedChangeEvent.fullyLoaded })

                if (this_.queuedImageId > 0 && fullyLoadedChangeEvent.fullyLoaded) {

                // seadragon has finished loading and another image has been queued for loading so load it
                let imageId = this_.queuedImageId;
                this_.queuedImageId = 0;

                this_.getImages(imageId);
                
                }
                else if ( this_.props.image != null && !this_.state.isImageInitialised){

                // Image has finished loading

                let imageId = this_.props.image.id;
                let imageDefects = this_.props.defects.filter(d=>d.imageId === imageId);

                this_.setState({imageDefects: imageDefects, isImageInitialised: true});

                if (this_.props.isDefectModeEnabled && this_.props.image && !this_.props.image.poleId) {
                   this_.setState({
                      generalSnackbarConfig: { messageType: 'info', message: "Note - Image is not linked to an asset", verticalAnchorOrigin: 'bottom', autoHideDuration: 3000 }
                   });
                }

                if (this_.props.isDefectModeEnabled ){
                    this_.clearDefectOverlays();
                    this_.drawDefectOverlays();
                    this_.setState({ isDrawDefectModeEnabled: this_.props.isDefectModeEnabled}); 
                }
              }
            });
        });

        this.viewer.addHandler('open', function(event:any) {
            if (this_.props.isDefectModeEnabled && (this_.state.canReadDefects || this_.state.canEditDefects)) {
                 this_.drawDefectOverlays();
            }
        });

        this.viewer.addHandler('canvas-nonprimary-press', function(event:any) {

            // User has started dragging
            if (!this_.state.canEditDefects || !this_.state.isDrawDefectModeEnabled) {
                return;
            }

            this_.setState({isDragging:true});

            let viewportPos = this_.viewer.viewport.pointFromPixel(event.position);
            let overlayElement:any = null;
            if (this_.state.selectedDefect) {
                // Editing an existing defect
                let seadragonOverlay: any = this_.viewer.getOverlayById(this_.state.selectedDefect.id?.toString());
                if ( seadragonOverlay != null)
                    overlayElement = seadragonOverlay.element;
            }
            else {

                // // Creating a new defect
                overlayElement = document.createElement('div');
                this_.lastNewDefectName = '';
                overlayElement.id = '-1';
                overlayElement.style.border = '2px solid ' +  this_.getDefectColour(); 
                this_.viewer.addOverlay(overlayElement, new this_.wnd.OpenSeadragon.Rect(0, 0, 0, 0));
            }

            this_.drag = {
                overlayElement: overlayElement,
                startPos: viewportPos
            };
        });

        this.viewer.addHandler('canvas-nonprimary-release', function(event:any) {
            
            // User has finished dragging
            if (!this_.state.canEditDefects || !this_.state.isDragging || this_.drag == null )
                return;

            this_.setState({isDragging:false});

            var viewportPos = this_.viewer.viewport.pointFromPixel(event.position);
            var diffX = viewportPos.x - this_.drag.startPos.x;
            var diffY = viewportPos.y - this_.drag.startPos.y;

            var location = new this_.wnd.OpenSeadragon.Rect(
                Math.min(this_.drag.startPos.x, this_.drag.startPos.x + diffX),
                Math.min(this_.drag.startPos.y, this_.drag.startPos.y + diffY),
                Math.abs(diffX),
                Math.abs(diffY)
            );

            var imageSelectionRect = this_.viewer.viewport.viewportToImageRectangle(location);

            // if the user has drawn too small a defect reject it with a warning
            let minSize = 20;
            if ( imageSelectionRect.height < minSize || imageSelectionRect.width < minSize ){
                this_.viewer.removeOverlay(this_.drag.overlayElement);
                this_.setState({
                    generalSnackbarConfig: { messageType: 'warning', message: 'Please draw a larger defect bounding box to continue', verticalAnchorOrigin: 'bottom', autoHideDuration: 3000 }
                });
                return;
            }

            //Get a base64 representation of the selected part of the image
            var viewportRect = this_.viewer.viewport.imageToViewportRectangle(imageSelectionRect);

            var webRect = this_.viewer.viewport.viewportToViewerElementRectangle(viewportRect);
            const { x, y, width, height } = webRect || {};
            const { canvas } = this_.viewer.drawer;
            let source = canvas.toDataURL();

            const img = new Image();

            img.src = source;

            img.onload = () => {
                let croppedCanvas = document.createElement('canvas');
                let ctx = croppedCanvas.getContext('2d');
                if ( ctx == null)
                    return;

                croppedCanvas.width = width;
                croppedCanvas.height = height;

                const pixelDens = this_.wnd.OpenSeadragon.pixelDensityRatio;
                ctx.drawImage(img, x*pixelDens, y*pixelDens, width*pixelDens, height*pixelDens, 0, 0, width, height);
                let croppedSrc = croppedCanvas.toDataURL();

                if (this_.props.image != null) {

                    let defects = [...this_.state.imageDefects];

                    let geometry = { left: Math.round(imageSelectionRect.x),
                                     top: Math.round(imageSelectionRect.y),
                                     height: Math.round(imageSelectionRect.height),
                                     width: Math.round(imageSelectionRect.width) };

                    if (this_.state.selectedDefect?.id && this_.state.selectedDefect?.id >= 0) {
                        // Update existing defect
                        let defect = this_.state.imageDefects.find(d=>d.id == this_.state.selectedDefect?.id);
                        if (defect) {
                            defect.imageBase64 = croppedSrc;
                            defect.geometry = JSON.stringify(geometry);

                            this_.updateDefect(defect).then((updatedDefect:IDefect) => {
                                if (updatedDefect && defect) {
                                    defect.dateUpdated = updatedDefect.dateUpdated;
                                    defect.updatedBy = updatedDefect.updatedBy;
                                    this_.replaceLocalDefect(defect, true); 
                                }
                                this_.setState({selectedDefect:null});
                            
                            });
                        }
                        
                    }
                    else {
                        // Add a new defect
                        let defect: IDefect = {
                            name: '',
                            eleName:this_.drag.overlayElement.id.toString(),
                            imageId: this_.props.image.id,
                            status: "Created",
                            isReadOnly: false,
                            imageBase64: croppedSrc,
                            geometry: JSON.stringify(geometry),
                            id: -1,
                            formId: 0,
                            formData: "",
                            colour: this_.getCurrentDefectColour(),
                            createdBy : "",
                            updatedBy : "",
                            dateCreated: new Date(),
                            dateUpdated: new Date(),
                            createdByMachineLearning: false,
                        }
                        

                          //savedefect
                          this_.saveDefect(defect).then((postConditionDefect:IDefect) => {
                              defect.id = postConditionDefect.id;
                              defect.createdBy = postConditionDefect.createdBy;
                              defect.updatedBy = postConditionDefect.updatedBy;

                              defect.name  = 'Defect ' + (this_.getMaxUnnamedDefectId() + 1); //uses n not n-1 for name
                              this_.updateDefect(defect).then((updatedDefect:IDefect) => {

                                  defect.createdBy = updatedDefect.createdBy;
                                  defect.updatedBy = updatedDefect.updatedBy;
                                  defect.dateCreated = updatedDefect.dateCreated;
                                  defect.dateUpdated = updatedDefect.dateUpdated;
                              })

                              // this_.lastNewDefectName = null;
                              let clonedDefects = JSON.parse(JSON.stringify(this_.state.imageDefects));

                              clonedDefects.push(defect);
                              this_.setState({imageDefects: clonedDefects});
                              this_.addDefectOverlay(defect);
                              this_.viewer.removeOverlay('-1');
                              //this_.resetSelectedDefect();
                          })
                        
                          .catch( error => {
                            console.error(error);

                          });
                        
                    }
                }

                //for debugging: display the cropped image in an img with id defecttest
                //fetch(croppedSrc)
                //    .then(res => res.blob())
                //    .then(blob => {
                //        const file = new File([blob], "File name", { type: "image/png" })
                //        let urlTmp = URL.createObjectURL(file);

                //        this_.wnd.$("#defecttest").attr("src",urlTmp);
                //     })
            }
        });

    //make space for menu bar
    this.wnd.$('[id^=navigator-].navigator').css('top','50px');

        if (this.state.canEditDefects) {
            this.addMouseHandlerForDefects();
        }

        if (this.viewer.buttons != null) {
        let fullScreenButton = this.viewer.buttons.buttons.pop();
        this.viewer.buttons.element.removeChild(fullScreenButton.element);
        }

        let filterOptions = {
        showControl: false, //show button or not
        };

        this.filters = this.viewer.imagefilters(filterOptions);

        if (this.props.viewerState != null ) {
            let centre = this.props.viewerState.centre;
            let brightness = this.props.viewerState.brightness;
            let contrast = this.props.viewerState.contrast;

            setTimeout(function(){
                this_.viewer.viewport.panTo( centre );

                this_.wnd.$('#osd-filter-brightness').val(brightness);
                this_.wnd.$('#osd-filter-contrast').val(contrast);
                this_.filters.updateFilters();
            }, 100);
        }

        this_.setState({isViewerInitialised: true});
    }
    return this.viewer;
  }

  addDefectOverlays(defects:IDefect[]) {

      defects.forEach(d=> { if (d.id) this.viewer.removeOverlay(d.id.toString())});
      defects.forEach(d=> {if (d.id) return;this.addDefectOverlay(d)} );
  }

  addMouseHandlerForDefects() {
    let this_ = this;

    // Add a mouse handler for dragging that will handle updating the drag window size
    new this_.wnd.OpenSeadragon.MouseTracker({
        element: this_.viewer.element,
        moveHandler: function(event:any) {
            
            // User is dragging
            if (!this_.state.canEditDefects || !this_.drag || !this_.state.isDragging) {
                return;
            }

            var viewportPos = this_.viewer.viewport.pointFromPixel(event.position);
            var diffX = viewportPos.x - this_.drag.startPos.x;
            var diffY = viewportPos.y - this_.drag.startPos.y;
            
            var location = new this_.wnd.OpenSeadragon.Rect(
                Math.min(this_.drag.startPos.x, this_.drag.startPos.x + diffX),
                Math.min(this_.drag.startPos.y, this_.drag.startPos.y + diffY),
                Math.abs(diffX),
                Math.abs(diffY)
            );

            this_.viewer.updateOverlay(this_.drag.overlayElement, location);
        }
    });

  }

  toggleIconColor(){
    if ( this.filters == null || this.filters.toggleButton == null || this.filters.navImages == null)
      return;

    this.filters.toggleButton.imgRest.src = process.env.PUBLIC_URL + '/images/' + this.filters.navImages.imagetools.ACTIVE;
    }

    getLinkedImages() {
        if (this.props.image && this.props.image.segmentId){
            let linkedImagesUrl = this.baseUrl + "segment/fetchLinkedImagesBySegment/" + this.props.image.segmentId + "/" + this.props.image.campaignId;
            fetchWithAuthorisationHeader(linkedImagesUrl)
                .then(res => {
                    if (res && res.status == 200 && this.props.image) {
                        this.setState({
                            linkedImages: res.data
                        });
                        this.state.linkedImages.forEach(item => item.statusColorCode = "#" + this.getImageStatusColour(item))
                    }
                })
                .catch(err => {
                    console.log('Error: Failed to get ' + linkedImagesUrl + ' with status code ' + err.status);
                    this.setState({
                        linkedImages: []
                    });
                });
        }
        else {
            this.setState({
                linkedImages: []
            });
        }
    }

    getImages(imageId: number) {
        this.setState({ isOpenSeadragonLoading: true });

        let imagesUrl = this.baseUrl + 'dzi/dziImages/' + imageId;

        fetchWithAuthorisationHeader(imagesUrl)
            .then(res => {
                if (res.status === 200) {
                    return res.data;
                }
                else {
                    let msg = 'Server Error';
                    if (res.status === 401) msg = 'Unauthorised';
                    if (res.status === 404) msg = 'Not Found';

                    throw Error(msg);
                }
            }
            )
            .then(
                (result) => {
                    let dziData = result as IDZIData;

                    this.setState({
                        has2dImages: true,
                        isLoaded: true,
                        dziData: dziData
                    });

                    this.showViewer(dziData);
                    if (this.state.linkedImages && this.state.linkedImages.length != 0) {
                        if (this.state.linkedImages[0].poleId != this.state.selectedImage?.poleId) {
                            this.getLinkedImages();
                        }
                    }
                    else
                        this.getLinkedImages();
        },
        (error) => {

          console.log('Error getting ' + imagesUrl + ': ' + error);

          this.setState({
            has2dImages: false,
            isLoaded: true,
            isOpenSeadragonLoading: false
          });

          this.showNoImageryImage();

        }
      );
  }

  // This converts the XML into a DZI tile source specification object that OpenSeadragon understands.
  tileSourceFromData (data:any, filesUrl:any) {
    var xml = new XMLParser().parseFromString(data);
    var image = xml.getElementsByTagName('Image')[0];
    var size = xml.getElementsByTagName('Size')[0];

    var dzi = {
        Image: {
            xmlns: image.attributes['xmlns'],
            Url: filesUrl,
            Format: image.attributes['Format'],
            Overlap: image.attributes['Overlap'],
            TileSize: image.attributes['TileSize'],
            Size: {
                Height: size.attributes['Height'],
                Width: size.attributes['Width']
            },
        }
    };
    return dzi;
  };

  showNoImageryImage() {
    this.getViewer().open({
                  type: 'image',
                  url: process.env.PUBLIC_URL + '/images/no2DImagery.png'
                  });
  }

  showViewer(dziData:IDZIData) {
    if (!this.state.isLoaded) {
      return;
    }

    if (!this.state.has2dImages) {
      this.showNoImageryImage();
      return;
    }

    let dziIndex = 1;

      let dziFilesUrl = this.baseUrl + "dzi/" + dziData.awsS3Bucket + "/" + dziData.dziFilePath + "/" + dziIndex + "_files/";      
      this.getViewer().open(this.tileSourceFromData(dziData.dziFileData, dziFilesUrl));
   }

  onMouseEnterFilterMenu() {
    clearTimeout( this.filterMenuCloseTimeout );
    this.filterMenuCloseTimeout = null;
  }

  onMouseLeaveFilterMenu() {
    let this_ = this;

    if (this.state.showFilters) {
      this_.filterMenuCloseTimeout = setTimeout(function(){
          this_.setState({
            showFilters: false
        })
      }, 1000);
    }
  }

  onChangeBrightness(val:any) {
    // We need to use jquery to set the filter value as there is a hidden input control
    this.setState({brightness:val});
    this.wnd.$('#osd-filter-brightness').val(val);
    this.filters.updateFilters();
  }

  onChangeContrast(val:any) {
    // We need to use jquery to set the filter value as there is a hidden input control
    this.setState({contrast:val});
    this.wnd.$('#osd-filter-contrast').val(val);
    this.filters.updateFilters();
  }

  clearDefectOverlays() {
    return new Promise((resolve) => {
    if (this.state.imageDefects.length <= 0) resolve(0);
    this.state.imageDefects.forEach(d=> {if(d.id) this.viewer.removeOverlay(d.id.toString())} );
    //this.setState({imageDefects:[]});
    resolve(0);
    })
  }

  drawDefectOverlays() {
      if (this.state.imageDefects.length <= 0) return;
      for (let i = 0; i < this.state.imageDefects.length; i++) 
        this.addDefectOverlay(this.state.imageDefects[i]);
  }

  getMaxUnnamedDefectId():number {
    let arr:IDefect[] = this.state.imageDefects;
    let max:number = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name.startsWith('Defect')) {
        var val:number = Number.parseInt(arr[i].name.trim().split(' ',2)[1].trim());
        if (val && val > max) {
          max = val;
        }
      }
    }
    return max;
  }

  refreshDefects(params={redraw:true, resetSelectedDefectState: true}) {
      if (!this.props.image) return;
      this.defectUnnamedIdx = this.maxDefectId = this.defectColourPalletIdx = 0;

      let url = this.baseUrl + "form/fetchDefectList/" + this.props.image.id; 
      let fetchedDefects:IDefect[] = [];

      this.clearDefectOverlays().then(() => {
        fetchWithAuthorisationHeader(url).then((result) => {
          if (!result) return;
          for (let i = 0; i < result.data.length; i++) {
              if (result.data[i].colour===null || result.data[i].colour === "#")
                result.data[i].colour = this.getDefectColour();
              if (result.data[i].name == null)
                result.data[i].name = 'Defect ' + (this.getMaxUnnamedDefectId() + 1); //uses n not n-1 for name
              if (result.data[i].id > this.maxDefectId) 
                this.maxDefectId = result.data[i].id;

              //this.state.imageDefects.push(result.data[i]);
              fetchedDefects.push(result.data[i]);                            
          }

          this.setState({ imageDefects: fetchedDefects, areDefectsInitialised:true });

          if (params.redraw) this.drawDefectOverlays();
          this.notifyDefectsHaveChanged();
          if (params.resetSelectedDefectState)  this.setState({selectedDefect: null});
        })
      });
  }

  updateDefect(defect:IDefect) {

    let url = this.baseUrl + "form/updateDefect";
    //if ( defect && defect.id)
      //this.viewer.removeOverlay(defect.id.toString());

    return new Promise<IDefect>((resolve, reject) => {
      if (!defect || !defect.id) reject({msg:"no defect to update"});
      postWithAuthorisationHeader(url, defect).then((result) => {
        if (result && result.status === 200){
          let r = result.data as IDefect;
          resolve(r);
        }
        else {
          reject(result);
        }
      })
    })

  }
  /**update the state.imageDefects and redraw is set to true */
  replaceLocalDefect(d:IDefect, redraw:boolean, highlight:boolean=false) {
    if (!d || !d.id) return;
    let idx:number = -1;
    for (let i = 0; i < this.state.imageDefects.length; i++) {
      if (d.id && this.state.imageDefects[i].id === d.id) {
        idx = i;
        break;
      }
    }
    if (idx >= 0) {
      var clonedDefects = JSON.parse(JSON.stringify(this.state.imageDefects));
      clonedDefects[idx] = d;
      this.setState({imageDefects:clonedDefects});
      if (redraw && d.id) {
        this.viewer.removeOverlay(d.id.toString());
        this.addDefectOverlay(d, highlight);
      }
    }
  }

  saveDefect(defect:IDefect) {
    let url = this.baseUrl + "form/saveDefect";

    return new Promise<IDefect>((resolve, reject) => {
      postWithAuthorisationHeader(url, defect)
        .then((result) => {
          if (!result || result.status !== 200) reject(null);
          let r = result.data as IDefect;
          resolve(r);
        })

    });
  }

  onDefectHover(defect: IDefect, hoverEvent:string) {
    let highlight = false;
    if(hoverEvent == "onMouseEnter"){
      highlight = true;
    }
    this.replaceLocalDefect(defect, true, highlight);
  }

  addDefectOverlay(defect: IDefect, highlight:boolean=false) {
      if (!defect.id) return;
        let geom:IRect = JSON.parse(defect.geometry);
        let osRect = new this.wnd.OpenSeadragon.Rect(geom.left, geom.top, geom.width, geom.height);

        var viewportRect = this.viewer.viewport.imageToViewportRectangle(osRect);
        var overlayElement = document.createElement('div');
        overlayElement.id = defect.id.toString();

        overlayElement.style.border = '2px solid ' + defect.colour; 
        overlayElement.style.backgroundColor = highlight ? defect.colour + '4D': '';//4D is hex code for 30% opacity

        this.viewer.addOverlay(overlayElement, new this.wnd.OpenSeadragon.Rect(viewportRect.x, viewportRect.y, viewportRect.width, viewportRect.height));
  }

  getDefectColour() {
      if ( this.defectColourPalletIdx >= this.defectColourPallete.length-1)
        this.defectColourPalletIdx = 0;
      return this.defectColourPallete[++this.defectColourPalletIdx];
  }

    getCurrentDefectColour() {
      return this.defectColourPallete[this.defectColourPalletIdx];
    }

  onResetFilters() {
    this.setState({contrast:this.defaultContrast, brightness: this.defaultBrightness});
    this.wnd.$('#osd-filter-brightness').val(this.defaultBrightness);
    this.wnd.$('#osd-filter-contrast').val(this.defaultContrast);
    this.filters.updateFilters();
  }

  formatUTCDateForLocal(utcDate: Date): string {
    return moment.utc(utcDate,"DD-MM-YYYY hh:mm:ss a").local().format('DD/MM/YY HH:mm:ss');
  }

  deleteDefect(defect: IDefect) {
      return new Promise((resolve,reject) => {
      if (defect.isReadOnly) {
        console.log("Read only defect cannot be deleted");
        reject(defect.status);
      }
      let url = this.baseUrl + "form/deleteDefect";
          postWithAuthorisationHeader(url,defect).then(result => {
            if (!result || result.status != 200) reject(result);
            this.setState({selectedDefect:null});
            resolve(result);

          });
      })
  }

  notifyDefectsHaveChanged() {
        if ( this.props.image == null)
            return;

        let imageId = this.props.image.id;

        let defects = this.props.defects.filter(d=>d.imageId !== imageId);

        this.state.imageDefects.forEach(d=> defects.push(d) );

        this.props.onDefectsChanged(defects);
    }

   saveFormType(form: IFormDetails, defect:IDefect) {
        let status = 'Draft';
        if (!this.state.selectedDefect?.id) return;
        //Assign the form to the defect
        let fetchUrl = process.env.REACT_APP_VAA_API_URL + "form/saveFormType" + "/" + this.state.selectedDefect?.id + "/" + form.id + "/" + status;

        fetchWithAuthorisationHeader(fetchUrl).then(res => {
            defect.name = form.name;
            defect.status = status;
            defect.formId = form.id;
            this.setState({selectedDefect:defect,showFormList: false, showDefectFavouritesList: false,showDefectForm: true, selectedForm: form});
            //update data but no need to redraw
            this.replaceLocalDefect(defect, false);
            this.updateDefect(defect);
            
        })
        .catch(err => {
            console.log('Error: Failed to get ' + fetchUrl + ' with status code ' + err.status);

        });
   }

   onFormIconClick(e: any, defect: IDefect) {
       this.setState({ selectedDefect: defect });
       this.replaceLocalDefect(defect, true);
       if (defect && defect.formId && defect.formId > 0) {
            //If formId is assigned, retrieve the stored form
            let fetchUrl = process.env.REACT_APP_VAA_API_URL + "form/fetchFormDetailsByDefectId/" + defect.id;

            fetchWithAuthorisationHeader(fetchUrl).then(res => {
                this.setState({ showFormList: false, showDefectForm: true, selectedForm: res.data });

                })
                .catch(err => {
                    console.log('Error: Failed to get ' + fetchUrl + ' with status code ' + err.status);
                });
       }
       else {
            //If form is not assigned, show the form type list
            if (this.state.formList && this.state.formList.length > 0 && !defect.isReadOnly) {
                if (this.state.formList.length == 1) { //If only one form exists, store the form id and show the form directly, without showing the form list
                    this.saveFormType(this.state.formList[0], defect);
                }
                else {
                    this.setState({ showDefectForm: true,selectedForm: null });
                }
            }
       }
   }

   onDefectFavouriteClick(e: any) {
        if (this.state.defectFavouritesList && this.state.formList) {
            let selectedFavourite = this.state.defectFavouritesList.find(x => x.name == e.target.innerText);
            if (selectedFavourite != null) {
              let selectedFavouriteFormId = selectedFavourite.formId;
              let formSelected = this.state.formList.find(x => x.id == selectedFavouriteFormId);
              let localDefect = this.state.imageDefects.find(x => x.id === this.state.selectedDefect?.id);

              if (localDefect && formSelected) {
                  localDefect.formId = selectedFavourite.formId;
                  localDefect.formData = selectedFavourite.formData;
                  formSelected.formData = selectedFavourite.formData;
                  this.saveFormType(formSelected, localDefect);
              }
            }
        }
   }

   onFormTypeClick(e: any) {
        if (this.state.formList) {
            let formSelected = this.state.formList.find(x => x.name == e.target.innerText);
            if (formSelected) {
              var localDefect = this.state.imageDefects.find(x => x.id === this.state.selectedDefect?.id);
              if (localDefect) {
                formSelected.formData = '';
                this.saveFormType(formSelected, localDefect);
              }
                
            }
        }
   }

   fetchFormList() {
        if (!this.state.formList) {
            //Get the types of forms to be displayed to the user
            let fetchUrl = process.env.REACT_APP_VAA_API_URL + "form/fetchFormList";

            fetchWithAuthorisationHeader(fetchUrl).then(res => {
                this.setState({ formList: res.data });
             })
             .catch(err => {
                 console.log('Error: Failed to get ' + fetchUrl + ' with status code ' + err.status);
             });
        }
   }

   getImageStatusColour(image:IImage) {
        var status = image != null ? image.status : null;

        if (status != null) {
            let statusObj = this.props.imageStatuses.find(is=>is.status === status);
            if (statusObj != null) return statusObj.colour;
       }

        return  'ffffff';
    }

  render()  {

    if (!this.state.isLoaded || this.props.image == null) {
      return <div></div>;
    }

    const menuBarStyle = {
      display: this.state.isMouseOverImage && !this.state.isDragging ? 'block' : 'none'
    };

    const filterMenuStyle = {
      display: this.state.showFilters && this.state.isMouseOverImage && !this.state.isDragging ? 'block' : 'none'
    };

    let statusColour =  '#' + this.getImageStatusColour(this.props.image);

    const imageStatusChipStyle = {
        color: statusColour,
        borderColor: statusColour,
    };
     
   function linkStatusColour(poleId:string, active:boolean) {
    if(poleId && active)
    {
      return '#AED581';
    }
    else if(poleId && !active)
    {
      return  '#fafcff';
    }
    else
    {
      return '#FFB74D';
    }
    }

    const statusIconStyle = {
        color: linkStatusColour(this.props.image.poleId,this.props.image.active),
        height: 18
    };
      let statusIcon = this.props.image.poleId ? <LinkIcon style={statusIconStyle} /> : <CancelIcon style={statusIconStyle} />
      let statusText = this.props.image.poleId ? ImageStatus.Linked : ImageStatus.NoLink;

    const statusContainerStyle = {
        color: linkStatusColour(this.props.image.poleId,this.props.image.active),
        marginRight:15
    };


    let filtersApplied = this.state.brightness != this.defaultBrightness || this.state.contrast != this.defaultContrast;

    const filterIconStyle = {
      color: filtersApplied ? '#95C5E7' : '#F9FCFF'
    };
     

    let defectToolbar: JSX.Element =  <div></div>;
    let defectListDefects: JSX.Element[] = [];


    let defectListStyle = {
        top: this.wnd.$('#defectToolbar').position() != undefined ? this.wnd.$('#defectToolbar').position().top + this.wnd.$('#defectToolbar').height() + 10 : 135
    }


    if (this.props.isDefectModeEnabled && this.state.imageDefects.length > 0 ) {
      for (let i = 0; i < this.state.imageDefects.length; i++) {
          defectListDefects.push(
            <DefectListItem
              organisationUsers={this.props.organisationUsers}
              defect={this.state.imageDefects[i]}
              focusedDefect={this.state.defectInDeleteState}
              onHover={(defect:IDefect, toggleEvent:string) => {this.onDefectHover(defect, toggleEvent);}}
              canRead={this.state.canReadDefects}
              canEdit={this.state.canEditDefects && !this.state.imageDefects[i].isReadOnly}
              canForm={!(!this.state.formList || ((!this.state.canEditDefects || this.state.imageDefects[i].isReadOnly) && this.state.imageDefects[i].status == "Created"))}// disable icon if there is no formlist or no associated form while in read only mode
              onOperation = {(op:IDefectOperationEvent) => {
                if (!op || !op.operation) return;
                switch (op.operation) {
                  case DefectOperationState.FormAction:
                    this.onFormIconClick(op.event,op.defect);
                    break;
                  case DefectOperationState.Edit:
                    this.setState({selectedDefect:op.defect, defectInDeleteState:null})
                    break;
                  case DefectOperationState.DeleteActionRequired:
                    this.setState({defectInDeleteState:op.defect});
                    break;
                  case DefectOperationState.Delete:
                      this.deleteDefect(op.defect).then(() => {
                        if (!op.defect || !op.defect.id) return;
                      this.viewer.removeOverlay(op.defect.id.toString());
                      for (let i = 0; i < this.state.imageDefects.length; i++) {
                        if (this.state.imageDefects[i].id === op.defect.id) {
                          var clonedDefects = JSON.parse(JSON.stringify(this.state.imageDefects)) as IDefect[];
                          clonedDefects.splice(i,1);
                          this.setState({imageDefects:clonedDefects, defectInDeleteState:null});
                          break;
                        }
                      }
                    });
                    break;
                  default:break;
                }
              }}
            />
          );
        };
      }

      let formMenuItems = [];

      if (this.state.formList) {
          if (this.state.formList.length > 1) {
              for (var i = 0; i < this.state.formList.length; i++) {
                  formMenuItems.push(
                    <Tooltip 
                    placement='left'
                      arrow={true}  
                      enterDelay={500} 
                      enterNextDelay={500}
                      title={this.state.formList[i].name}
                    >
                      <MenuItem
                          className={styles.formMenuItem}
                          onClick={(e: any) => this.onFormTypeClick(e)}
                      >
                          {this.state.formList[i].name}
                      </MenuItem>
                      </Tooltip>)
              }
              this.formListElement = (
                  <Menu
                      id="form-menu"
                      className={styles.userMenu}
                      anchorEl={this.state.anchorEl}
                      elevation={1}
                      getContentAnchorEl={null}
                      keepMounted
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}
                      transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      open={Boolean(this.state.anchorEl)}
                      onClose={() => this.setState({ showFormList: false })}
                      PaperProps={{
                          style: {
                              maxHeight: 216,
                              width: '32ch'
                              
                          },
                      }}
                  >
                      {formMenuItems}
                  </Menu>
              );
          }
      } else {
        this.formListElement = <div></div>
      }

      let defectFavouritesMenuItems = [];

      if (this.state.defectFavouritesList) {
            for (var i = 0; i < this.state.defectFavouritesList.length; i++) {
                defectFavouritesMenuItems.push(
                <Tooltip 
                    key={i}  
                    placement='left'
                    arrow={true}  
                    enterDelay={500} 
                    enterNextDelay={500}
                    title={this.state.defectFavouritesList[i].name}
                >
                    <MenuItem
                        className={styles.formMenuItem}
                        onClick={(e: any) => this.onDefectFavouriteClick(e)}
                    >
                        {this.state.defectFavouritesList[i].name}
                    </MenuItem>
                    </Tooltip>)
            }
            this.defectFavouritesListElement = (
                <Menu
                    id="form-menu"
                    className={styles.userMenu}
                    anchorEl={this.state.anchorEl}
                    elevation={1}
                    getContentAnchorEl={null}
                    keepMounted
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(this.state.anchorEl)}
                    onClose={() => this.setState({ showDefectFavouritesList: false })}
                    PaperProps={{
                        style: {
                            maxHeight: 216,
                            width: '32ch'
                              
                        },
                    }}
                >
                    {defectFavouritesMenuItems}
                </Menu>
            );
      } else {
        this.defectFavouritesListElement = <div></div>
      }


    //The Dzi Viewer can be instantiated without a fully populated image object. eg from AIMs external data source feature
    //In this case we will have just the id so we need to make sure we dont try to disply any info that is not available
    let hasFullImage = this.props.image.status != null && this.props.image.status.length > 0;

    return (
        <div 
        className={styles.dziViewer} 
        onMouseEnter={() => { if (this.state.defectFormIsOpen) return; this.setState({ isMouseOverImage:  true })}}
        onMouseLeave={() => { if (this.state.defectFormIsOpen) return;this.setState({ isMouseOverImage: false, showFilters: false })}}
        >
        <div id="leftSide" className={styles.leftSide}>
        <div id="defectToolbar" className={styles.defectToolbar}>
          {this.state.canReadDefects && (
            <DefectViewer
              defects={this.state.imageDefects}
              isDefectModeEnabled={this.props.isDefectModeEnabled}
              isDefectFetchingComplete={true}
              onClick={() => {
                let newStateIsEnabled = !this.props.isDefectModeEnabled;

                if (newStateIsEnabled) {
                  this.setState({
                    generalSnackbarConfig: { messageType: 'info', message: 'Use the right mouse button to draw defects', verticalAnchorOrigin: 'bottom', autoHideDuration: 3000 }
                  });

                  this.fetchFormList();

                  let msgAutoHideDuration = 3000;
                  let showingMessage = false;
                  if (this.props.image && !this.props.image.poleId) { 
                    showingMessage = true;
                    this.setState({
                        generalSnackbarConfig: { messageType: 'info', message: "Note - Image is not linked to an asset", verticalAnchorOrigin: 'bottom', autoHideDuration: msgAutoHideDuration }
                    });
                  }
                  
                  let msgConfig = { messageType: 'info', message: 'Use the right mouse button to draw defects', verticalAnchorOrigin: 'bottom', autoHideDuration: msgAutoHideDuration };
                  if ( showingMessage ){
                    //Set a timeout and then show this message after the last message has disappeard
                    let this_ = this;
                    setTimeout(function(){
                        this_.setState({generalSnackbarConfig: msgConfig});
                    }, msgAutoHideDuration + 1000);
                  }
                  else {
                      this.setState({
                        generalSnackbarConfig: msgConfig
                      });
                  }

                  this.state.imageDefects.forEach((d) =>
                    this.addDefectOverlay(d)
                  );
                } else {
                  this.state.imageDefects.forEach((d) => {
                    if (d.id) this.viewer.removeOverlay(d.id.toString());
                  });
                }
                this.setState({
                  isDrawDefectModeEnabled: newStateIsEnabled ? true : false,
                  selectedDefect: null,
                });
                this.props.onDefectModeChanged(newStateIsEnabled);
              }}
            />
          )}
        </div>
            {this.props.isDefectModeEnabled &&
             this.state.imageDefects.length > 0 &&
             !this.state.isDragging &&
             (
                <div>
                    <div className={styles.defectList} style={defectListStyle}>
                    <div className={styles.defectListHeader}>Defects</div>
                    <div className={styles.defectsContainer}>
                        {defectListDefects}
                    </div>
                    </div>
                    <div>{this.state.showFormList && this.formListElement}</div>
                    <div>{this.state.showDefectFavouritesList && (this.defectFavouritesListElement)}</div>
                </div>
             )}
        <div className={styles.menuBar} style={menuBarStyle}>
          <div className={styles.menuBarContent}>
            <Grid
              container
              spacing={0}
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ flexWrap: "nowrap" }}
            >
              <Grid item zeroMinWidth>
                <Grid container style={{ flexWrap: "nowrap" }}>
                  <Grid item zeroMinWidth>
                    <Tooltip title={this.props.image.campaignName}>
                      <Typography style={{ fontSize: 14 }} noWrap>
                        {this.props.image.campaignName}
                      </Typography>
                    </Tooltip>
                  </Grid>

                  {hasFullImage && this.props.image.pack != null &&  (
                    <Grid item zeroMinWidth>
                      <Tooltip title={this.props.image.pack}>
                        <Typography style={{ fontSize: 14 }} noWrap>
                          <ArrowForwardIosIcon
                            style={{
                              height: 10,
                              color: "#80837E",
                              marginLeft: -5,
                            }}
                          />
                          {this.props.image.pack}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>

                <Grid container style={{ flexWrap: "nowrap" }}>
                  <Grid item xs={12} sm={"auto"} style={{ fontSize: 12 }}>
                    { hasFullImage && this.formatUTCDateForLocal(this.props.image.created)}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container style={{ flexWrap: "nowrap" }}>
                  <Grid item>
                    <Box
                      style={{ marginTop: 4, marginLeft: 15, marginRight: 15 }}
                    >
                      {this.props.image.status && (
                        <Chip
                          label={this.props.image.status || "---"}
                          style={imageStatusChipStyle}
                          variant="outlined"
                        />
                      )}
                    </Box>
                  </Grid>

                  <Grid item>
                    <div
                      className={styles.statusContainer}
                      style={statusContainerStyle}
                    >
                    { hasFullImage &&
                      <Box display="flex" flexDirection="row">
                        <span className={styles.statusText}>{statusText}</span>
                        <div className={styles.statusIcon}>{statusIcon}</div>
                      </Box>
                      }
                    </div>
                  </Grid>

                  <Grid item>
                    {this.props.image.poleId && (
                      <Box
                        style={{
                          marginLeft: 8,
                          marginTop: 8,
                          paddingRight: 22,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <img
                          className={styles.iconInLine}
                          src={poleIcon}
                          style={{ marginRight: "5px" }}
                        ></img>
                        {this.props.image.poleId}
                      </Box>
                    )}
                  </Grid>
                  <Grid item>
                    {this.props.image.assigneeEmailAddress && this.props.image.assigneeEmailAddress.length > 0 && (
                      <Box
                        style={{
                          marginLeft: 6 ,
                          marginTop: 10,
                          paddingRight: 15,
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <UserIcon marginLeft={0} marginRight={0} height={21} width={21} fontSize={10} emailAddress={this.props.image.assigneeEmailAddress} firstName={this.props.image.assigneeFirstName} surname={this.props.image.assigneeSurname} tooltip={'Assigned to ' + this.props.image.assigneeFirstName + ' ' + this.props.image.assigneeSurname + ' ' + this.props.image.assigneeEmailAddress} />
                      </Box>
                    )}
                  </Grid>  
                </Grid>
              </Grid>

              <Grid item>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  style={{ marginTop: 9 }}
                  flexShrink={0}
                >
                  <Box>
                    <a id={this.zoomInButtomId} className={styles.menuIcon}>
                      <ZoomInIcon />
                    </a>
                  </Box>
                  <Box>
                    <a id={this.zoomOutButtomId} className={styles.menuIcon}>
                      <ZoomOutIcon />
                    </a>
                  </Box>
                  <Box>
                    <ExposureIcon
                      className={styles.menuIcon}
                      style={filterIconStyle}
                      onClick={() =>
                        this.setState({ showFilters: !this.state.showFilters })
                      }
                    />
                  </Box>
                  <Box>
                    <a id={this.homeButtomId} className={styles.menuIcon}>
                      <RefreshIcon />
                    </a>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>

        <div
          className={styles.filtersContainer}
          style={filterMenuStyle}
          onMouseEnter={() => this.onMouseEnterFilterMenu()}
          onMouseLeave={() => this.onMouseLeaveFilterMenu()}
        >
          <div>Brightness</div>
          <Slider
            min={-255}
            max={255}
            style={{ color: "#F7B500" }}
            value={this.state.brightness}
            onChange={(e, n) => this.onChangeBrightness(n)}
          />
          <div style={{ marginTop: 10 }}>Contrast</div>
          <Slider
            min={0}
            max={5}
            step={0.1}
            style={{ color: "#F7B500" }}
            value={this.state.contrast}
            onChange={(e, n) => this.onChangeContrast(n)}
          />

          <StyledButton
            style={{ height: 35, marginTop: 10, marginLeft: 90 }}
            variant="contained"
            onClick={() => this.onResetFilters()}
          >
            RESET
          </StyledButton>
        </div>

        <div
          id={this.seaDragonDivId}
          className={styles.seadragonImageViewer}
          style={{
            cursor:
              this.state.isDrawDefectModeEnabled && this.state.canEditDefects
                ? "crosshair"
                : "default",
          }}
        ></div>
        <div>
          {this.state.showDefectForm &&
            this.state.formList &&
            this.state.selectedDefect &&
            this.state.selectedDefect.id && (
              <DefectForm
                defectFavouritesList={this.state.defectFavouritesList}
                formList={this.state.formList}
                onFormIsOpened={(is:boolean) => { this.setState({defectFormIsOpen:is})}}
                defect={this.state.selectedDefect}
                form={this.state.selectedForm}
                readOnly={
                  !this.state.canEditDefects ||
                  this.state.selectedDefect?.isReadOnly
                }
                onDefectFormEnd={(data:any) => {
                  this.setState({ showDefectForm: false });
                  if (data && data.status && data.formData) {
                      if (this.state.selectedDefect) {
                        var tempDef:IDefect = JSON.parse(JSON.stringify(this.state.selectedDefect));
                        tempDef.status = data.status;
                        tempDef.formData = data.formData;
                        this.replaceLocalDefect(tempDef, false);
                        this.setState({selectedDefect:tempDef});
                      }
                    }
                    this.setState({ selectedDefect: null });

                    
                }}
              />
            )}
        </div>
        <div className={styles.generalSnackbarContainer}>
          <GeneralSnackbar config={this.state.generalSnackbarConfig} />
            </div>
            </div>
            <div id="splitter" className={styles.splitter}>
                <img src={process.env.PUBLIC_URL + '/images/arrow-right.png'} onClick={() => this.onCarouselToggle()} alt="" className={styles.arrowToClose} style={{ display: this.state.isRightSideCollapsed ? 'none' : '' }} />
                <img src={process.env.PUBLIC_URL + '/images/arrow-left.png'} onClick={() => this.onCarouselToggle()} alt="" className={styles.arrowToCollapse} style={{ display: this.state.isRightSideCollapsed ? '' : 'none' }} />
            </div>
            { //Conditional Rendering
                !this.state.isRightSideCollapsed &&
                <div id="rightSide" className={`${styles.rightSide} ${!this.state.isRightSideCollapsed ? styles.rightSideShow : styles.rightSideHide}`} >
                    <div className={styles.thumbnailCarousel}>
                        <DZICarousel linkedImages={this.state.linkedImages}
                            selectedDziImage={this.state.selectedImage ?? this.props.image}
                            imageStatuses={this.props.imageStatuses}
                            onSelectThumbnail={(n: IImage) => this.handleChangeThumbnail(n)}
                            canViewDefects = {this.props.canViewDefects}
                            imageDefectCount={this.props.imageDefectCount}
                        />
                    </div>
                </div>
            }

     </div>

    );
    };
}

