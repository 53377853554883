import React, { useState, useEffect, useCallback } from 'react';
import { fetchWithAuthorisationHeader } from "../../../../../services/AuthenticationService";
import { makeStyles } from '@material-ui/core/styles';
import styles from './DefectFormKeyValuePair.module.css';
import { SelectDropDown } from '../../../../SelectDropDown/SelectDropDown';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { IDefectFormKey } from '../../../../../models/IDefectFormKey';
import { IDefectFormValue } from '../../../../../models/IDefectFormValue';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const makeStyle = makeStyles((theme) => ({
    label: {
        fontSize: 12
    },
    labelSelected: {
        fontSize: 12,
        color: '#F7B500'
    },
    isDeprecatedStyle: {
        fontSize: 12,
        color: '#858181'
    },
    isDeprecatedSelectedStyle: {
        fontSize: 12,
        color: '#e6ce93'
    },
    accordian: {
        left: 250,
        position: 'absolute',
        width: 200,
        "&:hover": {
            borderBottomColor: 'white',
            borderBottomStyle: 'solid',
            borderBottomWidth: 1
        }
    },
    accordianDetails: {
        position: 'relative',
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: 'rgba(255, 255, 255, 0.42)',
        borderWidth:0.25,
        height: '100%',
        width: 225,
        maxHeight: 310,
        zIndex: 200,
        overflowY: 'scroll',
        overflowX: 'hidden',
        backgroundColor: 'rgba(41,42,43, 1.0)'
    },
    searchField: {
        color: '#F7B500',
        marginTop: -8,
        width: 220
    },
    summaryText: {
        top: 18,
        fontSize: 18
    },
    summaryTextSelected: {
        position: 'absolute',
        top: 22,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 150,
    },
    valueLabel: {
        position: 'absolute',
        top: 6,
        marginRight: 10,
        fontSize: 15,
        color: '#a88f27' 
    }
}));

interface IDefectFormKeyValuePairProps {
    keys: IDefectFormKey[];
    keyValuePairInstanceId: number; // indicates which key value pair we are dealing with
    openValueDropdown: boolean;
    clearFilters: number;
    onKeyValuePairSelected: Function;
    onExpandIconClick: Function;
}

interface IValue {
    valueObj: IDefectFormValue;
    isSelected: boolean;
}

export function DefectFormKeyValuePair(props: IDefectFormKeyValuePairProps) {

    const style = makeStyle();

    const initialValues: IValue[] = [];

    const [keyNames, setKeyNames] = useState<JSX.Element[]>([]);
    const [keyValues, setkeyValues] = useState(initialValues);
    const [filteredValues, setFilteredValues] = useState(initialValues);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);

    const [selectedKeyId, setSelectedKeyId] = useState(0);

    const [clearKeys, setClearKeys] = useState(false);
    const [arraykey, setArrayKey] = useState(1);
    const [error, setError] = useState('');
    const [isValuesExpanded, setIsValuesExpanded] = React.useState(false);
    const [searchValue, setSearchValue] = useState("");

    const [lastClearFilters, setLastClearFilters] = useState(0);

    useEffect(() => {
        setIsValuesExpanded(props.openValueDropdown);
    }, [props.openValueDropdown]);

    useEffect(() => {
        setClearKeys(false);
        if (props.clearFilters !== lastClearFilters) {
            setLastClearFilters(props.clearFilters);
            reset();
        }

    }, [props.clearFilters, lastClearFilters]);


    useEffect(() => {

        let keyDropdownOptions: JSX.Element[] = [<option key={props.keyValuePairInstanceId + '_null'} value=""></option >];

        if (props.keys.length === 0) {
            setKeyNames([]);
            reset();
        }
        else
        {
            props.keys.forEach(x => {
                let name = x.isDeprecated ? (x.key + " (v" + x.version + ")") : x.key;
                keyDropdownOptions.push(
                    <option key={props.keyValuePairInstanceId + '_' +x.id} value={x.id} className={x.isDeprecated ? style.isDeprecatedStyle : style.label}>{name}</option >
                );
            });
            setKeyNames(keyDropdownOptions);
            setClearKeys(false);
        }

    }, [props.keys]);


    const populateFormValuesForSelectedKey = useCallback(async (selectedKeyId: number) => {

        let url = process.env.REACT_APP_VAA_API_URL + "defectForm/getValues/" + selectedKeyId;
        let valuesList = initialValues;
        setFilteredValues([]);

        fetchWithAuthorisationHeader(url).then(res => {

            let response = res.data as IDefectFormValue[];

            var sortedDeprecatedKeys = response.filter(x => x.isDeprecated == true).sort((a: IDefectFormValue, b: IDefectFormValue) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
            var sortedCurrentVersionKeys = response.filter(x => x.isDeprecated == false).sort((a: IDefectFormValue, b: IDefectFormValue) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
            let sortedResponse = sortedCurrentVersionKeys.concat(sortedDeprecatedKeys);

            sortedResponse.forEach(x => {
                var newValue = { valueObj: x, isSelected: false };
                valuesList.push(newValue);
            });

            setkeyValues(valuesList);
            setFilteredValues(valuesList);
        })
        .catch(err => {
            console.log('Error: Failed to get ' + url + ' with status code ' + err.status);
        });

    }, [selectedKeyId]);

    const reset = () => {
        setSelectedKeyId(0);
        setkeyValues([]);
        setFilteredValues([]);
        setSelectedValues([]);
        setClearKeys(true);
        setIsValuesExpanded(false);
        setError("");
        setSearchValue("");
        setArrayKey(1);
    };

    function onKeyChange(selectedKeyId: string) {

        let parsedSelectedKeyId = parseInt(selectedKeyId);
        setSelectedKeyId(parsedSelectedKeyId);

        if (parsedSelectedKeyId) {
            props.onExpandIconClick(true, props.keyValuePairInstanceId);
            populateFormValuesForSelectedKey(parsedSelectedKeyId);
            setIsValuesExpanded(true);
            setError("Please select a value");
            setSearchValue("");
            setSelectedValues([]);
        }
        else {
            //Clear the stored key value pair if the user has cleared the key.
            reset();
            setArrayKey(arraykey + 2);
        }

        props.onKeyValuePairSelected(selectedKeyId, [], props.keyValuePairInstanceId);
    }

    function onValueChange(selectedValues: string[]) {

        selectedValues.length <= 0 ? setError("Please select a value") : setError("");
        props.onKeyValuePairSelected(selectedKeyId, selectedValues, props.keyValuePairInstanceId);
    }

    function onValueCheckboxChanged(value: IValue) {
        value.isSelected = !value.isSelected;
        setkeyValues(keyValues.slice());
        let selectedValues = keyValues.filter(s => s.isSelected).map(s => s.valueObj.value);
        setSelectedValues(selectedValues);
        onValueChange(selectedValues);
    };

    function onValueTextBoxChange(value: string) {
        setSearchValue(value);
        if (value == "") {
            setFilteredValues(keyValues);
        } else {
            setFilteredValues(filteredValues.filter(v => v.valueObj.value.toLowerCase().includes(value.toLowerCase())));
        }
    };

    function onExpandIconClick() {
        setIsValuesExpanded(!isValuesExpanded);
        props.onExpandIconClick(!isValuesExpanded, props.keyValuePairInstanceId);
    };

    let valueCheckboxes: JSX.Element[] = [];
    filteredValues.forEach(v => {
        valueCheckboxes.push(
            <FormControlLabel style={{ width: 225 }} 
                className={styles.values}
                key={v.valueObj.value}
                classes={{ label: v.isSelected ? style.labelSelected : (v.valueObj.isDeprecated ? style.isDeprecatedStyle: style.label) }}
                control={<Checkbox style={{ height: 5 }} 
                    checked={v.isSelected}
                    checkedIcon={<CheckBoxIcon
                        style={{ color: '#F7B500' }} />}
                    onChange={() => onValueCheckboxChanged(v)}
                    name={v.valueObj.value}
                    size={'small'}
                />} 
                label={v.valueObj.isDeprecated ? (v.valueObj.value + " (v" + v.valueObj.version + ")") : v.valueObj.value}
            />
        );
    });

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Grid container spacing={1} >
                    <Grid item xs={6}>
                        <SelectDropDown
                            keyId={arraykey}
                            id={'key'}
                            name={'Key'}
                            dropdownValues={keyNames}
                            onSelected={onKeyChange}
                            clearValues={clearKeys}
                        />
                    </Grid>

                    <Grid item xs={6} >
                        <Accordion expanded={isValuesExpanded} className={style.accordian} onChange={() => onExpandIconClick()}  disabled={selectedKeyId <= 0}>
                            <AccordionSummary style={{ backgroundColor: "#343536" }}
                                expandIcon={<ExpandMoreIcon style={{ color: '#000000' }}/>}
                            >
                                <div>
                                    {selectedValues.length > 0 && <span className={style.valueLabel}>Value</span>}
                                    <Typography className={style.summaryText} variant='subtitle2'>
                                        {selectedValues.length === 0
                                            ? (<span className={style.summaryText}>Value</span>)
                                            : (<span className={style.summaryTextSelected}>{selectedValues.join(', ')}</span>)
                                        }
                                    </Typography>
                                </div>

                            </AccordionSummary>
                            <AccordionDetails className={style.accordianDetails} >
                                <Grid>
                                    <Grid item>
                                        <TextField className={style.searchField}
                                            id="value-filter-search"
                                            label="Search"
                                            value={ searchValue }
                                            onChange={(event) => onValueTextBoxChange(event.target.value)} />
                                    </Grid>
                                    <Grid item style={{ marginTop: 10, width: 225 }}>
                                        {valueCheckboxes}
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
                <div style={{ marginLeft: 220 }}>
                    <Typography style={{ marginTop: 4, fontSize: 14 }} color={'error'}>
                        {error}
                    </Typography>
                </div>
            </div>
        </div>
    );
}