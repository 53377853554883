import React, { useEffect, useState } from "react";
import { Box, Badge, Chip, Tooltip, Avatar } from "@material-ui/core/";
import Grid from "@material-ui/core/Grid";
import { IImage } from "../../../models/IImage";
import checkCircle from "../../../images/check_circle_24px.png";
import cancelCircle from "../../../images/cancel_24px.png";
import inactiveAssetCircle from "../../../images/check_circle_inactivelink_24px.png"; 
import poleIcon from "../../../images/custom_pole.png";
import PhotoCameraIcon from "../../../images/photo_camera_24px.png";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { IImageStatus } from '../../../models/IImageStatus';
import Typography from '@material-ui/core/Typography';
import styles from "./ImageSearchResult.module.css";
import { UserIcon } from "../../UserIcon/UserIcon";
import moment from 'moment';
import { IImageDefectCount } from "../../../models/IImageDefectCount";
import { IDefect } from "../../../models/IDefect";
import ImageDefectIndicator from "../../Shared/ImageDefectindicator/ImageDefectIndicator";

interface IImageSearchResultProps {
  image: IImage;
  isImageRowSelected: boolean;
  onDziImageSelected: Function;
  onImageRowSelected: Function;
  selectedDziImageId: number;
  isImageSelectionEnabled: boolean;
  poleIdHasChanged: boolean;
  isImageSelected: boolean;
  onImageCheckboxChecked: Function;
  imageStatusColour: string;
  imageDefectCount : IImageDefectCount | undefined;
  canViewDefects: boolean;
}


export function ImageSearchResult(props: IImageSearchResultProps) {
  if (props.image == null) return null;

  let statusGreen = "#AED581";
  let statusYellow = "#FFB74D";
  let resultVariableDataSep = " | ";
  let resultVariableDataMaxChar = 35;
 
  const imageStatusChipStyle = {
    color: '#' + props.imageStatusColour,
    borderColor: '#' + props.imageStatusColour,
  };

  function formatUTCDateForLocal(utcDate: Date): string {
    return moment.utc(utcDate,"DD-MM-YYYY hh:mm:ss a").local().format('DD/MM/YY HH:mm:ss');
  }

  function getStatusIcon(poleId: string, active: boolean) {
    if (poleId && active) {
      return checkCircle;
    } else if (poleId && !active) {
      return inactiveAssetCircle;
    } else {
      return cancelCircle;
    }
  }

  function statusTabColor(poleId: string, active: boolean) {
    if (poleId && active) {
      return "#AED581";
    } else if (poleId && !active) {
      return "#fafcff";
    } else {
      return "#FFB74D";
    }
  }

  const statusTabStyle = {
    backgroundColor: statusTabColor(props.image.poleId,props.image.active),
    width: "10px",
    height: "100%"
  };

  let containerClasses = props.isImageRowSelected? styles.container + ' ' + styles.selected : styles.container;

  let ImageThumbnailContainer: JSX.Element; 
  let ImageDetailContainer: JSX.Element; 
 
  ImageDetailContainer = (    <Grid item 
    className={styles.content} 
    style={{ width: props.isImageSelectionEnabled ? '54%':'59%', paddingLeft:10}}
     onClick={() => props.onImageRowSelected(props.image)}
    >
    <Grid container justify='space-between' spacing={0} direction="row">
      <Grid item xs >
        <Box className={styles.bigProp}>{props.image.pack || "---"}</Box>
        <Box className={styles.sessionLabel}>
          {props.image.sessionName || "---"}
        </Box>
      </Grid>
      <Grid item xs className={styles.gridText} style={{ paddingTop: "4px", textAlign: 'center'}}>
        <Box>
        {props.image.status &&
          <Chip
            label={props.image.status || "---" }
            style={imageStatusChipStyle}
            variant="outlined"
          />}
        </Box>
      </Grid>
    </Grid>

    <Grid 
      container
      justify="space-evenly"
      alignContent="center"
      direction="column"
      
      style={{maxWidth:"100%", minHeight:"20%", paddingLeft: "1%", paddingRight: props.isImageSelectionEnabled ? "-20px" : "inherit" }}
    >
        <Grid 
          container 
          direction="row" 
          alignContent="center"
          
          style={{ paddingLeft: "0.1%", paddingRight: props.isImageSelectionEnabled ? "-20px" : "inherit"  }}>
 
          {props.image.feeder &&
          <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '4rem'}}>

          <Tooltip title={props.image.feeder} placement="top" interactive arrow>
          <Typography variant="inherit" noWrap>
           {props.image.feeder}
          </Typography>
          </Tooltip>
          </div>
          }
          {props.image.fileName &&
          <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '8rem'}}>
          <Tooltip title={props.image.fileName} placement="top" interactive arrow>
          <Typography  variant="inherit" noWrap>
           {props.image.fileName}
          </Typography>
          </Tooltip>
          </div>}

        </Grid>
    </Grid>
    <Grid container alignItems="center" spacing={1} style={{ paddingLeft: "0.1%", paddingRight: props.isImageSelectionEnabled ? "-20px" : "inherit" }} >
      {props.image.poleId &&
     
       
       <Tooltip title={props.image.poleId} placement="top" interactive arrow>
          <Typography variant="inherit" noWrap>
          {/* show if poleid exists */}

          <Box display="flex" justifyContent="center" >
            <img
              className={styles.iconInLine}
              src={poleIcon}
              style={{ width: "18px" }}
            ></img>
            <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '4rem'}}>  {props.image.poleId}</div>
          </Box>
          </Typography>
          </Tooltip>
      }
      <Grid item lg={"auto"} className={styles.gridRow}>
      {props.image.created &&
        <Box display="flex"  justifyContent="center">
          
          <Tooltip title={props.image.created || "---"} placement="top" interactive arrow>
            <img
              className={styles.iconInLine}
              src={PhotoCameraIcon}
              style={{ width: "15px" }}
            ></img>
          </Tooltip>
          { formatUTCDateForLocal(props.image.created) || "---"}
        </Box>}
      </Grid>
 
    </Grid>
  </Grid>);

  ImageThumbnailContainer = (
    <Grid item style={{ width: '33%' }}>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={
          <Avatar
            alt="Failed"
            src={getStatusIcon(props.image.poleId, props.image.active)}
            className={styles.linkAvatar}
            style={{ marginTop: props.poleIdHasChanged ? '-6px' : '' }}
          />
        }
        style={{ verticalAlign: 'middle', alignItems: 'center' }}
      >
        {props.image.thumbnailUrl && (
          <div className={styles.thumbnail} style={{ marginTop: props.poleIdHasChanged ? '-7px' : '-4px' }}>
            <ImageDefectIndicator
              imageId={props.image.id}
              imageDefectCount={props.image.defectCount}
              imageDefectCountLatest={props.imageDefectCount?.defectCount}
              canViewDefects={props.canViewDefects}
              height={89}
              left={1}
              top={2}
            />

            <img
              style={{ border: props.selectedDziImageId == props.image.id ? '2px solid #FF6107' : '2px solid transparent' }}
              src={props.image.thumbnailUrl}
              onClick={() => props.onDziImageSelected(props.image)}
            />
          </div>
        )}
      </Badge>
    </Grid>
  );

  return (
      <Grid container   className={containerClasses} alignItems="center">  
           { props.poleIdHasChanged &&
               <Grid container direction="column">
                  <Grid item>
                    <Box className={styles.groupDivider}></Box>
                  </Grid>
               </Grid>
           }
            { !props.poleIdHasChanged &&
               <Grid container direction="column">
                  <Grid item>
                    <Box className={styles.listDivider}></Box>
                  </Grid>
               </Grid>
           }
           <Grid container alignItems="center">
              <Grid item style={{height:'106px', width:'8px'}}>
                <Box className={styles.statusTab} style={statusTabStyle}></Box>
              </Grid>
              {props.isImageSelectionEnabled && 
                <Grid item style={{width:'6%', marginLeft:'-1px'}} >
                  <Checkbox checked={props.isImageSelected} checkedIcon={<CheckBoxIcon style={{color:'#F7B500'}} />} onChange={()=> props.onImageCheckboxChecked(props.image) } name={'checkbox'} size={'small'} />
                </Grid>}
              {ImageDetailContainer}
              <Grid item style={{width:'21px'}} >
                {props.image.assigneeEmailAddress != null && props.image.assigneeEmailAddress.length > 0 && 
                  <Box>
                      <UserIcon marginLeft={0} marginRight={0} height={21} width={21} fontSize={10} emailAddress={props.image.assigneeEmailAddress} firstName={props.image.assigneeFirstName} surname={props.image.assigneeSurname} tooltip={'Assigned to ' + props.image.assigneeFirstName + ' ' + props.image.assigneeSurname + ' ' + props.image.assigneeEmailAddress} />  
                  </Box>     
                }
              </Grid>
              {ImageThumbnailContainer}
           </Grid>
     </Grid> 
  );
}
