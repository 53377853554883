import { TextField, Box } from '@material-ui/core';
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { JsxEmit } from 'typescript';
import {ISegment} from '../../../models/ISegment';
import {IShapefileData} from '../../../models/IShapefileData';
import {IAssetSearchResponse} from '../../../models/IAssetSearchResponse';
import {AssetSearchSegmentResult} from '../AssetSearchSegmentResult/AssetSearchSegmentResult';
import {AssetSearchShapefileResult} from '../AssetSearchShapefileResult/AssetSearchShapefileResult';
import styles from './AssetSearchResults.module.css';
import { IMapLayer } from '../../../models/IMapLayer';

import customPoleImage from '../../../images/custom_pole.png';

interface IAssetSearchProps {
    shapefileLayers: IMapLayer[];
    assetSearchResults: IAssetSearchResponse | null;
    onAssetRowMouseClick:Function;
  };


  export function AssetSearchResults(props:IAssetSearchProps){
    const defaultArray: JSX.Element[] = [];
    const [resultsJsx, setResultsJsx] = useState(defaultArray);
    const [selectedSegmentId, setSelectedSegmentId] = useState(-1);
    const [selectedShapefileDataId, setSelectedShapefileDataId] = useState(-1);
    const searchLimit: number = 120; // Hard coded for now, but should match the config value in VAA API for AssetSearchResultsLimit
    const  mapLayerIconPath:string = "../../../images/MapLayerIcons/";

    function compareLayers(a:IMapLayer, b:IMapLayer ) {
        if ( a.label < b.label ){
            return -1;
        }
        else if ( a.label > b.label ){
            return 1;
        }

        return 0;
     }

    function setSelectedId(type:string, id: number) {
        if (type === 'segment') {
            setSelectedSegmentId(id);
            setSelectedShapefileDataId(-1);
        }
        else {
            setSelectedSegmentId(-1);
            setSelectedShapefileDataId(id);
        }
    }

    useEffect(() => {
        let results:JSX.Element[] = [];
        if (props.assetSearchResults===null) return;

        // Add segment results 
        for (var i = 0; i < props.assetSearchResults.segments.length; i++) {
          let segment = props.assetSearchResults.segments[i];

          if (!segment.externalReferenceId)
            continue;

          results.push(<div key={'seg_' + i}>
            <AssetSearchSegmentResult
              isAssetRowSelected={segment.id === selectedSegmentId}
              segment={segment}
              thumbnail ={customPoleImage}
              onMouseClick={(clickedSegment:ISegment) => {
                 setSelectedId('segment',clickedSegment.id); 
                 props.onAssetRowMouseClick(clickedSegment); 
              }}
              />
              </div>
          );
        }

        // Add shapefile results
        var fileIds = props.assetSearchResults.shapefiles.map(l=>l.fileId);
        if (fileIds.length > 0) {
            let shpCount = 0;

            results.push(<Box key={'shape_' + ++shpCount} className={styles.shapefileTitle}>Network shapefiles</Box>);

            var layersThatHaveResults = props.shapefileLayers.filter(l => l.fileId != null && fileIds.includes(l.fileId ));

            // Sort by name
            layersThatHaveResults.sort(compareLayers);

  
            for (var i = 0; i < layersThatHaveResults.length; ++i) {
                let layer = layersThatHaveResults[i];

                results.push(<div key={'shape_' + ++shpCount} className={styles.shapefileName}>{layer.label}</div>);

                let shapefileDatas = props.assetSearchResults.shapefiles.filter(l=>l.fileId === layer.fileId );
                for (var x = 0; x < shapefileDatas.length; ++x) {
                    let shapefileData = shapefileDatas[x];

                    results.push(<div key={'shape_' + ++shpCount}>
                                    <AssetSearchShapefileResult
                                        isAssetRowSelected={shapefileData.id === selectedShapefileDataId}
                                        shapefileData={shapefileData}
                                        thumbnail={`${mapLayerIconPath}${shapefileData.icon ? shapefileData.icon : shapefileData.lineType}${shapefileData.displayColor?"_" + shapefileData.displayColor:""}.png`}
                                        onMouseClick={(clickedShapefileData:IShapefileData) => {
                                            setSelectedId('shapefiledata',clickedShapefileData.id); 
                                            props.onAssetRowMouseClick(clickedShapefileData); 
                                        }}
                                        />
                                   </div>
                    );
                }
            }
        }
        
        setResultsJsx(results);
    }, [props.assetSearchResults, selectedSegmentId,selectedShapefileDataId]);


    return (
      <Box display="flex" flexDirection="column" height= '100%'>
       
        <Box flexGrow={1} style={{ maxHeight: '100%', height: '100%', overflowY:'auto', overflowX:'hidden' }}>
          <Box style={{ height: '0px' }}>
            {resultsJsx}
          </Box>
        </Box>

        { props.assetSearchResults ?
          <Box height={55} className={styles.footerContainer}>
            <Box display="flex" flexDirection="row" className={styles.footerContent}>
              <Box flexGrow={1} style={{textAlign:'left'}}>
                { props.assetSearchResults.segments.length + props.assetSearchResults.shapefiles.length  > searchLimit ? <a>Max search results returned. Please refine your search.</a>
                : <a>{props.assetSearchResults.segments.length + props.assetSearchResults.shapefiles.length} results shown</a>}
              </Box>
            </Box>
          </Box>
          : <div></div>
        }

      </Box>
  )
}