import React, { useState, useEffect } from 'react';
import { Tooltip, AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core/';
import { makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import  Popper, {PopperProps}  from '@material-ui/core/Popper';
import styles from "./SegmentDetails.module.css";
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import { ISegmentDetailsConfig } from '../../models/ISegmentDetailsConfig';
import { IImage } from '../../models/IImage';
import LaunchIcon from '@material-ui/icons/Launch';

interface ISegmentDetailsProp {
    selectedDziImageId: number;
    config: ISegmentDetailsConfig|null;
    onClose: Function;
    onThumbnailClick: Function;
    onSegmentUrlClick: Function;
}

//const IMAGE_DATE_SORT_ORDER = 1 //asc
const IMAGE_DATE_SORT_ORDER = -1 //desc

/**Assumes format is: "15/10/2020 02:44:08 AM"*/
const convertDateStringToDateObject = (date: string): Date | null => {
    let st: string = date;
    var dt = st.split(' ', 1)[0].split('/', 3);
    if (dt.length === 3) {
        var fmt = dt[2] + '/' + dt[1] + '/' + dt[0];
        var d: Date = new Date(fmt);
        return d;
    } else {
        return null
    }
}

const dateToComparer = (date: Date): number => {
    let d = convertDateStringToDateObject(date.toString());
    if (d) {
        var yr = d.getFullYear().toString();
        var mn = String(d.getMonth()).padStart(2, '0');
        var cmb = yr + mn;
        return Number.parseInt(cmb)
    }
    return -1;
}

const dateToLabel = (date: Date): string => {
    let d = convertDateStringToDateObject(date.toString());
    if (d)
        return d.toLocaleString('en-us', { month: 'long' }) + " " +  d.getFullYear().toString();
    else
        return "";
}

  
  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div style={{padding:0}} role="tabpanel" hidden={value !== index} id={`wrapped-tabpanel-${index}`} aria-labelledby={`wrapped-tab-${index}`} {...other}>
        {value === index && (
          <Box style={{padding:0}} p={2}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: any) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }
  
  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
        boxShadow: 'none',
        marginTop: 10,
        background: 'transparent !important',
    },
    tabStyle: {
        textTransform:'none',
        minWidth: '50%',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.25,
        paddingRight: 0,
        paddingLeft: 0,
    }
  });


export function SegmentDetails(props: ISegmentDetailsProp) {
    const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null);
    const [activeTab, setActiveTab] = React.useState('attributes'); // default tab = attributes
    let numberOfImage = 0;
    const classes = useStyles();

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setActiveTab(newValue);
      };

    useEffect(()=> {
        if (props.config) {
            const selection:ClientRect =  {
                top:props.config.screenY + 5,
                right:props.config.screenX + 12,
                width:1,
                height:1,
                bottom: props.config.screenY + 1,
                left: props.config.screenX - 1
            }
            const getBoundingClientRect = () => selection;

            setAnchorEl({
                clientWidth: getBoundingClientRect().width,
                clientHeight: getBoundingClientRect().height,
                getBoundingClientRect:getBoundingClientRect
            });
            
        }
       
    }, [props.config]);

    
    let attributesElement: JSX.Element[] = [];
    let thumbnailGroupElements: JSX.Element[] = [];

    if (props.config         &&
        props.config.segment &&
        props.config.screenX &&
        props.config.screenY &&
        props.config         &&
        props.config.segment &&
        props.config.segment.linkedImages) {
        var attributes = JSON.parse(props.config.segment.attributes);

        attributesElement.push(
            <GridList cols={1} style={{paddingTop:13}}>
                {Object.keys(attributes).map((key) =>
                    <GridListTile key={key} cols={1} className={styles.attributes}>
                        <Grid container>
                            <Grid item xs={6}  >{key.toLowerCase()}</Grid>
                            <Grid item xs={6} style={{ fontSize: 12, paddingRight: 5 }} >{attributes[key]}</Grid>
                        </Grid>
                    </GridListTile>
                )}
            </GridList>
        );
        //sort array based on asc/desc setting
        let sortedimages = props.config.segment.linkedImages.sort((a, b) => {
            let da: number = dateToComparer(IMAGE_DATE_SORT_ORDER < 0 ? a.created : b.created);
            let db: number = dateToComparer(IMAGE_DATE_SORT_ORDER < 0 ? b.created : a.created);

            if (da < db) return -1;
            else if (da > db) return 1;
            else return 0;
        });

        //organise images into groups based on their capture date
        let i: number = sortedimages.length - 1;
        while (i >= 0) {
            let j: number = i;
            let tArr: IImage[] = [];
            let x: number = dateToComparer(sortedimages[i].created);
            while (j >= 0 && dateToComparer(sortedimages[j].created) === x) {
                tArr.push(sortedimages[j--]);
            }
            thumbnailGroupElements.push(
                <div style={{marginTop: 14, marginLeft:8}} className={styles.thumbnailGroup}>
                <Grid item style={{fontSize:12, color:"#878a8b", fontWeight:500, marginBottom:6}}>{dateToLabel(tArr[0].created)}</Grid>
                <GridList cellHeight={64} style={{marginRight:0, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} cols={3}>
                    {tArr.map((img: IImage) => (
                        <GridListTile key={img.id} cols={1} style={{padding:0,border: props.selectedDziImageId == img.id ? '2px solid #FF6107' : '2px solid transparent'}}>    
                            <img  src={img.thumbnailUrl} alt={img.poleId} onClick={() => {props.onThumbnailClick(img);}} />
                        </GridListTile>
                    ))}
                </GridList>
            </div>    
            );
            i = j;
        }

        numberOfImage = sortedimages.length
        
    }

    return <Popper
        placement="right-start"
        disablePortal={false}
        open={true}
        anchorEl={anchorEl}
        modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport',
            }
          }}
        
    >
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <Grid container justify="space-between" >
                        <Grid item>
                            <span style={{ fontWeight: 500 }}> Pole {props.config && props.config.segment.externalReferenceId}</span>
                        </Grid>

                        <Grid item>
                            { props.config != null && props.config.segment != null && props.config.segment.url != null &&
                                <Tooltip title={'Open asset url in viewer'} >
                                    <IconButton style={{padding:0}} disableRipple={true} disableFocusRipple={true} onClick={() => { if ( props.config != null ) props.onSegmentUrlClick(props.config.segment.url) }}  >
                                        <LaunchIcon className={styles.clear} />
                                    </IconButton>
                                </Tooltip>
                            }
                            <IconButton style={{paddingLeft:5}}  disableRipple={true} disableFocusRipple={true} onClick={() => props.onClose()}  >
                                <ClearIcon className={styles.clear} />
                            </IconButton>
                        </Grid>

                    </Grid>

                    <div style={{ marginTop: -4, fontSize: 12, fontWeight: 500 }}>
                        <span>{props.config && props.config.segment.lat.toFixed(5)}, {props.config && props.config.segment.lon.toFixed(5)}</span>
                    </div>
                </div>

                <div className={classes.root}>
                    <AppBar position="static" className={classes.appBar}>
                        <Tabs value={activeTab} className={classes.appBar} onChange={handleTabChange} variant="fullWidth" style={{width:'215px', fontSize:14, backgroundColor:'transparent'}}>
                            <Tab style={{ alignItems:'start !important'}} className={classes.tabStyle} value="attributes" label="Attributes" {...a11yProps('attributes')} />
                            <Tab  className={classes.tabStyle} value="images" label={"Images (" + (numberOfImage > 9 ? "9+" : numberOfImage)+")"} {...a11yProps('images')} disabled={numberOfImage < 1}/>
                        </Tabs>
                    </AppBar>
                    <TabPanel  value={activeTab} index="attributes">
                        {attributesElement}
                    </TabPanel >
                    <TabPanel  value={activeTab} index="images">
                        {thumbnailGroupElements}
                    </TabPanel>
                </div>

            </div>
        </div>
    </Popper>
        ;
}


