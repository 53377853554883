import React, { useState, useEffect, useCallback } from 'react';
import { Box, Chip } from '@material-ui/core/';
import { IImage } from '../../../models/IImage';
import { IImageStatus } from '../../../models/IImageStatus';

import styles from './DZICarousel.module.css';
import { IDZIImage } from '../../../models/IDZIImage';
import ImageDefectIndicator from '../../Shared/ImageDefectindicator/ImageDefectIndicator';
import { IImageDefectCount } from '../../../models/IImageDefectCount';

interface IDZICarouselProps {
    linkedImages: IDZIImage[];
    selectedDziImage: IImage;
    imageStatuses: IImageStatus[];
    onSelectThumbnail: Function;
    canViewDefects: boolean;
    imageDefectCount: IImageDefectCount[];
}

interface ISessionImagesGroup {
    sessionName: string,    
    linkedImgs: IDZIImage[]
}


export function DZICarousel(props: IDZICarouselProps) {

    const emptySessionGroups: ISessionImagesGroup[] = [];
    const emptyLoadedImages: number[] = [];

    const [selectedDZIImageId, setSelectedDziImageId] = useState(props.selectedDziImage.id);
    const [sessionGroups, setSessionGroups] = useState(emptySessionGroups);
    const [loadedImages,setLoadedImages ] = useState(emptyLoadedImages);
    const groupHeadings: JSX.Element[] = [];

    useEffect(() => {
        // We need to load images here as they need auth which means we cant just use img with a url
        // We queue them for loading and when loaded we get the data and update the images ILoadedImage object

        setLoadedImages(emptyLoadedImages);
        setSessionGroups(emptySessionGroups);

        groupBy();

    }, [props.linkedImages]);

    useEffect(() => {
        // We need to load images here as they need auth which means we cant just use img with a url
        // We queue them for loading and when loaded we get the data and update the images ILoadedImage object

        setSelectedDziImageId(props.selectedDziImage.id);

    }, [props.selectedDziImage]);


    const groupBy = () => {
        var newGrp:ISessionImagesGroup[] = [];
        props.linkedImages.map((val, index) => {

            let session = newGrp.find(is => is.sessionName == val.sessionName);
            if (session != null)
                session.linkedImgs.push(val);
            else {
                newGrp.push({ sessionName: val.sessionName, linkedImgs: [] })
                newGrp.find(is => is.sessionName == val.sessionName)?.linkedImgs.push(val);
            }

        });
        setSessionGroups(newGrp);
       
    }
   
    const imageHasLoaded = (id:number) => {
        
        loadedImages.push(id);
        setLoadedImages([...loadedImages]);
    }

    const displayImage = (img: IImage) => {

        props.onSelectThumbnail(img);
              
        setSelectedDziImageId(img.id);
    }
  

    if (props.linkedImages == null || props.linkedImages.length === 0 )
        return <div style={{padding:'50px 0px'}}><span className={styles.boldTxt} style={{color:'white'}} >No linked images</span></div> 
    
    sessionGroups.forEach((grp,index) => {
        const images: JSX.Element[] = [];
        grp.linkedImgs.forEach((loadedImage: IDZIImage, index) => {
            images.push(
                <Box key={'tile-no-' + loadedImage.id} style={{ position: 'relative', textAlign: 'left',display: loadedImages.includes(loadedImage.id) ? 'block' : 'none' }} >

                    <div style={{ position: 'relative', height: '200', width: '300' }}
                        id={'thumb-' + index} key={index}
                        onClick={() => displayImage(loadedImage)}
                    >
                            {/* Index is 3 because for defect indicator z index is  */}
                            <Box style={{ bottom: '12%', left: '17%', position: 'absolute', marginTop: 4, marginLeft: 5, marginRight: 10, zIndex: 3 }}>
                            {
                                loadedImage.status && (
                                    <Chip
                                        label={loadedImage.status || "---"}
                                        style={{
                                            color: loadedImage.statusColorCode,
                                            borderColor: loadedImage.statusColorCode,
                                            backgroundColor: 'rgb(70, 74, 78)',
                                        }}
                                        variant="outlined"
                                    />
                                )}
                            </Box>
                        <ImageDefectIndicator
                            imageId={loadedImage.id}
                            imageDefectCount={loadedImage.defectCount}
                            imageDefectCountLatest={props.imageDefectCount.filter(x => x.imageId === loadedImage.id)[0]?.defectCount}
                            canViewDefects={props.canViewDefects}
                            left={7}
                            top={7}
                            height={97}
                        />
                        <img className={styles.thumbnailImage}
                            style={{ height: 'auto', width: 'auto', maxHeight: 200, maxWidth: 300, border: selectedDZIImageId == loadedImage.id ? '2px solid #FF6107' : '2px solid transparent' }}
                            src={loadedImage.thumbnailUrl}
                            onLoad={ () => imageHasLoaded(loadedImage.id)}
                            />
                    </div>
                </Box>
            );

        });
        groupHeadings.push(<div className={styles.datasetContainer}>
            <div className={styles.datasetHeading}>
                {index == 0 && <div><span><span className={styles.boldTxt}>Asset:&nbsp;</span>{props.selectedDziImage.poleId}</span></div>}
                <div><span><span className={styles.boldTxt}>Session:&nbsp;</span>{grp.sessionName}</span></div>
            </div>
            <Box display="flex" flexDirection="column" style={{ maxHeight: '100%', height: '100%', overflowX: 'hidden', overflowY: 'auto' }} onWheel={(e: any) => e.currentTarget.scrollTop -= e.nativeEvent.wheelDeltaY} >
                {images}
            </Box>
        </div>)
    });  

    

    return <div>

        <div className={styles.carousel} >
           {groupHeadings}
        </div>
    </div>
}
