import React, {useState, useEffect, useCallback} from 'react';
import Checkbox , { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import { fetchWithAuthorisationHeader } from '../../../../services/AuthenticationService';
import { IUser } from '../../../../models/IUser';
import { Tooltip} from '@material-ui/core/';

import styles from './DefectFilter.module.css';

const makeControlStyles = makeStyles((theme) => ({
    label: {
        fontSize: 14
    },
    labelSelected: {
        fontSize: 14,
        color: '#F7B500'
    }
}));

interface IDefectFilterProps {
  organisationUsers:IUser[];
  isDisplayed:boolean;
  onFilterChanged: Function;
  clearFilters: number;
  campaignId:number;
}

interface IOption {
  name: string;
  isSelected: boolean;
}

interface IOptionRange {
  name: string;
  range: number[];
}

interface IFilterUser {
    name: string;
    emailAddress: string;
    isSelected: boolean;
}

export function DefectFilter(props:IDefectFilterProps){
    const emptyStringArray: string[] = [];
    const controlClasses = makeControlStyles();

    const defectCountOptions: IOptionRange[] = [ 
                                            {name: 'Has defects',range:[1]},
                                            {name: 'No defects',range:[-1]},
                                           
                                        ];

    const defaultDefectSourceOptions: IOption [] = [
                                            {name: 'User created',isSelected:false},
                                            {name: 'Machine Learning',isSelected:false},
                                            ];

    const [selectedDefectCount, setSelectedDefectCount] = React.useState('');
    const [defectSourceOptions, setDefectSourceOptions] = useState(defaultDefectSourceOptions);

    const [lastClearFilters, setLastClearFilters] = useState(0);

    const initialUsers: IFilterUser[] = [];
    const [users, setUsers] = useState(initialUsers);
    const [selectedUserNames, setSelectedUserNames] = useState(emptyStringArray);
    const [filteredUsers, setFilteredUsers] = useState(initialUsers);
    const [selectAllUsers, setSelectAllUsers] = useState(false);
    const [sourceFlag, setSourceFlag] = useState(true);

    // Handle when the filters have been cleard
    // A number is passed as a flag so we can tell when it has changed
    useEffect(() => {
        if (props.clearFilters != lastClearFilters) {
            defectSourceOptions.forEach(s=> s.isSelected = false);
            setDefectSourceOptions(defectSourceOptions.slice());
            setSelectedDefectCount('');
            setLastClearFilters(props.clearFilters);
            setSelectedUserNames(emptyStringArray);
            users.forEach(s => s.isSelected = false);
            setUsers(users.slice());
            setSourceFlag(true);
        }

    },[props.clearFilters, lastClearFilters, defectSourceOptions]);

    useEffect(() => {
        
        if ( props.isDisplayed )
            getUsersThatHaveCreatedDefectsInTheCurrentCampaign();

    }, [props.isDisplayed]);


    const getUsersThatHaveCreatedDefectsInTheCurrentCampaign = useCallback(async () => {
        try {
            let userList = initialUsers;
            let selectedUsers = users.filter(s => s.isSelected && s.name != 'Select All').map(s => s.emailAddress);
            
            sendFilterUpdate(selectedDefectCount, defectSourceOptions.filter(s => s.isSelected).map(s => s.name), selectedUsers);
            var response = await fetchWithAuthorisationHeader(process.env.REACT_APP_VAA_API_URL + "defectDetection/usersThatHaveCreatedDefects/" + props.campaignId);
            var responseUserNames = response.data as string[];

            for (let responseUserName of responseUserNames) {

                let orgUser = props.organisationUsers.find(u=> u.emailAddress === responseUserName);
                let fullUserName = orgUser != null ? orgUser.firstName + ' ' + orgUser.surname : responseUserName;

                userList.push({ name: fullUserName,emailAddress:responseUserName, isSelected: selectedUsers.includes(responseUserName) });
            }

            selectedUsers = userList.filter(s => s.isSelected && s.name != 'Select All').map(s => s.emailAddress);
            setSelectedUserNames(selectedUsers);
            setUsers(userList);
            setFilteredUsers(userList);
            syncSelectAllUsers();
        }
        catch (e) {
            console.log(e);
        }
    },[initialUsers,props.organisationUsers,defectSourceOptions,props.campaignId,users]);


    function sendFilterUpdate(defectCountOption: string, defectSources: string[], theSelectedUserNames:string[]) {

        let optionRange = defectCountOptions.find( (rangeOption:IOptionRange) => rangeOption.name === defectCountOption);
        if (optionRange != null) {
            props.onFilterChanged(optionRange.range, defectSources, theSelectedUserNames != null ? theSelectedUserNames : selectedUserNames );
        }
        
    }

    function onDefectSourceCheckboxChanged(option: IOption ) {
        option.isSelected = !option.isSelected;
        setDefectSourceOptions(defectSourceOptions.slice());

        let selectedSources = defectSourceOptions.filter(s=>s.isSelected).map(s=>s.name);

        let defectCount = selectedDefectCount.length > 0 ? selectedDefectCount : 'Any';

        if (defectCount != selectedDefectCount)
             setSelectedDefectCount(defectCount);
             sendFilterUpdate(defectCount, selectedSources, selectedUserNames);

    };

    function onDefectCountRadioButtonChanged(option: any) {
      setSelectedDefectCount(option);

      if(option ==='Has defects')
      {
        setSourceFlag(false);
        sendFilterUpdate(option,defectSourceOptions.filter(s=>s.isSelected).map(s=>s.name), selectedUserNames);
      }
      else
      {
        filteredUsers.forEach(p => p.isSelected = false);
        let selectedUsers = users.filter(p => p.isSelected).map(p => p.emailAddress);
        setSelectedUserNames(selectedUsers);
        setSourceFlag(true);
        setDefectSourceOptions(defaultDefectSourceOptions.slice());
        sendFilterUpdate(option,defaultDefectSourceOptions.filter(s=>s.isSelected).map(s=>s.name), selectedUsers);   
        setSelectAllUsers(false);
      }                  
    }

    function syncSelectAllUsers() {
        let selectedUsers = users.filter(s => s.isSelected);
        if (!selectAllUsers && selectedUsers.length == users.length && users.length > 0) {
            setSelectAllUsers(true);
        } else if (selectAllUsers && selectedUsers.length < users.length) {
            setSelectAllUsers(false);
        }
    }

    function onUserCheckboxChanged(user: IFilterUser) {
        user.isSelected = !user.isSelected;
        setUsers(users.slice());
        let selectedUsers = users.filter(p => p.isSelected).map(p => p.emailAddress);
        setSelectedUserNames(selectedUsers);
        sendFilterUpdate(selectedDefectCount, defectSourceOptions.filter(s => s.isSelected).map(s => s.name), selectedUsers);
        syncSelectAllUsers();
    };

    function onSelectAllUsers(selectAll: boolean) {
        if (!selectAll) {
            filteredUsers.forEach(p => p.isSelected = true);
        } else {
            filteredUsers.forEach(p => p.isSelected = false);
        }

        setUsers(users.slice());
        let selectedUsers = users.filter(p => p.isSelected).map(p => p.emailAddress);
        setSelectedUserNames(selectedUsers);
        sendFilterUpdate(selectedDefectCount, defectSourceOptions.filter(s => s.isSelected).map(s => s.name), selectedUsers);
        setSelectAllUsers(!selectAll);
    }

    function onUserChange(value: string) {
        if (value == "") {
            syncSelectAllUsers();
            setFilteredUsers(users);
        } else {
            setFilteredUsers(users.filter(p => p.name.toLowerCase().includes(value.toLowerCase())));
        }
    };

    let defectCountRadioButtons: JSX.Element[] = [];
    defectCountOptions.forEach(option => {
        defectCountRadioButtons.push(
            <FormControlLabel
                key={option.name}
                classes={{label:controlClasses.label}}
                value={option.name}
                control={<Radio />}
                label={option.name}
                
                />
            );
    });

    let detectSourceCheckboxes: JSX.Element[] = [];
    defectSourceOptions.forEach(option => {
        detectSourceCheckboxes.push(
            <FormControlLabel
                key={option.name}
                classes={{label: option.isSelected ? controlClasses.labelSelected : controlClasses.label}}
                control={<Checkbox checked={option.isSelected} checkedIcon={<CheckBoxIcon style={{color:'#F7B500'}} />} onChange={()=> onDefectSourceCheckboxChanged(option) } name={option.name} size={'small'} />}
                label={option.name}
                disabled= {sourceFlag}
                />
            );
    });

    let selectAllUsersCheckbox = (
        <FormControlLabel
            key={"Select All"}
            classes={{ label: selectAllUsers ? controlClasses.labelSelected : controlClasses.label }}
            control={<Checkbox style={{ color: '#F7B500', opacity: '0.5 !important' }} checked={selectAllUsers && !sourceFlag} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500', opacity: '0.5 !important' }} />} onChange={() => onSelectAllUsers(selectAllUsers)} name={"Select All"} size={'small'} />}
            label={"Select All"}
            disabled={sourceFlag || filteredUsers.length === 0 }
        />
    );

    let userCheckboxes: JSX.Element[] = [];
    filteredUsers.forEach(user => {
        userCheckboxes.push(
            <Tooltip title={sourceFlag ? '' : user.emailAddress}>
                <FormControlLabel
                    key={user.emailAddress}
                    classes={{ label: user.isSelected ? controlClasses.labelSelected : controlClasses.label }}
                    control={<Checkbox checked={user.isSelected} checkedIcon={<CheckBoxIcon style={{ color: '#F7B500' }} />} onChange={() => onUserCheckboxChanged(user)} name={user.emailAddress} size={'small'} />}
                    label={user.name}
                    disabled={sourceFlag}
                />
            </Tooltip>
        );
    });

  return (     
    <div className={styles.container} >
      <div className={styles.content}>
        <FormControl>
          <div className={styles.subHeadingText}>
               
           </div>
          <RadioGroup value={selectedDefectCount} onChange={ (event:any) => onDefectCountRadioButtonChanged(event.target.value)}>
            {defectCountRadioButtons}
          </RadioGroup>
        </FormControl>
        <FormGroup>
          <div className={styles.subHeadingText}>
            Type
          </div>
          {detectSourceCheckboxes}
        </FormGroup>
        <FormGroup>
            <div className={styles.subHeadingText}>
                Users
            </div>
            <TextField style={{ color: '#F7B500' }} 
                      id="user-filter"
                      label="Search"
                      disabled={sourceFlag || filteredUsers.length === 0}
                      onChange={(event) => onUserChange(event.target.value)} />
                  {selectAllUsersCheckbox}
            <div className={styles.users}>
              {userCheckboxes}
            </div>
        </FormGroup>
       </div>
    </div>
  );
}